import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Moment } from "moment-timezone";
import first from "lodash/first";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

import { Box, Container, SelectChangeEvent } from "@mui/material";

import {
  useAnalytics,
  useCurrentUser,
  useScreenSize,
} from "../../globals/hooks";

import TotalReservationsView from "./components/TotalReservationsView";
import DriverPayoutView from "./components/DriverPayoutView";
import TotalQuotesView from "./components/TotalQuotesView";
import AffiliatePayoutView from "./components/AffiliatePayoutView";
import CorporateShuttleView from "./components/CorporateShuttleView";
import {
  LOAD_COMPANIES_QUERY,
  LOAD_METABASE_DASHBOARD_QUERY,
} from "globals/graphql";
import { useQuery } from "@apollo/client";
import { Company, PlanVariant } from "types";
import DashboardSelectorHeader from "./components/DashboardSelectorHeader";
import DashboardCorporateSelectorHeader from "./components/DashboardCorporateSelectorHeader";

export enum DashboardView {
  TOTAL_RESERVATIONS = "Total Reservations",
  DRIVER_PAYOUT = "Driver Earnings",
  TOTAL_QUOTES = "Total Quotes",
  AFFILIATE_PAYOUT = "Affiliate Payout",
  CORPORATE_SHUTTLE = "Corporate Shuttle",
}

const {
  TOTAL_RESERVATIONS,
  DRIVER_PAYOUT,
  TOTAL_QUOTES,
  AFFILIATE_PAYOUT,
  CORPORATE_SHUTTLE,
} = DashboardView;

const defaultMenuOptions = [
  TOTAL_RESERVATIONS,
  DRIVER_PAYOUT,
  TOTAL_QUOTES,
  AFFILIATE_PAYOUT,
];

function MetabaseDashboard() {
  const { isMobileView } = useScreenSize();
  const { data } = useQuery(LOAD_METABASE_DASHBOARD_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const metabaseIframeUrl = data?.loadMetabaseDashboard?.metabaseIframeUrl;

  return (
    <Box
      justifyContent="center"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <Box height="100%" mx={isMobileView ? 0 : 10} my={isMobileView ? 0 : 2}>
        <iframe
          title="Dashboard"
          src={metabaseIframeUrl}
          width="100%"
          height="100%"
          frameBorder={0}
          allowTransparency
        />
      </Box>
    </Box>
  );
}

function LegacyDashboard() {
  // queries
  const { data: companyList } = useQuery(LOAD_COMPANIES_QUERY);

  // hooks
  const { isMobileView } = useScreenSize();
  const location = useLocation();
  const { track } = useAnalytics();
  const { operator } = useCurrentUser();

  const { dashboardView } = queryString.parse(location.search);

  // state
  const [view, setView] = useState(
    dashboardView?.toString() || TOTAL_RESERVATIONS
  );
  const [endDate, setEndDate] = useState(moment().utc(true).endOf("day"));
  const [startDate, setStartDate] = useState(
    moment().utc(true).subtract(30, "days").startOf("day")
  );
  const [companyName, setCompanyName] = useState(null);



  // Track initial page load
  useEffect(() => {
    trackDashboardView(view);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // derived state
  const companyId = companyList?.companies.filter(
    (item) => item.name === companyName
  )[0]?.id;

  // useEffect
  // set first company in companyList as selected value
  useEffect(() => {
    const company: Company = first(companyList?.companies);

    if (company) {
      setCompanyName(company.name);
    }
  }, [companyList]);

  // track dashboard view
  const trackDashboardView = (viewToTrack: string) => {
    track("Dashboard Viewed", {
      view: viewToTrack.toLowerCase().replace(/\s+/g, "_"), // collapse whitespace into underscore
    });
  }

  // event handlers
  const handleViewChange = (event: SelectChangeEvent<any>) => {
    const value = event.target.value;

    if (view === DashboardView.DRIVER_PAYOUT) {
      track("dashboard_driverPayoutOpened");
    }
    trackDashboardView(value);
    setView(value);
  };

  const handleCompanyChange = (event: SelectChangeEvent<any>) => {
    const value = event.target.value;
    setCompanyName(value);
  };

  const handleStartDateChange = (date: Moment) => {
    setStartDate(date.startOf("day"));
  };

  const handleEndDateChange = (date: Moment) => {
    setEndDate(date.endOf("day"));
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: `calc(100vh - ${isMobileView ? "55px" : "84px"})`,
      }}
    >
      <Box display="flex" flexDirection="column" width="100%">
        {view === CORPORATE_SHUTTLE ? (
          <DashboardCorporateSelectorHeader
            view={view}
            onHandleViewChange={handleViewChange}
            isVip={operator.plan === PlanVariant.Vip}
            defaultMenuOptions={defaultMenuOptions}
            companyName={companyName}
            onHandleCompanyChange={handleCompanyChange}
            companyList={companyList?.companies}
            setStartDate={handleStartDateChange}
            setEndDate={handleEndDateChange}
          />
        ) : (
          <DashboardSelectorHeader
            view={view}
            onHandleViewChange={handleViewChange}
            isVip={operator.plan === PlanVariant.Vip}
            defaultMenuOptions={defaultMenuOptions}
            setStartDate={handleStartDateChange}
            setEndDate={handleEndDateChange}
          />
        )}
      </Box>

      <Box height="100%" width="100%" mb={2}>
        {
          {
            [TOTAL_RESERVATIONS]: (
              <TotalReservationsView startDate={startDate} endDate={endDate} />
            ),
            [DRIVER_PAYOUT]: (
              <DriverPayoutView startDate={startDate} endDate={endDate} />
            ),
            [TOTAL_QUOTES]: (
              <TotalQuotesView startDate={startDate} endDate={endDate} />
            ),
            [AFFILIATE_PAYOUT]: (
              <AffiliatePayoutView startDate={startDate} endDate={endDate} />
            ),
            [CORPORATE_SHUTTLE]: (
              <CorporateShuttleView
                startDate={startDate}
                endDate={endDate}
                companyId={companyId}
              />
            ),
          }[view]
        }
      </Box>
    </Container>
  );
}

function DashboardMainPage() {
  const { metabaseDashboards } = useLaunchDarklyFlags();

  return metabaseDashboards ? <MetabaseDashboard /> : <LegacyDashboard />;
}

export default DashboardMainPage;
