import React, { useState, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import filter from "lodash/filter";
import { Box, CircularProgress, Typography } from "@mui/material";

import MoovsDialog from "components/MoovsDialog";
import { useAnalytics, useOperator, useSnackbar } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import {
  SUBSCRIBE_TO_CRM_MUTATION,
  LOAD_LAGO_PAYMENT_METHOD_QUERY,
  LOAD_OPERATOR_QUERY,
} from "globals/graphql";
import { currency } from "globals/utils/helpers";
import { PlanInfoSection, LagoPaymentMethods } from "./components";
import { InfoIcon } from "design-system/icons";
import { LagoCrmPlanCode } from "types";

interface SubscribeToCrmDialogProps {
  open: boolean;
  onClose: () => void;
  planName: string;
  pricePerMonth: number;
  icon: React.ReactElement;
  bgColor: string;
  setSubscribedSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  discountPrice?: number;
  discountMonths?: number;
}

function SubscribeToCrmDialog(props: SubscribeToCrmDialogProps) {
  const {
    open,
    onClose,
    planName,
    pricePerMonth,
    icon,
    bgColor,
    setSubscribedSuccess,
    discountPrice,
    discountMonths,
  } = props;

  // state
  const [hideLoadingIndicator, setHideLoadingIndicator] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [lagoPaymentMethods, setLagoPaymentMethods] = useState([]);

  // hooks
  const snackbar = useSnackbar();
  const operator = useOperator();
  const { track } = useAnalytics();

  // memo
  const subscriptionFee = useMemo(() => {
    return currency(pricePerMonth).slice(0, -3);
  }, [pricePerMonth]);

  const discountedFee = useMemo(() => {
    if (!discountPrice) return null;
    return currency(discountPrice).slice(0, -3);
  }, [discountPrice]);

  // queries
  const { loading: paymentMethodLoading } = useQuery(
    LOAD_LAGO_PAYMENT_METHOD_QUERY,
    {
      variables: {
        id: operator?.id,
      },
      fetchPolicy: "cache-and-network",
      skip: !open || !operator?.id,
      onCompleted: (data) => {
        const primaryPaymentMethod = filter(
          data.node.lagoPaymentMethod,
          "isPrimary"
        );
        setLagoPaymentMethods(primaryPaymentMethod);

        if (primaryPaymentMethod.length) {
          setSubmitDisabled(false);
        }
      },
      onError: () => {
        snackbar.error();
      },
    }
  );

  // mutations
  const [subscribeToCrm] = useMutation(SUBSCRIBE_TO_CRM_MUTATION, {
    onError(error) {
      setSubmitDisabled(false);
      const errorMessage = getErrorMessage(error);
      snackbar.error(errorMessage);
      setHideLoadingIndicator(true);
    },
    onCompleted(data) {
      const result = data.subscribeToCrm.result;

      if (result.__typename === "SubscribeToCrmFailure") {
        snackbar.error(result.reason);
        return;
      }

      snackbar.success("Successfully subscribed to CRM!");
      track("subscriptions_crmSubscribed", {
        package: planName,
      });
      onClose();
      setSubscribedSuccess(true);
    },
    refetchQueries: [LOAD_OPERATOR_QUERY],
  });

  const handleSubscribe = async () => {
    setSubmitDisabled(true);
    setHideLoadingIndicator(false);

    subscribeToCrm({
      variables: {
        input: {
          lagoCrmPlanCode: LagoCrmPlanCode.CrmStandardMonthly,
        },
      },
    });
  };

  return (
    <MoovsDialog
      open={open}
      onClose={onClose}
      onAccept={handleSubscribe}
      acceptDisabled={submitDisabled}
      hideLoadingIndicator={hideLoadingIndicator}
      dialogTitle={`Subscribe to ${planName}`}
      acceptButtonText="Subscribe"
      closeButtonText="Cancel"
      size="sm"
    >
      <Box py={2}>
        <PlanInfoSection
          planName={planName}
          icon={icon}
          bgColor={bgColor}
          pricePerMonth={pricePerMonth}
          discountPrice={discountPrice}
          discountMonths={discountMonths}
        />

        {/* Payment Method Section */}
        {paymentMethodLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            p={2}
            mt={3}
            sx={{
              // styling to match height of card component
              border: "1px solid transparent",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <LagoPaymentMethods lagoPaymentMethods={lagoPaymentMethods} />
        )}

        {/* Charge Info */}
        <Box mt={3}>
          <Box display="flex" alignItems="center">
            <InfoIcon size="md" />
            <Typography ml={1} variant="body2">
              {discountPrice && discountMonths ? (
                <>
                  You will be charged <strong>{discountedFee}</strong> monthly for the first {discountMonths} months, 
                  then <strong>{subscriptionFee}</strong> monthly for access to the CRM platform.
                </>
              ) : (
                <>
                  You will be charged <strong>{subscriptionFee}</strong> monthly for
                  access to the CRM platform.
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </MoovsDialog>
  );
}

export default SubscribeToCrmDialog;
