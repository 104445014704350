import { deriveIsFarmee, deriveIsFarmor } from "globals/utils/farm";
import { Route } from "types";

export const getFarmTripInfo = (route: Route) => {
  const isFarmee = deriveIsFarmee(route);
  const isFarmor = deriveIsFarmor(route);

  const isFarmorAndFarmeeHasNotAcceptedTrip =
    isFarmor && route?.farmStatus === "PENDING";

  return {
    isFarmee,
    isFarmorAndFarmeeHasNotAcceptedTrip,
  };
};
