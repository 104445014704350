import React from "react";

type EditIconProps = {
  color?: string;
  size?: "small" | "medium";
};

const EditIcon = (props: EditIconProps) => {
  const { color, size } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0779 2.50136C11.2368 2.34241 11.4255 2.21632 11.6332 2.1303C11.8409 2.04428 12.0635 2 12.2883 2C12.513 2 12.7356 2.04428 12.9433 2.1303C13.151 2.21632 13.3397 2.34241 13.4986 2.50136C13.6576 2.66031 13.7837 2.84901 13.8697 3.05669C13.9557 3.26436 14 3.48695 14 3.71174C14 3.93653 13.9557 4.15912 13.8697 4.3668C13.7837 4.57447 13.6576 4.76317 13.4986 4.92212L5.32855 13.0922L2 14L2.90779 10.6714L11.0779 2.50136Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (size === "medium") {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 4.12132H4C3.46957 4.12132 2.96086 4.33204 2.58579 4.70711C2.21071 5.08218 2 5.59089 2 6.12132V20.1213C2 20.6518 2.21071 21.1605 2.58579 21.5355C2.96086 21.9106 3.46957 22.1213 4 22.1213H18C18.5304 22.1213 19.0391 21.9106 19.4142 21.5355C19.7893 21.1605 20 20.6518 20 20.1213V13.1213M18.5 2.62132C18.8978 2.2235 19.4374 2 20 2C20.5626 2 21.1022 2.2235 21.5 2.62132C21.8978 3.01915 22.1213 3.55871 22.1213 4.12132C22.1213 4.68393 21.8978 5.2235 21.5 5.62132L12 15.1213L8 16.1213L9 12.1213L18.5 2.62132Z"
          stroke={color || "#999999"}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 4.12132H4C3.46957 4.12132 2.96086 4.33204 2.58579 4.70711C2.21071 5.08218 2 5.59089 2 6.12132V20.1213C2 20.6518 2.21071 21.1605 2.58579 21.5355C2.96086 21.9106 3.46957 22.1213 4 22.1213H18C18.5304 22.1213 19.0391 21.9106 19.4142 21.5355C19.7893 21.1605 20 20.6518 20 20.1213V13.1213M18.5 2.62132C18.8978 2.2235 19.4374 2 20 2C20.5626 2 21.1022 2.2235 21.5 2.62132C21.8978 3.01915 22.1213 3.55871 22.1213 4.12132C22.1213 4.68393 21.8978 5.2235 21.5 5.62132L12 15.1213L8 16.1213L9 12.1213L18.5 2.62132Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
