import React from "react";
import moment from "moment-timezone";
import { Box } from "@mui/material";
import TripTimelineStep from "./TripTimelineStep";
import { TripTimelineProps } from "./TripTimeline";
import { Stop } from "types";

type TripProposalTimelineProps = TripTimelineProps & {
  isOriginalTrip: boolean;
};

const stopIsDifferent = (stop: Stop, proposedStop: Stop) => {
  const res = (
    stop.dateTime !== proposedStop.dateTime ||
    stop.location !== proposedStop.location ||
    stop.trackedFlight?.flightNumber !== proposedStop.trackedFlight?.flightNumber
  );

  return res
};

export function TripProposalTimeline(props: TripProposalTimelineProps) {
  const {
    trip: { stops, estimatedDuration, useTotalDuration, tripChangeProposal },
    variant = "default",
    garageIn,
    garageOut,
    isOriginalTrip,
  } = props;

  const addedStopsHasDateTime = (() => {
    if (stops.length > 2) {
      const addedStops = stops.slice(1, stops.length - 1);
      for (const stop of addedStops) {
        if (stop.dateTime) {
          return true;
        }
      }
    }
    return false;
  })();

  // Determine which stops to render and which to compare against
  const stopsToRender = isOriginalTrip ? stops : tripChangeProposal.stops;
  const stopsToCompareAgainst = isOriginalTrip ? tripChangeProposal.stops : stops;
  const useTotalDurationToRender = isOriginalTrip ? useTotalDuration : tripChangeProposal.useTotalDuration;
  const estimatedDurationToRender = isOriginalTrip ? estimatedDuration : tripChangeProposal.estimatedDuration;


  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      {stopsToRender.map((stop, index) => {
        const isLastStop = index === stopsToRender.length - 1;

        const estimatedDropOffTime =
          isLastStop &&
          !useTotalDurationToRender &&
          moment
            .utc(stopsToRender[0].dateTime)
            .add(estimatedDurationToRender, "minutes")
            .toISOString();

        // If the stop is the last stop, use the last stop in the comparison array for comparison.
        // We do this to handle the case where one set of stops has more or less stops than the other.
        let comparisonStop = null;
        if (isLastStop) {
          comparisonStop = stopsToCompareAgainst[stopsToCompareAgainst.length - 1];
        } else if (index < stopsToCompareAgainst.length) {
          comparisonStop = stopsToCompareAgainst[index];
        } else {
          comparisonStop = stopsToCompareAgainst[stopsToCompareAgainst.length - 1];
        }

        let hasProposedChange = false;
        if (comparisonStop) {
          hasProposedChange = stopIsDifferent(stop, comparisonStop);
        }

        return (
          <TripTimelineStep
            key={index}
            stop={stop}
            variant={variant}
            hasProposedChange={hasProposedChange}
            isLastStop={isLastStop}
            estimatedDropOffTime={estimatedDropOffTime}
            garageIn={garageIn}
            garageOut={garageOut}
            addedStopsHasDateTime={addedStopsHasDateTime}
          />
        );
      })}
    </Box>
  );
} 