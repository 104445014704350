import React, { useRef, useState } from "react";
import first from "lodash/first";
import { useMutation } from "@apollo/client";

import { Box } from "@mui/material";

import { black, moovsBlueSelected } from "design-system/colors";
import MoovsBanner from "components/MoovsBanner";
import { useAnalytics, useSnackbar } from "globals/hooks";
import {
  ACCEPT_UNCONFIRMED_REQUEST_MUTATION,
  LOAD_REQUEST_QUERY,
  UPDATE_ROUTE_DISPATCH_MUTATION,
} from "globals/graphql";
import { trackReservations } from "globals/utils/tracking/trackReservations";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { Trip, Vehicle } from "types";
import VehiclesDialog from "components/reservations/VehiclesDialog";



type AcceptDeclinedRequestBannerProps = {
  isDeclined: boolean;
  requestId: string;
  trips: Trip[];
  orderNumber: string | null;
};

function AcceptDeclinedRequestBanner(props: AcceptDeclinedRequestBannerProps) {
  const { isDeclined, requestId, trips, orderNumber } = props;

  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const tripIndexRef = useRef(0);

  const [isVehicleDialogOpen, setIsVehicleDialogOpen] = useState(false);


  const tripsWithConflictsOrNoVehicle = trips.filter(trip => trip.vehicleInConflictId || !trip.hasVehicle);
  const hasConflictsOrIsVehicleMissing = tripsWithConflictsOrNoVehicle.length > 0;


  const [acceptUnconfirmedReservation] = useMutation(
    ACCEPT_UNCONFIRMED_REQUEST_MUTATION,
    {
      refetchQueries: [
        {
          query: LOAD_REQUEST_QUERY,
          variables: { id: requestId },
        },
      ],
      onCompleted(data) {
        snackbar.success("Reservation successfully updated");
        const request = data.acceptUnconfirmedReservation.request;
        trackReservations(track, request, "reservation_accepted");
      },
      onError(error) {
        console.error(error);
        snackbar.error(getErrorMessage(error) || "Error updating reservation");
      },
    }
  );

  const [updateRouteDispatch] = useMutation(UPDATE_ROUTE_DISPATCH_MUTATION, {
    onCompleted() {
      setIsVehicleDialogOpen(false);
      if (tripIndexRef.current < tripsWithConflictsOrNoVehicle.length - 1) {
        tripIndexRef.current++;
        snackbar.success("Vehicle successfully added to trip");
        setIsVehicleDialogOpen(true);
      } else {
        acceptUnconfirmedReservation({
          variables: {
            input: { requestId },
          },
        });
        setIsVehicleDialogOpen(false);
      }
    },
    onError(error) {
      console.error(error);
      snackbar.error(getErrorMessage(error) || "Error adding vehicle to trip");
    },
    refetchQueries: [
      {
        query: LOAD_REQUEST_QUERY,
        variables: { id: requestId },
      },
    ],
    awaitRefetchQueries: true,
  });


  // event handlers

  const handleAcceptDeclinedRequest = () => {
    if (tripsWithConflictsOrNoVehicle.length > 0 && (tripIndexRef.current < tripsWithConflictsOrNoVehicle.length)) {
      setIsVehicleDialogOpen(true);
    } else {
      acceptUnconfirmedReservation({
        variables: {
          input: { requestId },
        },
      });
      setIsVehicleDialogOpen(false);
    }
  };

  const handleUpdateVehicle = (selectedVehicles: Vehicle[]) => {
    selectedVehicles.forEach((vehicle) => {
      const routeId = first(tripsWithConflictsOrNoVehicle[tripIndexRef.current].routes).id;
      if (routeId) {
        updateRouteDispatch({
          variables: {
            input: {
              routeId,
              vehicleId: vehicle.id,
            },
          },
        });
      }
    });
  };

  const currentTripWithVehicleConflict = tripsWithConflictsOrNoVehicle[tripIndexRef.current];

  if (!isDeclined) return null;
  return (
    <Box my={1}>
      <MoovsBanner
        bannerVariant="compact"
        bannerText="Reservation Declined"
        actionButtonText="Accept"
        onActionButtonClick={handleAcceptDeclinedRequest}
        bannerBgColor={moovsBlueSelected}
        bannerTextColor={black}
        actionButtonVariant="outlined"
        bannerTextVariant="subtitle2"
      />
      {hasConflictsOrIsVehicleMissing && <VehiclesDialog
        dialogMode={"add"}
        tripNumber={`${orderNumber}-${currentTripWithVehicleConflict.tripNumber}`}
        open={isVehicleDialogOpen}
        onClose={() => setIsVehicleDialogOpen(false)}
        onCreate={handleUpdateVehicle}
        stops={currentTripWithVehicleConflict.stops}
      />}
    </Box>
  );
}

export default AcceptDeclinedRequestBanner;
