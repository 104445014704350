import React, {
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment-timezone";
import isEqual from "lodash/isEqual";

import { Box, Button, CircularProgress } from "@mui/material";

import RemoveContactDialog from "../RemoveContactDialog";
import {
  ContactTripsGrid,
  ContactInfoSection,
  TripDrawerTabs,
} from "./components";
import UpdateDrawer from "../../../globals/UpdateDrawer";
import PaymentMethodList from "../../paymentMethod/PaymentMethodList";
import GQLQueryStatusIndicator from "../../../GQLQueryStatusIndicator";
import { UPDATE_CONTACT_MUTATION, LOAD_CONTACT_QUERY } from "globals/graphql";
import { Contact, Comment, Company, PaymentMethod } from "types";
import { useSnackbar } from "globals/hooks/useSnackbar";
import { TrashIcon, UsersIcon, ShieldIcon, DueIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import CommentUpdateBlock from "../../../CommentUpdateBlock";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import { useAnalytics, useScreenSize } from "globals/hooks";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import ViewCardPaymentsModal from "../../paymentMethod/ViewCardPaymentsModal";
import CustomEmailActivityLogDialog from "components/globals/ActivityLog/EmailActivityLogDialog";
import { isValidPhoneNumber } from "globals/utils/helpers";
import ContactLinkPassengerSection from "./components/ContactLinkPassengerSection";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";
import PassengerOfSection from "./components/PassengerOfSection";
import LinkedPassengerPaymentMethods from "components/contacts/paymentMethod/LinkedPassengerPaymentMethods";
import DiscardChangesDialog from "components/DiscardChangesDialog";
import { validateEmail } from "globals/utils/email";

// sort payment methods by stripe status
const paymentMethodPriority = { succeeded: 1, pending: 2, rejected: 3 };

const sortPaymentMethods = (allPaymentMethods: PaymentMethod[]) =>
  [...allPaymentMethods].sort((a, b) =>
    paymentMethodPriority[a.stripeStatus] >
    paymentMethodPriority[b.stripeStatus]
      ? 1
      : -1
  );

function UpdateContactDrawer() {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const { contactId } = useParams<{
    contactId: string;
  }>();
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();

  // state
  const [contact, setContact] = useState<Contact>(null);
  const [comments, setComments] = useState<Comment[]>(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [tabMode, setTabMode] = useState("Profile");
  const [activityLogOpen, setActivityLogOpen] = useState(false);
  const [contactMobilePhoneError, setContactMobilePhoneError] = useState("");
  const [contactEmailError, setContactEmailError] = useState("");
  const [discardChangesDialogOpen, setDiscardChangesDialogOpen] =
    useState(false);

  const initialContactState = useRef(null);

  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "saved" | "loading" | "error"
  >("default");

  const paymentMethodId = useMemo(
    () => new URLSearchParams(history.location.search).get("card"),
    [history.location]
  );

  const shouldSkipQuery = !contactId;

  // queries
  const {
    data: contactData,
    loading: contactLoading,
    refetch: contactRefetch,
    error: contactError,
  } = useQuery(LOAD_CONTACT_QUERY, {
    variables: {
      id: contactId,
    },
    skip: shouldSkipQuery,
    ...(!shouldSkipQuery && { pollInterval: 30 * 1000 }), // every 30 seconds
  });

  // mutations
  const [updateContact] = useMutation(UPDATE_CONTACT_MUTATION, {
    onCompleted(data) {
      setSaveIndicatorState("saved");

      const updatedContact = data.updateContact?.contact;

      // sets state
      initialContactState.current = updatedContact;
      setContact(updatedContact);
    },
    onError(error) {
      setSaveIndicatorState("error");
      const errorMessage = getErrorMessage(error) || "Error updating contact";

      snackbar.error(errorMessage);

      // resets to match cache
      delete contactData.node.comments;
      delete contactData.node.paymentMethods;
      initialContactState.current = contactData.node;
      setContact(contactData.node);
    },
    refetchQueries: ["PaginatedContacts"],
  });

  // event handlers
  const handleContactInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.name === "email") {
      setContactEmailError("");
    }

    setContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
  };

  const handleContactPhoneInputChange = (value, country, e, formattedValue) => {
    const { international, formatted } =
      formatPhoneNumber(value, country.countryCode, country.dialCode) || {};

    if (country.countryCode === "us" && !isValidPhoneNumber(formatted)) {
      setContactMobilePhoneError("Please enter valid number");
      return;
    }

    setContact({
      ...contact,
      mobilePhone: formatted,
      mobilePhoneInternational: international,
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
    setContactMobilePhoneError("");
  };

  const handleContactAddressInputChange = (value, name) => {
    setContact({
      ...contact,
      [name]: value.description,
    });
  };

  const handleCompanyChange = (company: Company) => {
    setContact({
      ...contact,
      company,
    });
  };

  const handleSubmit = () => {
    // validate minimum required fields
    if (
      !contact.firstName ||
      !contact.lastName ||
      !contact.email ||
      contactMobilePhoneError !== ""
    ) {
      return;
    }

    // validate email
    if (!validateEmail(contact.email)) {
      setContactEmailError("Please enter valid email");
      return;
    }

    setSaveIndicatorState("loading");

    updateContact({
      variables: {
        input: {
          id: contact.id,
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          mobilePhone: contact.mobilePhone,
          phoneCountryCode: contact.phoneCountryCode,
          phoneCountryDialCode: contact.phoneCountryDialCode,
          phoneCountryName: contact.phoneCountryName,
          phoneCountryFormat: contact.phoneCountryFormat,
          // optional fields
          companyId: contact.company?.id || null,
          companyPosition: contact.companyPosition || null,
          preferences: contact.preferences || null,
          workAddress: contact.workAddress || null,
          homeAddress: contact.homeAddress || null,
        },
      },
    });
  };

  const handleRemoveDialogClose = () => setRemoveDialogOpen(false);
  const handleDeleteContactClick = () => setRemoveDialogOpen(true);

  const handleGoToCompany = () => {
    track("goTo_pageSelected");
    history.push(`/companies/update/${contact?.company.id}`);
  };

  const handleTabChange = (tabMode: string) => {
    if (tabMode === "Trips") {
      track("contact_tripsTabOpened");
    }
    setTabMode(tabMode);
  };

  // lifecycle hooks
  // clears contact on toggling drawer
  useEffect(() => {
    setContact(null);
    setComments(null);
    setSaveIndicatorState("default");
  }, [contactId]);

  const { node: contactDataNode } = contactData || {};

  // sets contact on load
  // sets paymentMethods and comments whenever they are updated
  useEffect(() => {
    if (contactDataNode?.comments) {
      setComments(contactDataNode.comments);
    }

    if (contactDataNode && !contact) {
      initialContactState.current = contactDataNode;
      setContact(contactDataNode);
    }
  }, [comments, contact, contactDataNode]);

  // checks if contact has been updated
  const isDirty = useMemo(() => {
    if (initialContactState.current && contact) {
      return !isEqual(initialContactState.current, contact);
    }

    return false;
  }, [contact]);

  const checkIfChangesAreSaved = useCallback(() => {
    if (isDirty) {
      setDiscardChangesDialogOpen(true);
      return false;
    }
    return true;
  }, [isDirty, setDiscardChangesDialogOpen]);

  const sortedPaymentMethods = sortPaymentMethods(
    contactDataNode?.allPaymentMethods || []
  );

  return (
    <>
      <UpdateDrawer
        onClose={() => {
          history.push(`/contacts`);
        }}
        createdAt={contact ? moment(contact.createdAt).format("LLL") : ""}
        updatedAt={contact ? moment(contact.updatedAt).format("LLL") : ""}
        saveIndicatorState={saveIndicatorState}
        ellipsisMenuOptions={[
          "GO TO",
          {
            text: "View Activity Log",
            icon: <DueIcon color={grayDark} size="small" />,
            onClick: () => setActivityLogOpen(true),
          },
          "divider",
          {
            text: "Contact",
            icon: <UsersIcon color={grayDark} size="small" />,
            disableOption: true,
            onClick: () => {},
          },
          {
            disableOption: contact?.company ? false : true,
            onClick: handleGoToCompany,
            text: "Company",
            icon: <ShieldIcon color={grayDark} size="small" />,
          },
          "divider",
          {
            onClick: handleDeleteContactClick,
            text: "Delete",
            icon: <TrashIcon color={grayDark} size="small" />,
          },
        ]}
        subHeaderContent={
          <TripDrawerTabs value={tabMode} setValue={handleTabChange} />
        }
        footerContent={
          <Box mr={5}>
            <Button
              disabled={saveIndicatorState === "loading" || !isDirty}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        }
        validateBeforeClose={checkIfChangesAreSaved}
      >
        {contactLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={40} thickness={2} />
          </Box>
        )}
        {contactError && !contactLoading && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <GQLQueryStatusIndicator
              name="Contact"
              data={contactData}
              error={contactError}
              refetch={contactRefetch}
            />
          </Box>
        )}
        {contact && (
          <>
            {tabMode === "Profile" ? (
              <Box>
                <ContactInfoSection
                  contact={contact}
                  onContactInputChange={handleContactInputChange}
                  onContactPhoneInputChange={handleContactPhoneInputChange}
                  onCompanyChange={handleCompanyChange}
                  onContactAddressInputChange={handleContactAddressInputChange}
                  contactMobilePhoneError={contactMobilePhoneError}
                  contactEmailError={contactEmailError}
                />
                <Box my={2} />
                <PaymentMethodList
                  payer={{
                    payerType: "contact",
                    payerId: contactId,
                    payerPhone: contact.mobilePhone,
                  }}
                  paymentMethods={sortedPaymentMethods}
                  contact={contactDataNode}
                  setSaveIndicatorState={setSaveIndicatorState}
                />

                {enableLinkedPassenger && (
                  <ContactLinkPassengerSection
                    contact={contactDataNode}
                    refreshContact={contactRefetch}
                  />
                )}
                {enableLinkedPassenger && (
                  <PassengerOfSection
                    contact={contactDataNode}
                    refreshContact={contactRefetch}
                  />
                )}

                {enableLinkedPassenger && (
                  <LinkedPassengerPaymentMethods
                    payer={{
                      payerType: "contact",
                      payerId: contactId,
                      payerPhone: contact.mobilePhone,
                    }}
                    paymentMethods={
                      contactDataNode?.passengerLinkedPaymentMethods || []
                    }
                    setSaveIndicatorState={setSaveIndicatorState}
                  />
                )}

                {/** add linked payment method here */}
                <Box mb={6} />
                <Box my={3} pt={4}>
                  <CommentUpdateBlock
                    mode="contact"
                    comments={comments}
                    setSaveIndicatorState={setSaveIndicatorState}
                    contactId={contactId}
                    refetchQuery={contactRefetch}
                  />
                </Box>
              </Box>
            ) : (
              <ContactTripsGrid
                contactId={contactId}
                {...(isMobileView
                  ? { sx: { mx: -2 } }
                  : {
                      sx: {
                        mx: -4,
                        "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
                          {
                            display: "none",
                          },
                      },
                    })}
              />
            )}
          </>
        )}
      </UpdateDrawer>

      <RemoveContactDialog
        open={removeDialogOpen}
        contact={contact}
        onClose={handleRemoveDialogClose}
      />

      <CustomEmailActivityLogDialog
        open={activityLogOpen}
        onClose={() => setActivityLogOpen(false)}
        email={contact?.email}
        source="contact"
      />

      <ViewCardPaymentsModal paymentMethodId={paymentMethodId} />

      <DiscardChangesDialog
        open={discardChangesDialogOpen}
        onClose={() => setDiscardChangesDialogOpen(false)}
        onDiscard={() => history.push(`/contacts`)}
      />
    </>
  );
}

export default UpdateContactDrawer;
