import React, { useState } from "react";
import { OperationVariables } from "@apollo/client";

import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Collapse,
} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";

import DispatchPageViewToggle from "components/dispatch/DispatchPageViewToggle";
import { useAnalytics, useScreenSize } from "globals/hooks";
import {
  black,
  grayDark,
  grayLight,
  moovsBlue,
  white,
} from "design-system/colors";
import SavedIndicatorChip from "design-system/components/chips/SaveIndicatorChip";
import { FilterIcon } from "design-system/icons";
import { FilterDispatchTripsDrawer } from "./components";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

// constants
export const HEADER_HEIGHT_MD = "104px";

type TripViewHeaderProps = {
  onPageViewClick?: (_, newType: "list" | "scheduler") => void;
  pageViewValue?: "list" | "scheduler";
  dateFilter?: React.ReactNode;
  appointmentsFilter?: React.ReactNode;
  saveIndicatorState?: "loading" | "default" | "saved" | "error";
  refetch?: (variables?: Partial<OperationVariables>) => void;
  filterNumber?: number;
  onExpandedCallback?: () => void;
};

function TripViewHeader(props: TripViewHeaderProps) {
  const {
    dateFilter,
    onPageViewClick,
    pageViewValue,
    appointmentsFilter,
    saveIndicatorState,
    refetch,
    filterNumber,
    onExpandedCallback,
  } = props;
  const { enableDispatchV2 } = useLaunchDarklyFlags();

  // state
  const [filterDispatchTripsDrawerOpen, setFilterDispatchTripsDrawerOpen] =
    useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  // hooks
  const { isMobileView, isSmallTabletView } = useScreenSize();
  const { track } = useAnalytics();

  const isListPageView = pageViewValue === "list";
  const isSchedulerPageView = pageViewValue === "scheduler";

  // event handlers
  const handleOpenFilterButtonClick = () => {
    track("dispatch_filterInitiated");
    setFilterDispatchTripsDrawerOpen(true);
  };

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);

    if (onExpandedCallback) {
      setTimeout(() => {
        onExpandedCallback();
      }, 300); // delay to allow for animation to complete
    }
  };

  return (
    <Paper
      square
      sx={{
        borderBottom:
          pageViewValue === "list" ? `1px solid ${grayLight}` : "none",
        height: "100%",
        position: "relative",
        overflow: "visible", // Ensures content doesn't get clipped
      }}
      elevation={0}
    >
      <Collapse in={isExpanded} collapsedSize="15px">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              height: {
                xs: "100%",
                md: HEADER_HEIGHT_MD,
              },
              marginX: {
                xs: 0,
                md: 10,
              },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
              alignItems="center"
              {...(isSmallTabletView && !isListPageView && { padding: "16px" })}
              {...(isSmallTabletView &&
                isListPageView && { padding: "16px 4px" })}
              width="100%"
            >
              {/* left aligned */}
              <Box display="flex">
                <Box mr={2} display="flex">
                  <Typography variant="h1" style={{ color: black }}>
                    Dispatch
                  </Typography>
                </Box>
                {isListPageView && !isSmallTabletView && dateFilter}
              </Box>

              {/* right aligned */}
              <Box display="flex" flexDirection="row" alignItems="center">
                {isSchedulerPageView && !isMobileView && appointmentsFilter}

                {isListPageView && (
                  <>
                    {!enableDispatchV2 && (
                      <>
                        <Button
                          disableRipple
                          sx={{
                            mr: 2,
                            p: 1,
                            "&:hover": { bgcolor: "transparent" },
                          }}
                          onClick={handleOpenFilterButtonClick}
                        >
                          {filterNumber ? (
                            <Box
                              display="flex"
                              height="14px"
                              minWidth="14px"
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="50%"
                              bgcolor={moovsBlue}
                              marginRight="-22px"
                              marginTop="-15px"
                            >
                              <Box color={white} zIndex={3}>
                                <Typography
                                  fontSize="9px"
                                  fontFamily="Poppins"
                                  fontWeight={500}
                                >
                                  {filterNumber}
                                </Typography>
                              </Box>
                            </Box>
                          ) : null}

                          <FilterIcon size="small" />
                          <Typography pl={0.75} fontWeight={500}>
                            Filter
                          </Typography>
                        </Button>
                        <FilterDispatchTripsDrawer
                          open={filterDispatchTripsDrawerOpen}
                          onClose={() =>
                            setFilterDispatchTripsDrawerOpen(false)
                          }
                          refetch={refetch}
                        />
                      </>
                    )}
                    <SavedIndicatorChip mode={saveIndicatorState} />
                  </>
                )}

                <DispatchPageViewToggle
                  onChange={onPageViewClick}
                  value={pageViewValue}
                />
              </Box>
            </Box>
          </Box>

          {/* second row  */}
          {!isSchedulerPageView && isSmallTabletView && (
            <Box
              sx={{
                padding: "0 16px 16px",
                marginX: {
                  xs: 0,
                  md: 10,
                },
              }}
            >
              {dateFilter}
            </Box>
          )}
        </Box>
      </Collapse>

      {/* Toggle button - still outside Collapse for proper positioning */}
      {isListPageView && (
        <IconButton
          sx={{
            position: "absolute",
            right: "50%",
            bottom: 0,
            transform: "translate(50%, 45%)",
            zIndex: 1,
          }}
          onClick={handleExpandToggle}
        >
          <DoubleArrowIcon
            fontSize="small"
            htmlColor={grayDark}
            sx={{
              backgroundColor: "white",
              transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
              borderRadius: "50%",
            }}
          />
        </IconButton>
      )}
    </Paper>
  );
}

export default TripViewHeader;
