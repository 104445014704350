import { gql } from '@apollo/client';

export const SUBMIT_CUSTOMER_FEEDBACK_MUTATION = gql`
  mutation SubmitCustomerFeedback($input: SubmitCustomerFeedbackInput!) {
    submitCustomerFeedback(input: $input) {
      customerFeedback {
        customerFeedbackId
        title
        description
        createdAt
      }
    }
  }
`; 