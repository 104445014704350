/**
 * @file graphql/contact.ts
 * queries/mutations for Contact.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_CONTACT_QUERY = gql`
  query Contact($id: ID!) {
    node(id: $id) {
      id
      ... on Contact {
        id
        createdAt
        updatedAt
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
        companyPosition
        preferences
        homeAddress
        workAddress
        phoneCountryCode
        phoneCountryDialCode
        phoneCountryFormat
        phoneCountryName
        comments {
          id
          bodyText
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
          }
        }
        company {
          id
          name
          address
        }
        linkedPassengers {
          id
          firstName
          lastName
          createdAt
          updatedAt
          email
          mobilePhone
          mobilePhoneInternational
          preferences
          homeAddress
          workAddress
        }
        passengerOf {
          id
          firstName
          lastName
        }
        paymentMethods {
          id
          createdAt
          updatedAt
          contactId
          customer
          payerId
          cardNote
          isPrimary
          linkedPassenger {
            id
            firstName
            lastName
          }
          billingDetails {
            name
          }
          card {
            id
            expMonth
            expYear
            last4
            brand
            cvcCheck
            zipCodeCheck
          }
        }
        passengerLinkedPaymentMethods {
          id
          createdAt
          updatedAt
          contactId
          cardNote
          customer
          isPrimary
          stripeStatus
          billingDetails {
            name
          }
          card {
            id
            expMonth
            expYear
            last4
            brand
            cvcCheck
            zipCodeCheck
          }
        }
        allPaymentMethods {
          id
          createdAt
          updatedAt
          contactId
          cardNote
          customer
          isPrimary
          stripeStatus
          billingDetails {
            name
          }
          card {
            id
            expMonth
            expYear
            last4
            brand
            cvcCheck
            zipCodeCheck
          }
          linkedPassenger {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const LOAD_CONTACTS_QUERY = gql`
  query loadContacts(
    $searchTerm: String
    $companyId: ID
    $cursor: String
    $limit: Int
  ) {
    loadContacts(
      searchTerm: $searchTerm
      companyId: $companyId
      after: $cursor
      first: $limit
    ) {
      totalCount
      edges {
        node {
          id
          createdAt
          updatedAt
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          companyPosition
          preferences
          homeAddress
          workAddress
          paymentMethods {
            id
            createdAt
            updatedAt
            contactId
            cardNote
            isPrimary
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
          }
          passengerLinkedPaymentMethods {
            id
            createdAt
            updatedAt
            contactId
            cardNote
            isPrimary
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
          }
          company {
            id
            name
            email
            companyLogoUrl
          }
          linkedPassengers {
            id
            firstName
            lastName
            createdAt
            updatedAt
            email
            mobilePhone
            mobilePhoneInternational
            preferences
            homeAddress
            workAddress
          }
          passengerOf {
            id
            firstName
            lastName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const CREATE_CONTACT_MUTATION = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      contact {
        id
        createdAt
        updatedAt
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
        companyPosition
        preferences
        homeAddress
        workAddress
        company {
          id
          name
        }
        paymentMethods {
          id
          createdAt
          updatedAt
          contactId
          cardNote
          card {
            id
            expMonth
            expYear
            last4
            brand
          }
        }
      }
    }
  }
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      contact {
        id
        createdAt
        updatedAt
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
        companyPosition
        preferences
        homeAddress
        workAddress
        company {
          id
          name
        }
        phoneCountryCode
        phoneCountryDialCode
        phoneCountryName
        phoneCountryFormat
      }
    }
  }
`;

export const REMOVE_CONTACT_MUTATION = gql`
  mutation RemoveContact($input: RemoveContactInput!) {
    removeContact(input: $input) {
      contact {
        id
        createdAt
        updatedAt
        firstName
        lastName
        email
        mobilePhone
        mobilePhoneInternational
        companyPosition
        preferences
        homeAddress
        workAddress
        company {
          id
          name
        }
      }
    }
  }
`;
