import React from "react";
import { Box, Typography, Divider, Link } from "@mui/material";
import { History } from "@mui/icons-material";
import { Trip } from "../../../../../../types";
import { getTripIconAndCategoryType } from "pages/requests/utils/getTripIconAndCategoryType";
import { grayDark, grayMedium, moovsBlue } from "design-system/colors";
import { AddressIcon, StatusCircleIcon } from "design-system/icons";
import { tripStartEndDateTimeParser } from "pages/requests/utils/calculateTripEndDateTime";
import first from "lodash/first";
import DurationDisplay from "./DurationDisplay";
import FlightChangeDisplay from "./FlightChangeDisplay";
import last from "lodash/last";
import { TripCategory } from "../../../../../../types";

type TripChangeProposalHeaderProps = {
  trip: Trip;
  isOriginalTrip: boolean;
  onShowOriginalTrip: () => void;
};

export function TripChangeProposalHeader(props: TripChangeProposalHeaderProps) {
  const { trip, isOriginalTrip, onShowOriginalTrip } = props;

  const { tripCategory, roundTripVariant } = trip;

  const { text, icon } = getTripIconAndCategoryType({
    tripCategory,
    roundTripVariant,
  });

  const startDateTime = first(trip.stops).dateTime;
  const endDateTime = last(trip.stops).dateTime;
  const proposedStartDateTime = first(trip.tripChangeProposal.stops).dateTime;
  const proposedEndDateTime = last(trip.tripChangeProposal.stops).dateTime;

  const hasNewStartDateTime = proposedStartDateTime !== startDateTime;
  const isHourlyTrip = tripCategory === TripCategory.Hourly;

  let hasDifferentDuration = false;
  // Hourly trips should use total duration to identify a difference whereas transfer trips should use estimated duration
  if (isHourlyTrip) {
    hasDifferentDuration = trip.totalDuration !== (trip.tripChangeProposal.totalDuration || 0) // tripChangeProposal.totalDuration can be null, which is equivalent to 0
  } else {
    hasDifferentDuration = trip.estimatedDuration !== (trip.tripChangeProposal.estimatedDuration || 0)
  }

  const tripForDurationDisplay = isOriginalTrip
    ? trip
    : trip.tripChangeProposal;

  const tripStartEndDateTimeString = isOriginalTrip
    ? tripStartEndDateTimeParser({
        startDateTime,
        endDateTime,
      })
    : tripStartEndDateTimeParser({
        startDateTime: proposedStartDateTime,
        endDateTime: proposedEndDateTime,
      });

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" style={{ color: grayDark }}>
          PICK-UP DATE & TIME
        </Typography>
        <Box display="flex" alignItems="center">
          {isOriginalTrip ? (
            <History sx={{ color: moovsBlue, fontSize: 20, mr: 1 }} />
          ) : (
            <Box mr={1}>
              <AddressIcon color={moovsBlue} size="small" />
            </Box>
          )}
          <Link
            component="button"
            variant="body1"
            onClick={onShowOriginalTrip}
            sx={{
              color: moovsBlue,
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {isOriginalTrip ? "View proposed trip" : "View original trip"}
          </Link>
        </Box>
      </Box>
      <Box display="flex">
        <Box display="flex" flexDirection="column">
          <Typography
            display="flex"
            gap={1}
            variant="subtitle1"
            style={{
              fontWeight: 700,
              lineHeight: 1.2,
            }}
          >
            {tripStartEndDateTimeString.split(/\n/).map((dateTimeInfo) => (
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 700 }}
                key={dateTimeInfo}
              >
                {dateTimeInfo}
              </Typography>
            ))}
            {hasNewStartDateTime && (
              <Box display="flex" alignItems="center">
                <StatusCircleIcon
                  color={moovsBlue}
                  fill={moovsBlue}
                  size="small"
                />
              </Box>
            )}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Box display="flex">
              <FlightChangeDisplay
                flightOffset={first(trip.stops).flightOffset}
                originalDateTime={first(trip.stops).originalDateTime}
                dateTime={first(trip.stops).dateTime}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={1} display="flex">
        <DurationDisplay
          totalDuration={tripForDurationDisplay.totalDuration}
          estimatedDuration={tripForDurationDisplay.estimatedDuration}
          isEstimated={!tripForDurationDisplay.useTotalDuration}
        />
        {hasDifferentDuration && (
          <Box display="flex" alignItems="center" ml={1}>
            <StatusCircleIcon color={moovsBlue} fill={moovsBlue} size="small" />
          </Box>
        )}
        <Box mx={2}>
          <Divider
            orientation="vertical"
            style={{
              backgroundColor: grayMedium,
            }}
          />
        </Box>
        <Box minHeight={32} display="flex" alignItems="center">
          <Typography noWrap variant="body2" style={{ color: grayDark }}>
            {text}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" ml={1.5}>
          {icon}
        </Box>
      </Box>
    </>
  );
}
