import { EmailRecipient } from "components/SendEmailDialog/SendEmailDialog";

const PATTERN = /\S+@\S+\.\S+/;

// valid email if there is an @ and .
// Example: 'anystring@anystring.anystring'
export const parseInvalidEmails = (emails: EmailRecipient[]) => {
  let error = false;

  const parsedEmails = emails.map((emailRecipient) => {
    if (!PATTERN.test(emailRecipient.email)) {
      emailRecipient.error = true;
      error = true;
    }
    return emailRecipient;
  });

  return { emails: parsedEmails, error };
};

/**
 * ^ - Start of string
 * [^\s@]+ - One or more characters that are NOT whitespace or @ symbol
 * @ - Literal @ symbol
 * [^\s@]+ - One or more characters that are NOT whitespace or @ symbol
 * \. - Literal dot (period)
 * [^\s@]+ - One or more characters that are NOT whitespace or @ symbol
 * $ - End of string
 */
const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validateEmail = (email: string) => {
  return EMAIL_PATTERN.test(email);
};
