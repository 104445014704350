import React from "react";

type CrmSaveTimeIconProps = {
  color?: string;
};

const CrmSaveTimeIcon = (props: CrmSaveTimeIconProps) => {
  const { color } = props;

  return (
    <svg
      width="73"
      height="72"
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.666504"
        width="72"
        height="72"
        rx="36"
        fill={color || "#195FE9"}
      />
      <path
        d="M39.6665 25V33.3478L45.6665 37"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.398 36.9464C26.572 34.4286 26.4427 31.7252 27.0245 29.1365C27.6063 26.5479 28.8764 24.1755 30.6936 22.2831C32.5107 20.3907 34.8038 19.0524 37.3179 18.4169C39.8319 17.7815 42.4685 17.8739 44.9345 18.6837C47.4004 19.4936 49.5992 20.9893 51.2863 23.0044C52.9735 25.0196 54.0829 27.4753 54.4913 30.0987C54.8996 32.722 54.591 35.4101 53.5996 37.8642C52.6081 40.3184 50.9728 42.4423 48.8754 44"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.6665 54V40M34.6665 47L28.6665 47M18.6665 42H28.9823C30.1548 42 31.2793 42.5268 32.1084 43.4645C32.9376 44.4021 33.4033 45.6739 33.4033 47H42.2455C43.418 47 44.5425 47.5268 45.3716 48.4645C46.2007 49.4021 46.6665 50.6739 46.6665 52H18.6665V42Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CrmSaveTimeIcon;
