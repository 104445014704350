import React from "react";
import first from "lodash/first";

import { Box, IconButton, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Trip } from "types";
import { OpenIcon } from "design-system/icons";
import RoundChip from "design-system/components/chips/RoundChip";
import { granite, pickUpVariantLabelColors } from "design-system/colors";
import flightStatusMap from "components/flights/helpers/flightStatusMap";

function PickUpLocationColumnV2(props: GridRenderCellParams) {
  const { row, api, id, field, value } = props;

  const { stops } = row as Trip;
  const pickupStop = first(stops);
  const pickupStopVariant = pickupStop.pickUpVariant;
  const flightStatus = pickupStop.trackedFlight?.flightStatus;

  const statusColor = flightStatus
    ? flightStatusMap(flightStatus).statusColor
    : null;

  // event handler
  const handleOpenIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    api.setCellMode(id, field, "edit");
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      onClick={(e) => e.stopPropagation()}
    >
      {/* open tracked flight icon */}
      {pickupStop.airport && flightStatus && (
        <Box display="flex" alignItems="center" ml={-1}>
          <IconButton onClick={handleOpenIconClick}>
            <OpenIcon color={granite} size="xs" />
          </IconButton>
        </Box>
      )}

      {/* stop address */}
      <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
        {value}
      </Typography>

      {/* curbside/meet & greet chip */}
      {pickupStopVariant && (
        <Box display={"flex"} flex={2} justifyContent={"flex-end"}>
          <RoundChip
            {...pickUpVariantLabelColors[pickupStopVariant]}
            whiteSpace={"nowrap"}
          />
        </Box>
      )}

      {/* flight status chip */}
      {pickupStop.airport && flightStatus && statusColor && (
        <Box ml={1}>
          <RoundChip
            label={flightStatus}
            color="white"
            backgroundColor={statusColor}
            textTransform="uppercase"
          />
        </Box>
      )}
    </Box>
  );
}

export default PickUpLocationColumnV2;
