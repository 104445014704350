import React, { useEffect } from "react";

import {
  Box,
  Button,
  Card,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import {
  alabaster,
  moovsBlue,
  moovsBlueHover,
  white,
} from "design-system/colors";
import { ArrowRightIcon, SalesAutomationIcon } from "design-system/icons";
import { useScreenSize, useAnalytics } from "globals/hooks";
import SubscribeToCrmDialog from "pages/settings/SubscriptionsPage/components/SubscriptionPlans/components/UpgradeSubscriptionDialog/SubscribeToCrmDialog";
import CRMInfoPageImage from "design-system/images/CRMInfoPageImage.jpg";
import CheckFilledIcon from "design-system/icons/info/CheckFilledIcon";
import CrmSaveMoneyIcon from "design-system/icons/info/CrmSaveMoneyIcon";
import CrmSpendConfidentIcon from "design-system/icons/info/CrmSpendConfidentIcon";
import CrmSaveTimeIcon from "design-system/icons/info/CrmSaveTimeIcon";

type SubscribeToCrmWithInfoProps = {
  isCrmDialogOpen: boolean;
  setIsCrmDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSubscribedSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

function SubscribeToCrmWithInfo(props: SubscribeToCrmWithInfoProps) {
  const { isCrmDialogOpen, setIsCrmDialogOpen, setSubscribedSuccess } = props;

  const { isMediumTabletView, isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  useEffect(() => {
    track("subscribeToCrmPage_visited");
  }, [track]);

  const largeScreen = !isMediumTabletView;

  // handlers
  const handleOpenCrmDialog = () => {
    track("subscribeToCrmPage_subscribeDialogOpened");
    setIsCrmDialogOpen(true);
  };
  const handleCloseCrmDialog = () => {
    setIsCrmDialogOpen(false);
  };

  return (
    <Box
      mx={largeScreen ? "300px" : isMobileView ? "20px" : "50px"}
      py={isMobileView ? 1.5 : 4}
    >
      {/* header */}
      <Box display="flex" justifyContent="center">
        <Box maxWidth="800px" width="100%">
          <Typography variant="h1" align="left">
            Drive Customer Relationships Forward <br />
            With{" "}
            <Typography
              variant="h1"
              color={moovsBlue}
              sx={{ display: "inline" }}
            >
              Moovs CRM.
            </Typography>
          </Typography>
        </Box>
      </Box>

      {/* sub header */}
      <Box my={4} display="flex" justifyContent="center">
        <Box maxWidth="800px" width="100%">
          <Typography variant="body1" align="left">
          Unlock the full potential of your contacts with Moovs CRM. Easily segment your customer base, create targeted email campaigns, and watch your booking numbers grow—all from the same platform you already use to run your transportation business. 
          </Typography>
        </Box>
      </Box>

      {/* middle image plus subscribe to crm button */}
      <Box 
        display="flex" 
        flexDirection={isMobileView ? "column" : "row"} 
        justifyContent="center" 
        alignItems="center"
        maxWidth="1400px"
        width="100%"
        mx="auto"
        gap={isMobileView ? 0 : 3}
      >
        <Box 
          display="flex" 
          justifyContent="flex-end" 
          alignItems="center"
          width={isMobileView ? "100%" : "auto"}
          mb={isMobileView ? 0 : undefined}
        >
          <img
            style={{ 
              borderRadius: "10px",
              maxWidth: isMobileView ? "100%" : "700px",
              width: "auto"
            }}
            src={CRMInfoPageImage}
            alt="crm-subscription"
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          width={isMobileView ? "100%" : "auto"}
          mt={isMobileView ? 0 : undefined}
          sx={{ 
            '& .MuiList-root': { 
              minWidth: isMobileView ? "auto" : "400px",
              width: isMobileView ? "100%" : "auto"
            },
            '& .MuiListItem-root': {
              padding: "12px 16px"
            },
            '& .MuiTypography-root': {
              fontSize: "1.1rem"
            },
            '& .MuiButton-root': {
              padding: "12px 24px"
            }
          }}
        >
          <CheckList handleOpenCrmDialog={handleOpenCrmDialog} />
        </Box>
      </Box>

      {/* bottom 3 cards */}
      <Box
        display="flex"
        gap={2}
        mt={isMobileView ? 0 : 4}
        maxWidth="1400px"
        flexDirection={isMobileView ? "column" : "row"}
      >
        <Box width={isMobileView ? "100%" : "33.33%"}>
          <InfoCard
            icon={<CrmSaveMoneyIcon />}
            title="Grow Your Revenue"
            description="Reach more customers instead of just relying on word-of-mouth. Targeted emails and SMS campaigns help you stay connected with past riders and find new ones."
          />
        </Box>
        <Box width={isMobileView ? "100%" : "33.33%"}>
          <InfoCard
            icon={<CrmSpendConfidentIcon />}
            title="Strengthen Your Customer Relationships"
            description="Build stronger connections with riders by remembering their preferences and special occasions. Automated birthday messages, ride anniversaries, and travel pattern insights help you make every customer feel valued and understood."
          />
        </Box>
        <Box width={isMobileView ? "100%" : "33.33%"}>
          <InfoCard
            icon={<CrmSaveTimeIcon />}
            title="Measure Your Success"
            description="Make smarter business decisions by knowing exactly which marketing efforts deliver real results. Detailed analytics track every reservation that comes from your campaigns, showing you which messages convert to actual bookings and revenue."
          />
        </Box>
      </Box>

      <SubscribeToCrmDialog
        open={isCrmDialogOpen}
        onClose={handleCloseCrmDialog}
        planName="Moovs CRM"
        pricePerMonth={299}
        discountPrice={99}
        discountMonths={3}
        icon={<SalesAutomationIcon />}
        bgColor={alabaster}
        setSubscribedSuccess={setSubscribedSuccess}
      />
    </Box>
  );
}

type CheckListProps = {
  handleOpenCrmDialog: () => void;
};

const CheckList = (props: CheckListProps) => {
  const { handleOpenCrmDialog } = props;

  return (
    <List>
      <CheckListItem text="$99/month (regular price $299/month)" />
      <CheckListItem text="No setup fees" />
      <CheckListItem text="No annual commitment" />
      <ListItem sx={{ my: 2, px: 1.5 }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleOpenCrmDialog}
        >
          <Typography fontSize={16} fontWeight={500}>
            Subscribe
          </Typography>

          <Box ml={1} display="flex" alignItems="center">
            <ArrowRightIcon color={white} size="small" />
          </Box>
        </Button>
      </ListItem>
      <ListItem>
        <Typography>
          Questions?{" "}
          <Link
            id="fab-help"
            target="_blank"
            underline="none"
            color={moovsBlue}
            sx={{ cursor: "pointer" }}
          >
            Speak with a representative
          </Link>
        </Typography>
      </ListItem>
    </List>
  );
};

const CheckListItem = (props) => {
  const { text } = props;
  return (
    <ListItem>
      <Box mr={2}>
        <CheckFilledIcon bgColor={moovsBlueHover} checkmarkColor={moovsBlue} />
      </Box>
      <ListItemText primary={text} />
    </ListItem>
  );
};

const InfoCard = (props) => {
  const { icon, title, description } = props;

  return (
    <Card variant="outlined" sx={{ borderRadius: "10px", height: '100%' }}>
      <Box p={3}>
        {icon}
        <Typography variant="h4" sx={{ my: 2 }}>
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>
    </Card>
  );
};
export default SubscribeToCrmWithInfo;
