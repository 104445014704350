import React from "react";
import { GridFilterInputValueProps } from "@mui/x-data-grid-pro";
import MultiSelectFilterInput from "./MultiSelectFilterInput";

const classificationOptions = [
  { name: "Farm-In", id: "farm-in" },
  { name: "Farm-Out", id: "farm-out" },
  { name: "Standard", id: "standard" },
];

export default function TripClassificationFilterInput(
  props: GridFilterInputValueProps
) {
  return <MultiSelectFilterInput {...props} options={classificationOptions} />;
}
