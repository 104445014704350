export * from "./operator.graphql";
export * from "./operatorRoute.graphql";
export * from "./airline.graphql";
export * from "./airport.graphql";
export * from "./automatedPricing";
export * from "./cancellationPolicy.graphql";
export * from "./comment.graphql";
export * from "./company.graphql";
export * from "./contact.graphql";
export * from "./contactAndAffiliate.graphql";
export * from "./dashboard.graphql";
export * from "./dispatch.graphql";
export * from "./driver.graphql";
export * from "./driverPayout.graphql";
export * from "./email.graphql";
export * from "./farming/externalOperator.graphql";
export * from "./farming/farmAffiliate.graphql";
export * from "./farming/farmedRoute.graphql";
export * from "./farming/farmVehicle.graphql";
export * from "./farming/farmPricing.graphql";
export * from "./farming/griddnetOperator.graphql";
export * from "./farming/moovsNetworkOperator.graphql";
export * from "./farming/moovsAiOperator.graphql";
export * from "./globalSearch.graphql";
export * from "./googleCalendar.graphql";
export * from "./insurancePolicy.graphql";
export * from "./invoice.graphql";
export * from "./moovsMarket.graphql";
export * from "./orderType.graphql";
export * from "./paymentMethod.graphql";
export * from "./payment.graphql";
export * from "./priceZone.graphql";
export * from "./recipient.graphql";
export * from "./request.graphql";
export * from "./route.graphql";
export * from "./routeStatusLog.graphql";
export * from "./stop.graphql";
export * from "./stripe.graphql";
export * from "./terms.graphql";
export * from "./trackedFlight.graphql";
export * from "./transaction.graphql";
export * from "./trip.graphql";
export * from "./twilio.graphql";
export * from "./user.graphql";
export * from "./vehicle.graphql";
export * from "./vehicleSettings.graphql";
export * from "./website.graphql";
export * from "./dynamicPricing";
export * from "./contactsAndFarmAffiliatesAndCompanies.graphql";
export * from "./financialAccount.graphql";
export * from "./subscription.graphql";
export * from "./shuttleRoute.graphql";
export * from "./promoCode.graphql";
export * from "./accessPermission.graphql";
export * from "./linkPassenger.graphql";
export * from "./customerFeedback.graphql";

export const EmptyVariables = {
  input: {},
};
