import React, { useState } from "react";

import { Box, IconButton, Typography } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";

import MoovsDialog from "components/MoovsDialog";
import { FlightInfoCard } from "components/flights";
import RoundChip from "design-system/components/chips/RoundChip";
import flightStatusMap from "components/flights/helpers/flightStatusMap";
import { granite, pickUpVariantLabelColors } from "design-system/colors";
import { OpenIcon } from "design-system/icons";

type PickUpLocationEditColumnType = {
  params: GridRenderEditCellParams;
};

function PickUpLocationEditColumn(props: PickUpLocationEditColumnType) {
  const {
    params: { row, api, id, field },
  } = props;

  // state
  const [dialogOpen, setDialogOpen] = useState(true);

  const pickUpStop = row.stops[0];
  const flightStatus = pickUpStop.trackedFlight?.flightStatus;

  if (!flightStatus) return null;

  const { statusColor } = flightStatusMap(
    pickUpStop.trackedFlight.flightStatus
  );

  // event handler
  const handleCloseDialog = () => {
    setDialogOpen(false);
    api.setCellMode(id, field, "view");
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center" width="100%">
      {/* open tracked flight icon */}
      <Box display="flex" alignItems="center" ml={-1}>
        <IconButton>
          <OpenIcon color={granite} size="xs" />
        </IconButton>
      </Box>

      {/* stop address */}
      <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
        {pickUpStop.location}
      </Typography>

      {/* curbside/meet & greet chip */}
      <RoundChip
        {...pickUpVariantLabelColors[pickUpStop.pickUpVariant]}
        whiteSpace={"nowrap"}
      />

      {/* flight status chip */}
      <RoundChip
        label={pickUpStop.trackedFlight.flightStatus}
        color="white"
        backgroundColor={statusColor}
        textTransform="uppercase"
      />

      {/* tracked flight card */}
      <MoovsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        hideTopBorder
        dialogTitle="Pick-up Flight Info"
        closeButtonText="Cancel"
        size="sm"
      >
        <FlightInfoCard trackedFlight={pickUpStop.trackedFlight} />
      </MoovsDialog>
    </Box>
  );
}

export default PickUpLocationEditColumn;
