import React from "react";

import { Stop, Vehicle } from "../../../types";
import { VehicleCardContent } from ".";
import { getCalendarVariant } from "globals/utils/getCalendarVariant";

type VehicleCardProps = {
  vehicle: Vehicle;
  selectedVehicles?: string[];
  onSelectVehicle?: (vehicle: Vehicle) => void;
  stops?: Stop[];
  ellipsisMenuActions?: {
    onPressRemove?: () => void;
    onPressReplace?: () => void;
  };
  showBRBlock?: boolean;
  onShowVehicleAvailabilityClick?: (
    vehicleAvailabilityDialogTitle: string
  ) => void;
  onSwoopFarmVehiclesDialogOpen?: () => void;
};

function VehicleCard(props: VehicleCardProps) {
  const {
    vehicle,
    selectedVehicles,
    onSelectVehicle,
    ellipsisMenuActions,
    showBRBlock,
    onShowVehicleAvailabilityClick,
    onSwoopFarmVehiclesDialogOpen,
    stops,
  } = props;

  // event handlers
  const handleSelectVehicle = () => {
    if (onSelectVehicle) onSelectVehicle(vehicle);
  };

  // derived state
  const isSelected =
    selectedVehicles &&
    selectedVehicles.some(
      (selectedVehicleId: string) => selectedVehicleId === vehicle.id
    );
  const vehicleCalendarVariant = getCalendarVariant(vehicle, stops);
  const vehicleTypeName = vehicle?.vehicleType?.typeName;
  const vehicleCapacity = `${vehicle.capacity} ${vehicle.capacity === 1 ? "person" : "persons"
    }`;

  return (
    <VehicleCardContent
      mainContent={{
        header: vehicle.name,
        subHeader: [vehicleTypeName, vehicleCapacity, vehicle.licensePlate],
        imageTitle: vehicle.name,
      }}
      automatedBaseRateProps={{
        showBRBlock,
        baseRateAmount: vehicle.baseRateAutomation?.total,
      }}
      vehicleCalendarVariant={vehicleCalendarVariant}
      onShowVehicleAvailabilityClick={onShowVehicleAvailabilityClick}
      isSelected={isSelected}
      ellipsisMenuActions={ellipsisMenuActions}
      photoUrl={vehicle.photos?.[0]?.url}
      onSwoopFarmVehiclesDialogOpen={onSwoopFarmVehiclesDialogOpen}
      {...(onSelectVehicle && { onSelectVehicle: handleSelectVehicle })}
    />
  );
}

export default VehicleCard;
