import React, { useCallback, useRef, useState } from "react";
import moment, { Moment } from "moment-timezone";
import size from "lodash/size";

import {
  Box,
  Button,
  MenuList,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";

import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckIcon,
} from "../../../design-system/icons";
import {
  grayLight,
  moovsBlue,
  moovsBlueSelected,
} from "../../../design-system/colors";
import {
  timeViews,
  driverPayoutTimeViews,
  getTimeViewValueFromDates,
  getAssociatedTimeView,
  TimeViewsEnum,
  getAssociatedDriverPayoutView,
} from "../../../globals/utils/views";
import { getElementWidthById } from "../../../globals/utils/helpers";
import AnchorPopper from "../AnchorPopper";
import useQueryParamsToSetDates from "./useQueryParamsToSetDates";
import { DateRange } from "./types";

// string constants
const { SPECIFIC_DATE, CUSTOM_RANGE, ALL } = TimeViewsEnum;

const DAY = "day";

const dateFilterCenterButtonId = "date-filter-center-button";

const styles = {
  chevronRoot: {
    minWidth: "0px",
  },
  selectedMenuItem: {
    "&.Mui-selected": {
      borderRadius: "5px",
      fontWeight: 500,
      backgroundColor: moovsBlueSelected,
      color: moovsBlue,
      ":hover": {
        backgroundColor: grayLight,
      },
    },
  },
  viewItem: {
    borderRadius: "5px",
    marginTop: "2px",
    marginBottom: "2px",
    textAlign: "center",
    width: "95%",
    ":hover": {
      backgroundColor: grayLight,
    },
  },
};

type DateFilterProps = {
  isMobileView: boolean;
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  mode: "reservations" | "dispatch" | "driver-payout";
  hasMinWidth?: boolean;
};

function DateFilter(props: DateFilterProps) {
  const { hasMinWidth, isMobileView, mode, dateRange } = props;
  const [startDate, endDate] = dateRange;

  // whenever we set date range
  const setDateRange = useCallback(
    (dateRange: DateRange) => {
      props.setDateRange(dateRange);

      if (dateRange) {
        window.localStorage.setItem(
          `${mode}-customDateRange`,
          JSON.stringify(dateRange)
        );

        const currentTimezone = moment.tz.guess(true);

        window.localStorage.setItem(
          `${mode}-customDateRange-timezone`,
          JSON.stringify(currentTimezone || "")
        );
      }
    },
    [props, mode]
  );

  // state
  const [showSpecificDatePicker, setShowSpecificDatePicker] = useState(false);
  const [showCustomRangePicker, setShowCustomRangePicker] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [pendingDateRange, setPendingDateRange] =
    useState<[Moment, Moment]>(null);

  // refs
  const anchorRef = useRef(null);

  // hooks
  useQueryParamsToSetDates({
    setDateRange,
    dateRange,
    mode,
  });

  const modeMap = (mode: "reservations" | "dispatch" | "driver-payout") =>
    ({
      "driver-payout": {
        getAssociatedViewObject: getAssociatedDriverPayoutView,
        menuList: driverPayoutTimeViews,
      },
      dispatch: {
        getAssociatedViewObject: getAssociatedTimeView,
        menuList: timeViews,
      },
      reservations: {
        getAssociatedViewObject: getAssociatedTimeView,
        menuList: timeViews,
      },
    }[mode]);

  const { getAssociatedViewObject, menuList } = modeMap(mode);

  const timeViewValue = size(dateRange)
    ? getTimeViewValueFromDates(dateRange, mode)
    : ALL;

  const { timespan, getButtonDisplayLabel } =
    getAssociatedViewObject(timeViewValue);

  // event handlers
  const handleDateShift = (direction: "next" | "previous") => {
    const isNext = direction === "next";

    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const numDaysInRange = endDateMoment.diff(startDateMoment, "days");

    if (isNext) {
      setDateRange([
        endDateMoment.clone().add(1, "day").format("YYYY-MM-DD"),
        endDateMoment
          .clone()
          .add(numDaysInRange + 1, "days")
          .format("YYYY-MM-DD"),
      ]);
    } else {
      setDateRange([
        startDateMoment
          .clone()
          .subtract(numDaysInRange + 1, "days")
          .format("YYYY-MM-DD"),
        startDateMoment.clone().subtract(1, "day").format("YYYY-MM-DD"),
      ]);
    }
  };

  const handleViewMenuItemClick =
    (selectedTimeViewValue: TimeViewsEnum) => () => {
      const { timespan, defaultStartDate, defaultEndDate } =
        getAssociatedViewObject(selectedTimeViewValue);

      if (selectedTimeViewValue === SPECIFIC_DATE) {
        setShowSpecificDatePicker(true);
      } else if (selectedTimeViewValue === CUSTOM_RANGE) {
        setShowCustomRangePicker(true);
      } else if (selectedTimeViewValue === ALL) {
        setDateRange([defaultStartDate, defaultEndDate]);
      } else {
        setDateRange([
          defaultStartDate,
          timespan === DAY ? defaultStartDate : defaultEndDate,
        ]);
      }
      setMenuOpen(false);
    };

  // specific date handler
  const handleSpecificDatePickerSelection = (selectedDate: Moment) => {
    const formattedDate = selectedDate.format("YYYY-MM-DD");

    setDateRange([formattedDate, formattedDate]);
    setShowSpecificDatePicker(false);
  };

  const handleCustomRangePickerChange = (dateRange: [Moment, Moment]) => {
    setPendingDateRange(dateRange);
  };

  const handleDateRangeClickAway = () => {
    setShowSpecificDatePicker(false);
    setShowCustomRangePicker(false);
  };

  const handleDateRangeSelect = ([startDate, endDate]: [Moment, Moment]) => {
    setDateRange([
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
    ]);
  };

  const buttonDisplayLabel = getButtonDisplayLabel(startDate, endDate);
  const centerButtonWidth = getElementWidthById(dateFilterCenterButtonId);
  const isDriverPayout = mode === "driver-payout";

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        pb={isMobileView ? 1 : 0}
        {...(hasMinWidth &&
          !isMobileView && { minWidth: timespan === DAY ? "270px" : "350px" })}
      >
        {!isDriverPayout && (
          <Button
            sx={styles.chevronRoot}
            onClick={() => handleDateShift("previous")}
          >
            <ChevronLeftIcon size="small" />
          </Button>
        )}
        <Box flex="1">
          <Button
            id={dateFilterCenterButtonId}
            fullWidth
            ref={anchorRef}
            onClick={() => setMenuOpen((prevOpen) => !prevOpen)}
          >
            <CalendarIcon />
            <Typography style={{ marginLeft: "8px" }}>
              {buttonDisplayLabel}
            </Typography>
          </Button>
          <AnchorPopper
            open={menuOpen}
            anchorRef={anchorRef.current}
            onClickAway={() => setMenuOpen(false)}
            placement="bottom"
          >
            <Box>
              <MenuList
                autoFocusItem={menuOpen}
                sx={{
                  minWidth: "191px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                {...(centerButtonWidth && {
                  style: { width: centerButtonWidth },
                })}
              >
                {menuList.map(({ menuLabel, value: viewValue }) => {
                  const isSelected = timeViewValue === viewValue;
                  return (
                    <MenuItem
                      selected={isSelected}
                      sx={[styles.viewItem, styles.selectedMenuItem]}
                      key={viewValue}
                      onClick={handleViewMenuItemClick(viewValue)}
                    >
                      <Box
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                      >
                        {menuLabel}
                        {isSelected && (
                          <Box mt="2px">
                            <CheckIcon color={moovsBlue} size="small" />
                          </Box>
                        )}
                      </Box>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Box>
          </AnchorPopper>
        </Box>
        {!isDriverPayout && (
          <Button
            sx={styles.chevronRoot}
            onClick={() => handleDateShift("next")}
          >
            <ChevronRightIcon size="small" />
          </Button>
        )}
      </Box>

      {/* Date Pickers */}
      <AnchorPopper
        anchorRef={anchorRef.current}
        open={showSpecificDatePicker || showCustomRangePicker}
        onClickAway={handleDateRangeClickAway}
      >
        <>
          {showSpecificDatePicker && (
            <StaticDatePicker
              label="End Date"
              inputFormat="MMM Do YYYY"
              value={endDate || moment().toISOString()}
              onChange={handleSpecificDatePickerSelection}
              displayStaticWrapperAs="desktop"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="pickupDateTime"
                  variant="outlined"
                />
              )}
            />
          )}

          {showCustomRangePicker && (
            <StaticDateRangePicker
              disableCloseOnSelect={false}
              value={pendingDateRange || dateRange}
              onChange={handleCustomRangePickerChange}
              onAccept={handleDateRangeSelect}
              onClose={() => {
                setPendingDateRange(null);
                setShowCustomRangePicker(false);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} variant="outlined" />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField variant="outlined" {...endProps} />
                </>
              )}
            />
          )}
        </>
      </AnchorPopper>
    </>
  );
}

export default DateFilter;
