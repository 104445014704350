import moment from "moment-timezone";

import {
  getAssociatedTimeView,
  getTimeViewValueFromDates,
  TimeViewsEnum,
  QuoteStatusViewsEnum,
  ReservationStatusViewsEnum,
} from "../../globals/utils/views";
import { Trip } from "../../types";

export function orderTripsByAscendingStopTimes(trips: Trip[]) {
  return [...trips].sort((tripA, tripB) => {
    return moment(tripA.stops[0].dateTime).diff(
      moment(tripB.stops[0].dateTime)
    );
  });
}

type TripContact = {
  name: string;
  email?: string;
  mobilePhone: string;
  mobilePhoneInternational: string;
};

export function getTripContactFromTrip(
  trip: Trip,
  isFarmee?: boolean
): TripContact {
  const hasTemporaryPassenger = !!trip.temporaryPassenger?.name;

  return hasTemporaryPassenger
    ? {
        name: trip.temporaryPassenger.name,
        email: trip.temporaryPassenger.email,
        mobilePhone: trip.temporaryPassenger.mobilePhone,
        mobilePhoneInternational:
          trip.temporaryPassenger.mobilePhoneInternational,
      }
    : {
        name: isFarmee
          ? `${trip.contact.firstName} ${trip.contact.lastName.charAt(0)}`
          : `${trip.contact.firstName} ${trip.contact.lastName}`,
        email: trip.contact.email,
        mobilePhone: trip.contact.mobilePhone,
        mobilePhoneInternational: trip.contact.mobilePhoneInternational,
      };
}

const { NEW, SENT, DRAFT, ALL, ARCHIVED } = QuoteStatusViewsEnum;

export function renderNoQuotesText(statusView) {
  let titleText;
  let copyText;

  if (statusView === NEW) {
    titleText = "No New Quotes";
  } else if (statusView === SENT) {
    titleText = "No Sent Quotes";
  } else if (statusView === DRAFT) {
    titleText = "No Draft Quotes";
  } else if (statusView === ARCHIVED) {
    titleText = "No Archived Quotes";
  } else if (statusView === ALL) {
    titleText = "No Quotes";
    copyText = "Create one to get started!";
  }
  return [titleText, copyText];
}

const { THIS_WEEK, SPECIFIC_DATE, CUSTOM_RANGE } = TimeViewsEnum;

export function renderNoReservationsText(
  startDate: string,
  endDate: string,
  view: string | string[]
) {
  let titleText: string;
  let copyText: string;
  let viewText: string;

  const timeView = getTimeViewValueFromDates([startDate, endDate]);

  // add messaging dependent on date of empty reservations view
  switch (timeView) {
    case SPECIFIC_DATE:
      titleText = `on ${moment(startDate).format("MM.DD.YYYY")}`;
      copyText = "Try selecting another date";
      break;
    case CUSTOM_RANGE:
      titleText = `from ${moment(startDate).format("MM.DD.YYYY")} to ${moment(
        endDate
      ).format("MM.DD.YYYY")}`;
      copyText = "Try selecting a different date range";
      break;
    case THIS_WEEK:
      titleText = "This Week";
      copyText = "Try selecting a custom date range";
      break;
    default:
      titleText = getAssociatedTimeView(timeView)?.menuLabel;
      copyText = "Try checking another date";
      break;
  }

  // add text for specifying reservation view
  switch (view) {
    case ReservationStatusViewsEnum.OPEN:
      viewText = "Open";
      break;
    case ReservationStatusViewsEnum.CLOSED:
      viewText = "Closed";
      break;
    case ReservationStatusViewsEnum.NOT_PAID:
      viewText = "Unpaid";
      break;
    default:
      viewText = "";
      break;
  }

  titleText = `No ${viewText} Reservations ${titleText}`;
  return [titleText, copyText];
}

export const tripHasChangeProposal = (trip: Trip) => {
  return trip.tripChangeProposal !== null && trip.tripChangeProposal !== undefined;
};