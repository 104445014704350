import React from "react";

import { Box, Typography } from "@mui/material";

type PlanInfoSectionProps = {
  planName: string;
  icon: JSX.Element;
  bgColor: string;
  pricePerMonth: number;
  discountPrice?: number;
  discountMonths?: number;
};

function PlanInfoSection(props: PlanInfoSectionProps) {
  const { planName, icon, bgColor, pricePerMonth, discountPrice, discountMonths } = props;

  return (
    <Box
      bgcolor={bgColor}
      p={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center" py={1}>
        <Box px={1}>{icon}</Box>
        <Typography variant="h4" px={1}>
          {planName}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end" pr={1}>
        {discountPrice && discountMonths ? (
          <>
            <Box display="flex" alignItems="center">
              <Typography variant="h2" pr={0.5} sx={{ textDecoration: "line-through", color: "text.secondary" }}>
                {`$${pricePerMonth}`}
              </Typography>
              <Typography variant="caption" sx={{ color: "text.secondary" }}>/month</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h2" pr={0.5} color="primary">
                {`$${discountPrice}`}
              </Typography>
              <Typography variant="caption">/month</Typography>
              <Typography variant="caption" ml={0.5} color="primary.main">
                (for the first {discountMonths} months)
              </Typography>
            </Box>
          </>
        ) : (
          <Box display="flex" alignItems="center">
            <Typography variant="h2" pr={0.5}>
              {`$${pricePerMonth}`}
            </Typography>
            <Typography variant="caption">/month</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default PlanInfoSection;
