import { Dispatch, SetStateAction } from "react";
import moment from "moment";
import first from "lodash/first";
import last from "lodash/last";
import size from "lodash/size";

import {
  GridValueGetterParams,
  GridValueFormatterParams,
  GridValueSetterParams,
  GridColDef,
} from "@mui/x-data-grid-pro";

import { orderTypeEnumToNameMap } from "globals/utils/enumMaps";
import { FarmRelationshipEnum, Trip } from "types";
import { convertMinutesToHoursMinutes, currency } from "globals/utils/helpers";
import { getTripIconAndCategoryType } from "pages/requests/utils/getTripIconAndCategoryType";
import { renderEditCell } from "./dataGridEditRenderer";
import {
  DispatchStatusEditColumn,
  AlertsEditColumn,
  DriverEditColumn,
} from "../components/editCellComponents";
import {
  AffiliateColumn,
  AlertsColumn,
  BookingContactColumn,
  CompanyColumn,
  ConfNumberColumn,
  DispatchStatusColumn,
  DriverColumn,
  DriverNoteColumn,
  DropOffLocationColumn,
  DropOffTimeColumn,
  DurationColumn,
  PassengerColumn,
  PickUpLocationColumn,
  PickUpTimeColumn,
  PaidStatusColumn,
  TripNotesColumn,
} from "../components";
import { getPassenger } from "globals/utils/passenger";

type TripValueGetterParams = GridValueGetterParams<Trip, Trip>;

const defaults = {
  sortable: false,
  hide: false,
  width: 140,
};

export const dataGridDefaultConfig = (
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >,
  refetchTripsData: () => void
): GridColDef[] => {
  return [
    {
      ...defaults,
      headerName: "Conf. No.",
      field: "completeOrderNumber",
      valueGetter: ({ row }: TripValueGetterParams) =>
        `${row.request.orderNumber}-${row.tripNumber}`,
      renderCell: ConfNumberColumn,
    },

    {
      ...defaults,
      headerName: "Trip Status",
      field: "statusSlug",
      valueGetter: ({ row }: TripValueGetterParams) =>
        row.routes[0].dispatchStatus,
      renderCell: DispatchStatusColumn,
      renderEditCell: renderEditCell(
        DispatchStatusEditColumn,
        setSaveIndicatorState
      ),
      editable: true,
    },

    {
      ...defaults,
      headerName: "Order Type",
      field: "orderType",
      valueGetter: ({ row }: TripValueGetterParams) =>
        orderTypeEnumToNameMap[row.request.orderType],
    },

    {
      ...defaults,
      width: 200,
      headerName: "Passenger",
      field: "tripContact",
      valueGetter: ({
        row: { tempPassenger, contact, routes },
      }: TripValueGetterParams) => {
        const { farmRelationship } = routes[0];

        const isFarmee = farmRelationship === FarmRelationshipEnum.Farmee;
        const passenger = getPassenger(contact, tempPassenger, isFarmee);
        return passenger.name;
      },
      renderCell: PassengerColumn,
    },

    {
      ...defaults,
      width: 200,
      headerName: "Driver",
      field: "driverRow", // field needs to not be named "driver" otherwise this will override route.driver
      valueGetter: ({ row: { routes } }: TripValueGetterParams) =>
        routes[0].routeDriver?.driver.firstName
          ? `${routes[0].routeDriver.driver.firstName} ${
              routes[0].routeDriver?.driver.lastName || ""
            }`
          : null,
      valueSetter: (params: GridValueSetterParams) => {
        refetchTripsData();
        const driver = params.value;
        return { ...params.row, value: driver };
      },
      renderCell: DriverColumn,
      editable: true,
      renderEditCell: renderEditCell(DriverEditColumn, setSaveIndicatorState),
    },

    {
      ...defaults,
      headerName: "Company",
      field: "company",
      valueGetter: ({ row }: TripValueGetterParams) => row.request.company,
      renderCell: CompanyColumn,
    },

    {
      ...defaults,
      headerName: "Vehicle",
      field: "vehicle",
      valueGetter: ({ row }: TripValueGetterParams) =>
        row.routes[0]?.vehicle?.name || "",
    },

    {
      ...defaults,
      headerName: "Date",
      field: "date",
      type: "date",
      valueGetter: ({ row }: TripValueGetterParams) =>
        first(row.stops).dateTime,
      valueFormatter: ({ value }: GridValueFormatterParams) =>
        moment.utc(value as Date).format("M/DD/YY"),
    },

    {
      ...defaults,
      headerName: "Pick-up Time",
      field: "pickupTime",
      type: "dateTime",
      valueGetter: ({ row }: TripValueGetterParams) => {
        return {
          datetimeUtc: first(row.stops).dateTime,
          timezoneId: first(row.stops).timezoneId,
        };
      },
      valueFormatter: ({ value }: GridValueFormatterParams) =>
        moment.utc(value as Date).format("LT"),
      renderCell: PickUpTimeColumn,
    },

    {
      ...defaults,
      headerName: "Drop-off Time",
      field: "dropoffTime",
      type: "dateTime",
      valueGetter: ({ row }: TripValueGetterParams) => {
        const pickup = first(row.stops);
        const dropoff = last(row.stops);
        const timezoneId = pickup.timezoneId;

        return dropoff.dateTime
          ? {
              datetimeUtc: dropoff.dateTime,
              timezoneId,
            }
          : {
              datetimeUtc: moment
                .utc(pickup.dateTime)
                .add(row.estimatedDuration, "minutes"),
              timezoneId,
            };
      },
      valueFormatter: ({ value }: GridValueFormatterParams) =>
        moment.utc(value as Date).format("LT"),
      renderCell: DropOffTimeColumn,
    },

    {
      ...defaults,
      headerName: "Duration",
      field: "duration",
      valueGetter: ({ row }: TripValueGetterParams) =>
        convertMinutesToHoursMinutes(
          row.useTotalDuration ? row.totalDuration : row.estimatedDuration
        ),
      renderCell: DurationColumn,
    },

    {
      ...defaults,
      width: 300,
      headerName: "Pick-up",
      field: "pickupLocation",
      valueGetter: ({ row }: TripValueGetterParams) =>
        first(row.stops).location,
      renderCell: PickUpLocationColumn,
    },

    {
      ...defaults,
      headerName: "# Of Stops",
      field: "stopCount",
      type: "number",
      valueGetter: ({ row }: TripValueGetterParams) => size(row.stops) - 2,
    },

    {
      ...defaults,
      width: 300,
      headerName: "Drop-off",
      field: "dropoffLocation",
      valueGetter: ({ row }: TripValueGetterParams) =>
        last(row.stops)?.location,
      renderCell: DropOffLocationColumn,
    },

    {
      ...defaults,
      headerName: "PAX Count",
      field: "totalGroupSize",
      type: "number",
      valueGetter: ({ row }: TripValueGetterParams) => row.totalGroupSize,
    },

    {
      ...defaults,
      headerName: "Trip Classification",
      field: "tripClassification",
      valueGetter: ({ row: { routes } }: TripValueGetterParams) =>
        routes[0].farmRelationship === FarmRelationshipEnum.Farmee
          ? "Farm-In"
          : routes[0].farmRelationship === FarmRelationshipEnum.Farmor
          ? "Farm-Out"
          : "Standard",
    },

    {
      ...defaults,
      headerName: "Trip Type",
      field: "tripCategory",
      valueGetter: ({ row }: TripValueGetterParams) =>
        getTripIconAndCategoryType({
          tripCategory: row.tripCategory,
          roundTripVariant: row.roundTripVariant,
        }).text,
    },

    {
      ...defaults,
      headerName: "Trip Notes",
      field: "tripNote",
      valueGetter: ({ row }: TripValueGetterParams) => row.note,
      renderCell: TripNotesColumn,
    },

    {
      ...defaults,
      headerName: "Driver Notes",
      field: "driverNote",
      valueGetter: ({ row: { routes } }: TripValueGetterParams) =>
        routes[0].driverNote,
      renderCell: DriverNoteColumn,
      editable: true,
    },

    {
      ...defaults,
      headerName: "Alerts",
      field: "alerts",
      renderCell: AlertsColumn,
      renderEditCell: renderEditCell(AlertsEditColumn, setSaveIndicatorState),
      editable: true,
    },

    {
      ...defaults,
      headerName: "Booking Contact",
      field: "bookingContact",
      valueGetter: ({ row }: TripValueGetterParams) => {
        const bookingContact = row.request.bookingContact;

        return `${bookingContact?.firstName || ""} ${
          bookingContact?.lastName || ""
        }`;
      },
      renderCell: BookingContactColumn,
    },

    {
      ...defaults,
      headerName: "Total Amount",
      field: "totalAmt",
      type: "number",
      valueGetter: ({ row }: TripValueGetterParams) => {
        const route = first(row.routes);

        const totalAmount =
          route.farmRelationship === FarmRelationshipEnum.Farmee
            ? route.farmeePricing?.totalAmt
            : route.pricing?.totalAmt;

        return totalAmount || 0;
      },
      valueFormatter: ({ value }) => {
        return currency(Number(value));
      },
    },

    {
      ...defaults,
      headerName: "Affiliate",
      field: "affiliate",
      valueGetter: ({ row: { routes } }: TripValueGetterParams) =>
        routes[0].farmAffiliate?.operatorName || "",
      renderCell: AffiliateColumn,
    },

    {
      ...defaults,
      headerName: "Close Status",
      field: "closeStatus",
      valueGetter: ({ row }: TripValueGetterParams) =>
        row.closedAt ? "Closed" : "Open",
    },

    {
      ...defaults,
      headerName: "Paid Status",
      field: "paidStatus",
      valueGetter: ({ row }: TripValueGetterParams) =>
        row.amountDue > 0 ? "Not Paid" : "Paid",
      renderCell: PaidStatusColumn,
    },
  ];
};
