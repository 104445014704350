import React, { useState, Fragment, useEffect, useCallback } from "react";
import { DeepPartial } from "ts-essentials";
import { useMutation, useLazyQuery } from "@apollo/client";
import upperCase from "lodash/upperCase";
import last from "lodash/last";
import first from "lodash/first";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import size from "lodash/size";
import omit from "lodash/omit";
import toLower from "lodash/toLower";
import { useHistory, useLocation } from "react-router-dom";
import { parse, stringify } from "query-string";

import { Box } from "@mui/material";

import {
  Request,
  Trip,
  Vehicle,
  Stop,
  RoutePricing,
  TripCategory,
  VehicleChildSeat,
  Contact,
} from "types";
import { useSnackbar } from "globals/hooks/useSnackbar";
import {
  CREATE_TRIP_MUTATION,
  LOAD_REQUEST_QUERY,
  LOAD_AUTOMATED_BASE_RATE,
} from "globals/graphql";
import { useLocalStorage } from "globals/hooks/useLocalStorage";
import { useAnalytics, useOperator, useScreenSize } from "globals/hooks";
import { calculateTotalDurationMinutes } from "globals/utils/stops";
import { shapeStopsToAutomatedBaseRateQueryStopsArg } from "globals/graphql/automatedPricing";
import CreateDrawer from "../../../globals/CreateDrawer";
import AddVehicleCreateBlock from "../../../vehicles/AddVehicleCreateBlock";
import CommentCreateBlock from "../../../CommentCreateBlock";
import {
  initialRequestTemplate,
  initialRequestErrors,
  convertTripToBeInputReady,
  removeStop,
  createStop,
} from "globals/utils/createRequests";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { initialLuggageFields } from "components/requests/luggage/initialValues";
import { useCreateRequestTripType } from "./hooks/useCreateRequestTripType";
import {
  AdditionalInfoCreateBlock,
  PricingInfoCreateBlock,
  TripDetailsCreateBlock,
  TripInfoCreateBlock,
  TripTypeButtonsBlock,
} from "./components";
import { parsePricingLayoutForInitialRoute } from "./helpers/parsePricingLayoutForInitialRoute";
import { getChildSeatOptionsFromVehicle } from "./helpers/getChildSeatOptionsFromVehicle";
import { getContactForReservation } from "globals/utils/contact";

export type CreateTripDrawerProps = {
  addTripFor: "quote" | "reservation";
};

function CreateTripDrawer(props: CreateTripDrawerProps) {
  const { addTripFor } = props;

  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation<{ request: Request; trip: Trip }>();
  const { settings, enabledTripCategories } = useOperator() || {};
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  const isAddTripToQuote = addTripFor === "quote";
  const orderNumber = location?.state?.request?.orderNumber;
  const bookingContact = location?.state?.request?.bookingContact;

  const pageLabel = `Add Trip to ${orderNumber}`;
  const modeEnum = upperCase(addTripFor);
  const localStorageKey = isAddTripToQuote
    ? "submitQuoteSettings"
    : "submitReservationSettings";
  const getCloseDrawerLink = (
    requestId: string,
    isRoundTripAndSavedTrip?: boolean
  ) => {
    return isRoundTripAndSavedTrip
      ? `/${addTripFor}s/${requestId}/add-return-trip/`
      : `/${addTripFor}s/${requestId}/`;
  };

  // state
  const [request, setRequest] = useState<DeepPartial<Request>>(
    JSON.parse(JSON.stringify(initialRequestTemplate))
  );
  const [requestErrors, setRequestErrors] = useState(
    JSON.parse(JSON.stringify(initialRequestErrors))
  );
  const [saveErrorText, setSaveErrorText] = useState("");
  const [selectedSubmitButtonIndex, setSelectedSubmitButtonIndex] =
    useLocalStorage(localStorageKey, 0);
  const [suggestedAddressInfo, setSuggestedAddressInfo] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [isVehicleBaseRateAutomated, setIsVehicleBaseRateAutomated] =
    useState(false);
  const [automatedBaseRate, setAutomatedBaseRate] = useState(null);
  const [luggageAmount, setLuggageAmount] = useState(initialLuggageFields);
  const [isBaseRateAutomationClicked, setIsBaseRateAutomationClicked] =
    useState(false);
  const [childSeatOptions, setChildSeatOptions] = useState<VehicleChildSeat[]>(
    []
  );

  // more hooks
  const { tripType, onTripTypeChange, submitLabelOptions } =
    useCreateRequestTripType({
      enabledTripCategories,
      setRequest,
      defaultSubmitLabelOptions: ["Save trip"],
    });

  // query for automated base rate
  const [getAutomatedBaseRate, { loading: automatedBaseRateLoading }] =
    useLazyQuery(LOAD_AUTOMATED_BASE_RATE, {
      onCompleted(data) {
        setAutomatedBaseRate(data.automatedBaseRate);
      },
      onError(error) {
        const errorMessage =
          getErrorMessage(error) || "Failed to get automated base rate";

        snackbar.error(errorMessage);
      },
      fetchPolicy: "network-only",
    });

  // derived state
  const stops = request.trips[0].stops;

  const vehicleId = request.trips[0].routes[0].vehicle?.id;
  const hasEnabledVehicleBRA =
    request.trips[0].routes[0].vehicle?.enableBaseRateAutomation;
  const shouldSendEmail = selectedSubmitButtonIndex === 1;
  const linkedPassengerIds =
    bookingContact?.linkedPassengers?.map((passenger) => passenger.id) || [];

  // mutations
  const [createTrip] = useMutation(CREATE_TRIP_MUTATION, {
    onCompleted(data) {
      const tripId = data.createTrip.trip.id;

      const isRoundTrip = tripType === TripCategory.RoundTrip;

      const snackbarText = isRoundTrip
        ? "Pick-up trip successfully saved"
        : "Successfully added trip!";

      snackbar.success(snackbarText);

      const trip = data.createTrip.trip;

      if (trip.tempPassenger?.name) {
        track(`${addTripFor}_tripAdded`, {
          passengerType: "Temporary Passenger",
          ...(tripType && { tripType: toLower(tripType) }),
        });
      } else if (
        trip.contact.id === bookingContact.id &&
        !trip.tempPassenger?.name
      ) {
        track(`${addTripFor}_tripAdded`, {
          passengerType: "Booking Contact",
          ...(tripType && { tripType: toLower(tripType) }),
        });
      } else if (linkedPassengerIds.includes(trip.contact.id)) {
        track(`${addTripFor}_tripAdded`, {
          passengerType: "Linked Passenger",
          ...(tripType && { tripType: toLower(tripType) }),
        });
      }

      handleCreateRequestDrawerClose(tripId, data.createTrip.trip);
    },
    onError(error) {
      setSubmitDisabled(false);
      const errorMessage = getErrorMessage(error) || "Error creating trip";

      snackbar.error(errorMessage);
    },
    refetchQueries: [
      {
        query: LOAD_REQUEST_QUERY,
        variables: {
          id: request.id,
        },
      },
      "LoadTripConflict",
    ],
    awaitRefetchQueries: true,
  });

  // event handlers
  const updateTrip = useCallback(
    (newTrip: DeepPartial<Trip>, tripIndex: number) => {
      setRequest((prevRequest) => {
        const newTrips = [...prevRequest.trips];
        newTrips.splice(tripIndex, 1, newTrip);
        return {
          ...prevRequest,
          trips: newTrips,
        };
      });
    },
    []
  );

  const handleCreateRequestDrawerClose = (tripId?: string, trip?: Trip) => {
    let search = location.search;

    // if passed a tripId, makes sure that that tripId is showing when drawer closes
    if (tripId) {
      const parsed = parse(search);

      search = `?${stringify({
        ...parsed,
        tripId,
      })}`;
    }

    setTimeout(() => {
      history.push({
        search,
        pathname: getCloseDrawerLink(
          request.id,
          tripType === TripCategory.RoundTrip && !!tripId
        ),
        state: {
          request: {
            ...request,
            orderNumber,
            bookingContact: location?.state?.request?.bookingContact,
          },
          trip: trip,
          from: "add_trip",
        },
      });
    }, 200);
  };

  const updateRoute =
    (tripIndex: number) =>
    (targetName: string, targetValue: number | string | boolean) => {
      const newRoutes = [...request.trips[tripIndex].routes];
      const newRoute = newRoutes[0];

      newRoute[targetName] = targetValue;
      const newTrip = { ...request.trips[tripIndex], routes: newRoutes };

      updateTrip(newTrip, tripIndex);
    };

  const handleCreateStop = (tripIndex: number) => () => {
    const newTrips = createStop(request.trips, tripIndex);

    const stopsErrors = [...requestErrors.stops];
    const lastStopErrors = stopsErrors.pop();
    requestErrors.stops = [...requestErrors.stops];

    setRequestErrors({
      ...requestErrors,
      stops: [
        ...stopsErrors,
        {
          location: "",
          dateTime: "",
          stopIndex: requestErrors.stops.length,
        },
        {
          ...lastStopErrors,
          stopIndex: requestErrors.stops.length + 1,
        },
      ],
    });

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const handleRemoveStop = (tripIndex: number) => (stopIndex: number) => () => {
    const newTrips = removeStop(request.trips, tripIndex, stopIndex);

    setRequest({
      ...request,
      trips: newTrips,
    });

    requestErrors.stops.splice(stopIndex - 1, 1);

    setRequestErrors({
      ...requestErrors,
      stops: requestErrors.stops,
    });
  };

  const addVehicles = (tripIndex: number) => (vehicles: Partial<Vehicle>[]) => {
    setIsVehicleBaseRateAutomated(vehicles[0].enableBaseRateAutomation);

    const childSeats = getChildSeatOptionsFromVehicle(vehicles[0]);
    setChildSeatOptions(childSeats);

    const newTrips = [...request.trips];
    newTrips[tripIndex].routes[0].vehicle = { id: vehicles[0].id };

    setRequest({
      ...request,
      trips: newTrips,
    });
  };

  const removeVehicle = (tripIndex: number) => () => {
    setIsVehicleBaseRateAutomated(false);
    setAutomatedBaseRate(null);
    setChildSeatOptions([]);
    const newTrips = [...request.trips];
    delete newTrips[tripIndex].routes[0].vehicle;

    setRequest({
      ...request,
      trips: newTrips,
    });
    setIsBaseRateAutomationClicked(false);
  };

  const handleSubmitClick = () => {
    let errors = [];

    let newSaveErrorText = "Oops! Please check the above fields for errors.";

    // quote does not need to validate vehicle
    if (
      !isAddTripToQuote &&
      !request.trips[0].routes[0].vehicle
    ) {
      errors = [...errors, { vehicle: "Please select a vehicle" }];
    }

    // stop errors
    const stopErrors = request.trips[0].stops.map((stop) => {
      const addressErrors = {
        location: !stop.location ? "Please enter location" : "",
      };

      const airportErrors = {
        airport: !stop.airport ? "Please select an Airport" : "",
        airline:
          !!stop.flightNumber && !stop.airline
            ? "Please select an Airline"
            : "",
      };

      const isLocationError = !!addressErrors.location;
      const isAirportError = Object.values(airportErrors).some(
        (error) => !!error.length
      );

      // TODO: possibly generalize this error handling across create quote/reservation,
      // add trip, duplicate, roundtrip, etc. given its repetition?
      const isPickUpDateTimeError = !stop.dateTime && stop.stopIndex === 1;
      const isDropOffDateTimeError =
        tripType === TripCategory.Hourly &&
        !stop.dateTime &&
        stop.stopIndex === size(first(request.trips).stops);

      const dateTimeErrors = {
        dateTime:
          isPickUpDateTimeError || isDropOffDateTimeError
            ? "Please enter date"
            : "",
      };

      // if error in both show errors
      // if only one has an error that means it
      // isnt the variant that is selected
      if (isLocationError && isAirportError) {
        return {
          ...addressErrors,
          ...airportErrors,
          ...dateTimeErrors,
        };
      }

      return {
        ...dateTimeErrors,
      };
    });

    const isErrorStops = stopErrors.some((error) =>
      Object.values(error).some((value) => value.length)
    );

    if (errors.length > 0 || isErrorStops) {
      errors = [...errors, { stops: stopErrors }];

      setRequestErrors(
        errors.reduce((acc, value) => ({ ...acc, ...value }), {})
      );

      setSaveErrorText(newSaveErrorText);
      return;
    }

    // sets type as input type (as stop input is different than stop type)
    const requestInput: DeepPartial<Request> = cloneDeep(request);

    // converts airport and airlines from objects to just the code for input
    requestInput.trips = requestInput.trips.map((trip: Trip) =>
      convertTripToBeInputReady(trip, luggageAmount)
    );

    // set default booking contact if nothing is picked
    requestInput.trips = requestInput.trips.map((trip: Trip) => {
      if (!trip.contact?.firstName) {
        trip.contact = getContactForReservation(
          bookingContact as Contact
        ) as Contact;
      } else {
        trip.contact = getContactForReservation(trip.contact) as Contact;
      }
      return trip;
    });

    setSaveErrorText("");
    setSubmitDisabled(true);

    const addTripInput = {
      requestId: requestInput.id,
      trip: requestInput.trips[0],
      shouldSendEmail,
      mode: modeEnum,
      comments: requestInput.trips[0].comments,
      ...(isBaseRateAutomationClicked && {
        baseRateAutomation: {
          total: automatedBaseRate.total,
          lineItems: automatedBaseRate.lineItems.map((lineItem) =>
            omit(lineItem, "__typename")
          ),
        },
      }),
    };

    // remove unneeded trip contact fields
    delete addTripInput.trip.contact.homeAddress;
    delete addTripInput.trip.contact.workAddress;

    createTrip({
      variables: {
        input: addTripInput,
      },
    });
  };

  const handleTripCommentChange =
    (trip: Trip, tripIndex: number) => (value: string) => {
      updateTrip(
        {
          ...trip,
          comments: [{ bodyText: value }],
        },
        tripIndex
      );
    };

  // effects

  // if requirements are met, get automated pricing
  useEffect(() => {
    const firstStop = first(stops);
    const lastStop = last(stops);
    if (
      isVehicleBaseRateAutomated &&
      (firstStop.location || firstStop.airport) &&
      firstStop.dateTime &&
      (lastStop.location || lastStop.airport)
    ) {
      getAutomatedBaseRate({
        variables: {
          vehicleId: vehicleId,
          // TODO: Remove 'Partial' Utility types from types in this file and redefine types as necessary.
          // If properly resolved, removing this type cast should automatically resolve to Stop[].
          stops: shapeStopsToAutomatedBaseRateQueryStopsArg(stops as Stop[]),
        },
      });
    }
  }, [vehicleId, isVehicleBaseRateAutomated, getAutomatedBaseRate, stops]);

  // suggested addresses
  useEffect(() => {
    let keys = [];
    request.trips.forEach((trip) => {
      if (trip.contact.workAddress) {
        keys.push({
          address: trip.contact.workAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "workAddress",
        });
      }
      if (trip.contact.homeAddress) {
        keys.push({
          address: trip.contact.homeAddress,
          firstName: trip.contact.firstName,
          lastName: trip.contact.lastName,
          mode: "homeAddress",
        });
      }
    });

    if (!isEqual(keys, suggestedAddressInfo)) {
      setSuggestedAddressInfo(keys);
    }
  }, [request, suggestedAddressInfo]);

  // populate initial request with data from pricingLayout
  useEffect(() => {
    setRequest((prevRequest) => {
      const trip = prevRequest.trips[0];

      return {
        ...prevRequest,
        trips: [
          {
            ...trip,
            routes: trip.routes.map((route: any) => {
              return {
                ...route,
                ...parsePricingLayoutForInitialRoute(settings?.pricingLayout),
              };
            }),
          },
        ],
      };
    });
  }, [settings?.pricingLayout]);

  // set contact and request id for
  // - adding a trip to reservation
  useEffect(() => {
    if (location?.state?.request) {
      const { request } = location.state;
      const bookingContact = request.bookingContact;

      setRequest((prevRequest) => {
        const trip = prevRequest.trips[0];

        return {
          ...prevRequest,
          id: request.id,
          trips: [
            {
              ...trip,
              contact: bookingContact,
            },
          ],
        };
      });
    }
  }, [location, addTripFor]);

  return (
    <CreateDrawer
      onClose={handleCreateRequestDrawerClose}
      pageLabel={pageLabel}
      onSubmit={handleSubmitClick}
      saveError={!!saveErrorText}
      saveErrorText={saveErrorText}
      submitLabelOptions={submitLabelOptions}
      selectedSubmitButtonIndex={selectedSubmitButtonIndex}
      setSelectedSubmitButtonIndex={setSelectedSubmitButtonIndex}
      submitDisabled={submitDisabled}
    >
      <Box alignItems="flex-start" data-testid="create-request-drawer">
        {/* Order Details */}
        <TripTypeButtonsBlock
          onChange={onTripTypeChange}
          value={tripType}
          enabledTripCategories={enabledTripCategories}
        />
        {request.trips.map((trip: Trip, tripIndex: number) => (
          <Fragment key={tripIndex}>
            {/* Trip Details */}
            <TripDetailsCreateBlock
              trip={trip}
              updateTrip={updateTrip}
              tripIndex={tripIndex}
              bookingContact={bookingContact as Contact}
            />
            <Box my={4}>
              <TripInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                onCreateStop={handleCreateStop(tripIndex)}
                onRemoveStop={handleRemoveStop(tripIndex)}
                requestErrors={requestErrors}
                setRequestErrors={setRequestErrors}
                suggestedAddressInfo={suggestedAddressInfo}
                tripType={tripType}
              />
            </Box>
            <Box my={4}>
              <AdditionalInfoCreateBlock
                trip={trip}
                updateTrip={updateTrip}
                tripIndex={tripIndex}
                requestType={request.orderType}
                luggageAmount={luggageAmount}
                setLuggageAmount={setLuggageAmount}
              />
            </Box>
            <AddVehicleCreateBlock
              requestErrors={requestErrors}
              addVehicles={addVehicles(tripIndex)}
              removeVehicle={removeVehicle(tripIndex)}
              vehicle={trip.routes[0].vehicle}
              stops={trip.stops}
            />
            <PricingInfoCreateBlock
              // initialized request template uses deprecated route pricing (pricing field on route type instead of on route.pricing)
              routePricing={trip.routes[0] as unknown as RoutePricing}
              updateRoute={updateRoute(tripIndex)}
              requestErrors={requestErrors}
              setRequestErrors={setRequestErrors}
              totalDurationMinutes={calculateTotalDurationMinutes(trip.stops)}
              baseRateAmtError={requestErrors.baseRateAmt}
              BRAProps={{
                automatedBaseRate,
                automatedBaseRateLoading,
                showAutomatedBaseRateIcon:
                  isMobileView && !!automatedBaseRate?.total,
                vehicleId,
                hasEnabledVehicleBRA,
                stops: trip.stops,
              }}
              isVehicleBaseRateAutomated={isVehicleBaseRateAutomated}
              setIsBaseRateAutomationClicked={setIsBaseRateAutomationClicked}
              childSeatOptions={childSeatOptions}
            />
            <Box mt={7} mb={4}>
              <CommentCreateBlock
                commentBodyText={trip.comments[0].bodyText}
                onChangeComment={handleTripCommentChange(trip, tripIndex)}
              />
            </Box>
          </Fragment>
        ))}
      </Box>
    </CreateDrawer>
  );
}

export default CreateTripDrawer;
