import React from "react";
import { useQuery } from "@apollo/client";

import { GridFilterInputValueProps } from "@mui/x-data-grid-pro";

import { LOAD_OPERATOR_DRIVERS_NAME_AND_ID_QUERY } from "globals/graphql";
import { NO_VALUE_FILTER_VALUE } from "../utils/dataGridDefaultConfigV2";
import MultiSelectFilterInput from "./MultiSelectFilterInput";

export default function DriverFilterInput(props: GridFilterInputValueProps) {
  const { data: driverData } = useQuery(
    LOAD_OPERATOR_DRIVERS_NAME_AND_ID_QUERY
  );

  const driverOptions =
    driverData?.drivers.map((driver) => ({
      name: `${driver.firstName} ${driver.lastName}`,
      id: driver.id,
    })) || [];

  // prepend "No Driver" option
  driverOptions.unshift({
    name: "No Driver",
    id: NO_VALUE_FILTER_VALUE,
  });

  return <MultiSelectFilterInput {...props} options={driverOptions} />;
}
