import React from "react";
import some from "lodash/some";
import map from "lodash/map";

import { Box, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Trip } from "types";
import RoundChip from "design-system/components/chips/RoundChip";
import { black, grayMedium } from "design-system/colors";

export const CHILD_SEAT_LABELS = {
  boosterSeatQuantity: "Booster Seat",
  forwardFacingSeatQuantity: "Forward-Facing Seat",
  rearFacingSeatQuantity: "Rear-Facing Seat",
};

function TripNotesColumn(props: GridRenderCellParams<string>) {
  const { value, row } = props;

  const { childSeats } = row as Trip;
  const hasChildSeats = some(childSeats, (qty) => qty > 0);

  const childSeatsTooltips = map(childSeats, (qty, key) => {
    if (qty === 0) return;

    return qty > 1
      ? `${qty} X ${CHILD_SEAT_LABELS[key]}`
      : CHILD_SEAT_LABELS[key];
  });

  // returning tooltip around entire cell as a workaround for popper not rendering correctly
  return (
    <Tooltip
      title={
        hasChildSeats ? (
          <Box display="flex" flexDirection="column">
            {childSeatsTooltips.map((tooltip, i) =>
              tooltip ? (
                <Typography key={i} variant="caption">
                  {tooltip}
                </Typography>
              ) : null
            )}
          </Box>
        ) : (
          ""
        )
      }
      placement="top-end"
    >
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        <Typography
          variant="body2"
          textOverflow="ellipsis"
          overflow="hidden"
          sx={{ minWidth: 0, flex: 1 }}
        >
          {value}
        </Typography>
        {hasChildSeats && (
          <Box
            sx={{
              ml: 1,
              minWidth: "auto",
              flex: "0 0 auto",
            }}
          >
            <RoundChip
              label="CHILD SEATS"
              color={black}
              backgroundColor={grayMedium}
            />
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}

export default TripNotesColumn;
