import React, { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";

import { DispatchStatusEnum } from "globals/utils/enumMaps";
import { LOAD_OPERATOR_DRIVERS_NAME_AND_ID_QUERY } from "globals/graphql";
import { rideStatusLabelColors } from "design-system/colors";
import { StatusCircleIcon, StatusCircleFullIcon } from "design-system/icons";
import MoovsDialog from "components/MoovsDialog";
import { Driver } from "types";
import { formatPhoneNumber } from "globals/utils/phoneNumberFormatter/phoneNumberFormatter";
import MoovsTooltip from "components/MoovsTooltip";

export type BulkEditModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (status: string, driver: string) => Promise<void>;
  selectedFarmedOutTrip: boolean;
};

export function BulkEditModal(props: BulkEditModalProps) {
  const { open, onClose, onSubmit, selectedFarmedOutTrip } = props;

  // state
  const [tripStatus, setTripStatus] = useState("");
  const [driver, setDriver] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // query
  const { data: driverData } = useQuery<{ drivers: Driver[] }>(
    LOAD_OPERATOR_DRIVERS_NAME_AND_ID_QUERY
  );

  const driverOptions =
    driverData?.drivers.map((driver) => ({
      id: driver.id,
      name: `${driver.firstName} ${driver.lastName}`,
      mobilePhone: driver.mobilePhone,
    })) || [];

  // effects
  useEffect(() => {
    if (!open) {
      setTripStatus("");
      setDriver(null);
      setIsSubmitting(false);
    }
  }, [open]);

  // handler
  const handleSubmit = async () => {
    if (tripStatus || driver) {
      setIsSubmitting(true);
      await onSubmit(tripStatus, driver?.id || "");
      setIsSubmitting(false);
    }
    onClose();
  };

  const handleDriverAutoCompleteChange = (
    _e: ChangeEvent<HTMLInputElement>,
    driverData
  ) => {
    setDriver(driverData);
  };

  return (
    <MoovsDialog
      hideLoadingIndicator={!isSubmitting}
      size="sm"
      open={open}
      onClose={isSubmitting ? undefined : onClose} // prevent closing when submitting
      onAccept={handleSubmit}
      dialogTitle="Bulk Edit"
      acceptButtonText="Save"
      closeButtonText="Cancel"
      acceptDisabled={(!tripStatus && !driver) || isSubmitting}
    >
      <Box p={2}>
        {/* Trip Status Selection */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Trip Status
          </Typography>
          <Select
            value={tripStatus}
            onChange={(e) => setTripStatus(e.target.value)}
            fullWidth
            displayEmpty
            disabled={isSubmitting}
            variant="outlined"
            sx={{
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                maxHeight: "23px",
                opacity: tripStatus === "" ? 0.5 : 1,
              },
            }}
          >
            <MenuItem value="">
              <ListItemText primary="Select..." />
            </MenuItem>
            {Object.entries(DispatchStatusEnum).map(([slug, option]) => {
              const statusIconColor = rideStatusLabelColors[slug].color;
              const isActive = slug === tripStatus;

              return (
                <MenuItem key={slug} value={slug}>
                  <ListItemIcon sx={{ minWidth: "24px" }}>
                    {isActive ? (
                      <StatusCircleFullIcon color={statusIconColor} />
                    ) : (
                      <StatusCircleIcon color={statusIconColor} />
                    )}
                  </ListItemIcon>
                  <ListItemText sx={{ overflow: "hidden" }} primary={option} />
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        {/* Driver Selection */}
        <Box sx={{ mb: 2 }}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            gap={1}
            mb={1}
          >
            <Typography variant="subtitle1">Driver</Typography>
            {selectedFarmedOutTrip && (
              <MoovsTooltip
                tooltipText="Driver will not be assigned to farmed out trips."
                placement="top"
              />
            )}
          </Box>

          <Autocomplete
            options={driverOptions}
            value={driver}
            onChange={handleDriverAutoCompleteChange}
            disabled={isSubmitting}
            filterOptions={(drivers, { inputValue }) =>
              drivers.filter(({ name, mobilePhone }) => {
                const input = inputValue.toLowerCase();

                return (
                  name.toLowerCase().startsWith(input) ||
                  mobilePhone.includes(input) ||
                  formatPhoneNumber(mobilePhone).formatted.includes(input)
                );
              })
            }
            renderOption={(props, optionData) => {
              return (
                <li {...props} value={optionData.id}>
                  <Box display="flex" alignItems="center" width="100%">
                    <Box>
                      <Typography variant="body2" fontWeight={500}>
                        {optionData.name}
                      </Typography>
                      <Typography fontSize={12}>
                        {optionData.mobilePhone}
                      </Typography>
                    </Box>
                  </Box>
                </li>
              );
            }}
            getOptionLabel={(optionData) => (optionData ? optionData.name : "")}
            isOptionEqualToValue={(optionData, value) =>
              optionData.id === value.id
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Select..." />
            )}
          />
        </Box>
      </Box>
    </MoovsDialog>
  );
}
