import React from "react";

type RefreshIconProps = {
  size?: "small";
  color?: string;
};

const RefreshIcon = (props: RefreshIconProps) => {
  const { color, size } = props;
  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M17.2986 10.1249L18.0142 6.03564L22.0001 6.76979M18.2274 6.26184C19.6237 7.75434 20.4765 9.69465 20.6423 11.7564C20.8081 13.8182 20.2767 15.8757 19.1376 17.5827C17.9984 19.2898 16.321 20.5423 14.3875 21.1296C12.7551 21.6255 11.0242 21.6217 9.40454 21.1296"
          stroke={color || "#195FE9"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.12321 15.6598L5.84139 19.6008L2 18.2858M5.56996 18.7311C4.1766 17.2379 3.32648 15.2982 3.16235 13.2379C2.99821 11.1776 3.53005 9.12212 4.66857 7.41656C5.80709 5.71101 7.48295 4.45929 9.41473 3.87158C11.0495 3.37424 12.7832 3.37804 14.4054 3.87158"
          stroke={color || "#195FE9"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M17.2986 10.1249L18.0142 6.03564L22.0001 6.76979M18.2274 6.26184C19.6237 7.75434 20.4765 9.69465 20.6423 11.7564C20.8081 13.8182 20.2767 15.8757 19.1376 17.5827C17.9984 19.2898 16.321 20.5423 14.3875 21.1296C12.7551 21.6255 11.0242 21.6217 9.40454 21.1296"
        stroke={color || "#195FE9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.12321 15.6598L5.84139 19.6008L2 18.2858M5.56996 18.7311C4.1766 17.2379 3.32648 15.2982 3.16235 13.2379C2.99821 11.1776 3.53005 9.12212 4.66857 7.41656C5.80709 5.71101 7.48295 4.45929 9.41473 3.87158C11.0495 3.37424 12.7832 3.37804 14.4054 3.87158"
        stroke={color || "#195FE9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshIcon;
