import React from "react";

import { grayDark } from "design-system/colors";
import {
  ArrowUpIcon,
  ClockIcon,
  RoundTripIcon,
  ShuttleReturnTripIcon,
  TripTypeIcon,
} from "design-system/icons";
import { RoundTripVariant, TripCategory } from "types";

type getTripIconAndCategoryTypeParams = {
  tripCategory: TripCategory;
  roundTripVariant?: RoundTripVariant;
  hideRoundTripVariant?: boolean;
};

export enum TripTypeEnum {
  RoundTrip = "Round Trip",
  RoundTripPickUp = "Round Trip: Pick-Up",
  RoundTripReturn = "Round Trip: Return",
  Hourly = "Hourly",
  OneWay = "One way",
  ShuttlePickUp = "Shuttle Transfer:\nPick-up",
  ShuttleReturn = "Shuttle Transfer:\nReturn",
}

export const getTripIconAndCategoryType = (
  params: getTripIconAndCategoryTypeParams
) => {
  const { tripCategory, roundTripVariant, hideRoundTripVariant } = params;

  switch (tripCategory) {
    case TripCategory.RoundTrip: {
      const isOutboundTrip = roundTripVariant === RoundTripVariant.Outbound;

      if (hideRoundTripVariant) {
        return {
          text: TripTypeEnum.RoundTrip,
          icon: <RoundTripIcon size="small" color={grayDark} />,
        };
      }

      return {
        text: isOutboundTrip
          ? TripTypeEnum.RoundTripPickUp
          : TripTypeEnum.RoundTripReturn,
        icon: <RoundTripIcon size="small" color={grayDark} />,
      };
    }
    case TripCategory.Hourly:
      return {
        text: TripTypeEnum.Hourly,
        icon: <ClockIcon size="small" color={grayDark} />,
      };
    case TripCategory.OneWay:
      return {
        text: TripTypeEnum.OneWay,
        icon: <TripTypeIcon color={grayDark} />,
      };
    case TripCategory.ShuttlePickUp:
      return {
        text: TripTypeEnum.ShuttlePickUp,
        icon: <ArrowUpIcon color={grayDark} />,
      };
    case TripCategory.ShuttleReturn:
      return {
        text: TripTypeEnum.ShuttleReturn,
        icon: <ShuttleReturnTripIcon color={grayDark} />,
      };
    default:
      return { text: "", icon: <></> };
  }
};
