import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";
import { useLazyQuery } from "@apollo/client";
import { useFormContext } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import { Box, CircularProgress } from "@mui/material";

import { LOAD_FILTERABLE_DISPATCH_TRIPS } from "../../globals/graphql";
import GQLQueryStatusIndicator from "../../components/GQLQueryStatusIndicator";
import { LoadFilterableDispatchTripsConnection } from "../../types";
import EmptyList from "../../components/globals/EmptyList";
import DateFilter from "../../components/common/DateFilter";
import { useScreenSize } from "../../globals/hooks/useScreenSize";
import { DateRange } from "components/common/DateFilter/types";
import TripDataGrid from "./components/TripDataGrid/TripDataGrid";
import TripDataGridV2 from "./components/TripDataGrid/TripDataGridV2";
import TripViewHeader from "./components/TripViewHeader";
import { useCustomDateRange } from "components/common/hooks/useCustomDateRange";
import { HEADER_HEIGHT_MD } from "./components/TripViewHeader/TripViewHeader";
import { getLoadFilterableDispatchTripFilterFields } from "./utils";
import { DispatchTripFilterFields } from "./types";
import noFilterFoundIllustration from "design-system/illustrations/noFilterFound.svg";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

//  constants
const MODE = "dispatch";

type DispatchTripsPageProps = {
  onPageViewClick: (_, newView: "list" | "scheduler") => void;
};

function DispatchTripsPage(props: DispatchTripsPageProps) {
  const { onPageViewClick } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();
  const { storedDateRange } = useCustomDateRange({ mode: MODE });
  const { getValues } = useFormContext<DispatchTripFilterFields>();
  const { enableDispatchV2 } = useLaunchDarklyFlags();

  const DISPATCH_TRIPS_FETCH_LIMIT = enableDispatchV2 ? 1000 : 20;

  // state
  const [dateRange, setDateRange] = useState<DateRange>(storedDateRange);
  const [startDate, endDate] = dateRange;
  const [saveIndicatorState, setSaveIndicatorState] = useState<
    "default" | "saved" | "loading" | "error"
  >("default");
  const [hasInitialized, setHasInitialized] = useState(false);
  const [contentHeight, setContentHeight] = useState<string>(
    `calc(100% - ${HEADER_HEIGHT_MD})`
  );

  // ref
  const headerRef = useRef<HTMLDivElement>(null);

  // derived state
  const dispatchFilterValues = getValues();

  // memoize
  const filterNumber = useMemo(() => {
    let num = 0;

    for (let filterField in dispatchFilterValues) {
      const filterResult = dispatchFilterValues[filterField];
      num += isEmpty(filterResult) ? 0 : 1;
    }

    return num;
  }, [dispatchFilterValues]);

  // queries
  const [
    loadTrips,
    {
      data: dispatchTripsData,
      loading: dispatchTripsLoading,
      error: dispatchTripsError,
      refetch: dispatchTripsRefetch,
      fetchMore: dispatchTripsFetchMore,
    },
  ] = useLazyQuery<{
    loadFilterableDispatchTrips: LoadFilterableDispatchTripsConnection;
  }>(LOAD_FILTERABLE_DISPATCH_TRIPS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    pollInterval: 60 * 1000, // 60 seconds
    onCompleted() {
      setHasInitialized(true);
    },
  });

  // event handlers
  const handleFetchMore = () => {
    dispatchTripsFetchMore({
      variables: {
        // fetch using initial limit here instead of updated limit
        // so that fetching more only returns 20 at a time.
        limit: DISPATCH_TRIPS_FETCH_LIMIT,
        cursor:
          dispatchTripsData?.loadFilterableDispatchTrips?.pageInfo.endCursor,
      },
    });
  };

  // effects
  // reset limit when startDate changes
  useEffect(() => {
    loadTrips({
      variables: {
        limit: DISPATCH_TRIPS_FETCH_LIMIT,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        ...getLoadFilterableDispatchTripFilterFields(getValues()),
      },
    });
  }, [
    dateRange,
    loadTrips,
    startDate,
    endDate,
    getValues,
    DISPATCH_TRIPS_FETCH_LIMIT,
  ]);

  const updateContentHeight = useCallback(() => {
    if (headerRef.current) {
      setContentHeight(
        `calc(100% - ${headerRef.current.getBoundingClientRect().height}px)`
      );
    }
  }, [headerRef]);

  useEffect(() => {
    // initial calculation
    updateContentHeight();

    // update on resize
    window.addEventListener("resize", updateContentHeight);

    return () => window.removeEventListener("resize", updateContentHeight);
  }, [updateContentHeight]);

  if ((!dispatchTripsData && !dispatchTripsLoading) || dispatchTripsError) {
    return (
      <GQLQueryStatusIndicator
        name="Trips"
        data={dispatchTripsData}
        error={dispatchTripsError}
        refetch={dispatchTripsRefetch}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Dispatch | Moovs</title>
      </Helmet>

      <Box width="100%" height={`calc(100vh - ${HEADER_HEIGHT_MD})`}>
        <Box
          ref={headerRef}
          position="sticky"
          top={isSmallTabletView ? "56px" : "78px"}
          zIndex={10}
          width="100%"
        >
          <TripViewHeader
            onPageViewClick={onPageViewClick}
            pageViewValue="list"
            dateFilter={
              <DateFilter
                hasMinWidth
                isMobileView={isSmallTabletView}
                dateRange={dateRange}
                setDateRange={setDateRange}
                mode={MODE}
              />
            }
            saveIndicatorState={saveIndicatorState}
            refetch={dispatchTripsRefetch}
            filterNumber={filterNumber}
            onExpandedCallback={updateContentHeight}
          />
        </Box>

        {dispatchTripsLoading && !hasInitialized ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            width="100%"
            height="100%"
            p={4}
          >
            <CircularProgress size={40} />
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            sx={{
              overflowY: "auto",
              margin: "auto",
              height: `max(${contentHeight}, calc(100% - ${HEADER_HEIGHT_MD}))`,
              transition: "height 0.1s ease-in-out",
            }}
          >
            {dispatchTripsData?.loadFilterableDispatchTrips?.edges.length >
            0 ? (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  height: "100%",
                }}
              >
                {enableDispatchV2 ? (
                  <TripDataGridV2
                    tripData={dispatchTripsData?.loadFilterableDispatchTrips}
                    loading={dispatchTripsLoading}
                    onFetchMore={handleFetchMore}
                    refetchTripsData={dispatchTripsRefetch}
                    setSaveIndicatorState={setSaveIndicatorState}
                  />
                ) : (
                  <TripDataGrid
                    tripData={dispatchTripsData?.loadFilterableDispatchTrips}
                    loading={dispatchTripsLoading}
                    onFetchMore={handleFetchMore}
                    refetchTripsData={dispatchTripsRefetch}
                    setSaveIndicatorState={setSaveIndicatorState}
                  />
                )}
              </Box>
            ) : (
              <Box
                height="calc(100vh - 170px)"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box display="flex" width="210px">
                  <EmptyList
                    title="No trips match the filter selected. "
                    copy="Try to use something else."
                    imageUrl={noFilterFoundIllustration}
                    imageHeight={88}
                    imageWidth={88}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

export default DispatchTripsPage;
