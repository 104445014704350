import React, { useState } from "react";
import map from "lodash/map";
import every from "lodash/every";

import { Box, Typography } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";

import MoovsDialog from "components/MoovsDialog";
import { black, grayMedium } from "design-system/colors";
import { CHILD_SEAT_LABELS } from "../TripNotesColumn";

type TripNoteEditColumnType = {
  params: GridRenderEditCellParams;
};

function TripNoteEditColumn(props: TripNoteEditColumnType) {
  const {
    params: { row, api, id, field },
  } = props;

  const tripNote = row.note;
  const childSeats = row.childSeats;

  // state
  const [dialogOpen, setDialogOpen] = useState(true);

  // event handler
  const handleCloseDialog = () => {
    setDialogOpen(false);
    api.setCellMode(id, field, "view");
  };

  // create child seats text
  const childSeatsText = map(childSeats, (qty, key) => {
    if (qty === 0) return;

    return (
      <Typography variant="body2">{`${CHILD_SEAT_LABELS[key]}: ${qty}`}</Typography>
    );
  });

  const hasChildSeats = !every(childSeats, (qty) => qty === 0);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Typography
        variant="body2"
        color={tripNote ? black : grayMedium}
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {tripNote}
      </Typography>
      <MoovsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        hideTopBorder
        dialogTitle="Trip Notes"
        closeButtonText="Cancel"
        size="xs"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
          mb={3}
        >
          <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
            {tripNote}
          </Typography>
        </Box>
        {hasChildSeats && (
          <>
            <Typography variant="subtitle2">Child Seats</Typography>
            <Typography
              variant="body1"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {childSeatsText}
            </Typography>
          </>
        )}
      </MoovsDialog>
    </Box>
  );
}

export default TripNoteEditColumn;
