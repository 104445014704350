import { Stop, Vehicle } from "types";

import { shouldShowVehicleAvaliabilityOrBRA } from "./shouldShowVehicleAvaliabilityOrBRA";
import { VehicleCalendarVariantEnum } from "./vehicleCalendarEnum";

export const getCalendarVariant = (vehicle: Vehicle, stops: Stop[]) => {

  const hasValidationsToShowVehicleAvailability = stops ? shouldShowVehicleAvaliabilityOrBRA(stops) : false;
    
  if (!stops) {
    return VehicleCalendarVariantEnum.NoCalendar;
  }

  if (!hasValidationsToShowVehicleAvailability) {
    return VehicleCalendarVariantEnum.DisabledCalendar;
  }

  if (hasValidationsToShowVehicleAvailability) {
    return vehicle.hasTripConflict 
      ? VehicleCalendarVariantEnum.UnavailableCalendar
      : VehicleCalendarVariantEnum.AvailableCalendar;
  }

  return VehicleCalendarVariantEnum.NoCalendar;

};
