import { useMemo, Dispatch, SetStateAction, useEffect, useRef } from "react";
import map from "lodash/map";
import isNull from "lodash/isNull";
import size from "lodash/size";
import filter from "lodash/filter";

import {
  GridColumnOrderChangeParams,
  GridColumnVisibilityChangeParams,
  GridApiRef,
  GridColumnResizeParams,
  GridColDef,
} from "@mui/x-data-grid-pro";

import { useUserSettings } from "globals/hooks/useUserSettings";
import { useSnackbar } from "globals/hooks";
import { move } from "globals/utils/move";
import { mergeDataGridConfigs } from "../utils";
import useUpdateTripsViewConfigMutation from "./useUpdateTripsViewConfigMutation";
import { useLaunchDarklyFlags } from "globals/utils/useLaunchDarklyFlags";

type UseTripsViewConfigParams = {
  apiRef: GridApiRef;
  setSaveIndicatorState?: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
  refetchTripsData?: () => void;
};

function useTripsViewConfig(params: UseTripsViewConfigParams) {
  const { apiRef, setSaveIndicatorState, refetchTripsData } = params;
  const { enableDispatchV2 } = useLaunchDarklyFlags();

  // hooks
  const snackbar = useSnackbar();
  const userSettings = useUserSettings();
  const { onUpdateTripsViewConfig } = useUpdateTripsViewConfigMutation();

  // refs
  const tripsViewConfigOrderRef = useRef<GridColDef[]>([]); // this is used for dispatch v2, using a ref to avoid unnecessary re-renders

  // derived state
  const columns = useMemo(() => {
    if (isNull(userSettings)) return [];

    return mergeDataGridConfigs(
      setSaveIndicatorState,
      refetchTripsData,
      userSettings.settings.tripsViewConfig?.config,
      enableDispatchV2
    );
  }, [userSettings, setSaveIndicatorState, refetchTripsData, enableDispatchV2]);

  const userConfig = useMemo(
    () => map(columns, ({ field, hide, width }) => ({ field, hide, width })),
    [columns]
  );

  // effects
  useEffect(() => {
    if (!enableDispatchV2) return;

    // subscribe to drag end event so we don't update the order on every order change
    const unsubscribe = apiRef.current.subscribeEvent(
      "columnHeaderDragEnd",
      () => {
        // update the order from the ref
        onUpdateTripsViewConfig(tripsViewConfigOrderRef.current);

        // reset the ref
        tripsViewConfigOrderRef.current = [];
      }
    );

    return () => unsubscribe();
  }, [
    enableDispatchV2,
    apiRef,
    tripsViewConfigOrderRef,
    onUpdateTripsViewConfig,
  ]);

  // event handlers
  const handleColumnVisibilityChange = (
    params: GridColumnVisibilityChangeParams
  ) => {
    const { field, isVisible, colDef } = params;
    const visibleColumns = filter(columns, (column) => !column.hide);

    // undo hiding if only one column is showing
    if (colDef.hide && size(visibleColumns) === 1) {
      snackbar.error("Can't hide all columns. One must be visible.");

      apiRef.current.setColumnVisibility(field, true);
      return;
    }

    const updatedConfig = map(userConfig, (column) => {
      if (column.field !== field) return column;

      return {
        ...column,
        hide: !isVisible,
      };
    });

    onUpdateTripsViewConfig(updatedConfig);
  };

  const handleShowAllColumnsClick = () => {
    const updatedConfig = map(userConfig, (column) => ({
      ...column,
      hide: false,
    }));

    onUpdateTripsViewConfig(updatedConfig);
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const { oldIndex, targetIndex } = params;
    const updatedConfig = move(columns, oldIndex, targetIndex);
    onUpdateTripsViewConfig(updatedConfig);
  };

  const handleColumnOrderChangeV2 = (params: GridColumnOrderChangeParams) => {
    const { oldIndex, targetIndex } = params;

    // if the ref is not set, use the columns
    const updatedConfig = move(
      tripsViewConfigOrderRef.current.length
        ? tripsViewConfigOrderRef.current
        : columns,
      // -1 because the order is now 1-indexed due to the selection header
      oldIndex - 1,
      targetIndex - 1
    );

    // lastly update the ref for use in the next drag end event
    tripsViewConfigOrderRef.current = updatedConfig;
  };

  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    const { width, colDef } = params;

    const updatedConfig = map(userConfig, (column) => {
      if (column.field !== colDef.field) return column;

      return {
        ...column,
        width,
      };
    });

    onUpdateTripsViewConfig(updatedConfig);
  };

  return {
    columns,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onShowAllColumnsClick: handleShowAllColumnsClick,
    onColumnOrderChange: enableDispatchV2
      ? handleColumnOrderChangeV2
      : handleColumnOrderChange,
    onColumnWidthChange: handleColumnWidthChange,
  };
}

export { useTripsViewConfig };
