import React from "react";
import { useQuery } from "@apollo/client";
import { GridFilterInputValueProps } from "@mui/x-data-grid-pro";
import MultiSelectFilterInput from "./MultiSelectFilterInput";
import { LOAD_CONTACTS_QUERY } from "globals/graphql";

export default function BookingContactFilterInput(
  props: GridFilterInputValueProps
) {
  const { data } = useQuery(LOAD_CONTACTS_QUERY);

  const contactsOptions =
    data?.loadContacts.edges.map(({ node: contact }) => ({
      name: `${contact.firstName} ${contact.lastName}`,
      id: contact.id,
    })) || [];

  return <MultiSelectFilterInput {...props} options={contactsOptions} />;
}
