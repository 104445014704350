import React from "react";
import { GridFilterInputValueProps } from "@mui/x-data-grid-pro";
import MultiSelectFilterInput from "./MultiSelectFilterInput";
import { TripTypeEnum } from "pages/requests/utils/getTripIconAndCategoryType";

const tripTypeOptions = [
  { name: "Round Trip: Pick-Up", id: TripTypeEnum.RoundTripPickUp },
  { name: "Round Trip: Return", id: TripTypeEnum.RoundTripReturn },
  { name: "Hourly", id: TripTypeEnum.Hourly },
  { name: "One way", id: TripTypeEnum.OneWay },
  { name: "Shuttle Transfer: Pick-up", id: TripTypeEnum.ShuttlePickUp },
  { name: "Shuttle Transfer: Return", id: TripTypeEnum.ShuttleReturn },
];

export default function TripTypeFilterInput(props: GridFilterInputValueProps) {
  return <MultiSelectFilterInput {...props} options={tripTypeOptions} />;
}
