import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";

import {
  ENABLE_POCKETFLOWS_CRM_MUTATION,
  GET_POCKETFLOWS_EMBED_ID_MUTATION,
} from "globals/graphql";
import { useOperatorWithLoading } from "globals/hooks";
import { white, alabaster } from "design-system/colors";
import { useSnackbar } from "globals/hooks";
import { useAnalytics } from "globals/hooks";
import SubscribeToCrm from "./SubscribeToCrm/SubscribeToCrm";

export enum CrmPageTab {
  Campaigns = "campaigns",
  EmailTemplates = "email_templates",
  Segments = "segments",
}

function Loading() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      mt={2}
    >
      <CircularProgress />
    </Box>
  );
}

function PocketflowsEmbed({
  embedId,
  tab,
}: {
  embedId: string;
  tab: CrmPageTab;
}) {
  if (!embedId) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      width={"100%"}
      height="100%"
      bgcolor={white}
    >
      <iframe
        title="PocketFlows CRM"
        src={`https://moovs.api.pocketflows.com/moovs_embeds/${embedId}/show/${tab}?show_sidebar=1`}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
      />
    </Box>
  );
}

function CrmPage() {
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const {
    operator,
    loading: operatorLoading,
    reloadOperator,
  } = useOperatorWithLoading();

  const [isLoading, setIsLoading] = useState(operatorLoading);
  const [pocketflowsEmbedId, setPocketflowsEmbedId] = useState<string | null>(
    null
  );

  const initializePocketFlows = () => {
    setIsLoading(true);
    enablePocketflowsCRM({
      variables: {
        input: {},
      },
    });
  };

  const [getPocketflowsEmbedId, { loading: getPocketflowsEmbedIdLoading }] =
    useMutation(GET_POCKETFLOWS_EMBED_ID_MUTATION, {
      onCompleted(data) {
        reloadOperator();
        setPocketflowsEmbedId(data.getPocketflowsEmbedId.embedId);
        setIsLoading(false);
      },
      onError(error) {
        reloadOperator();
        setIsLoading(false);

        snackbar.error("Error loading CRM. Please refresh the page.");
        Sentry.captureMessage(
          "Error loading pocketflows embed ID for operator",
          {
            level: Sentry.Severity.Error,
            tags: {
              page: "CRMPage",
              action: "GET_POCKETFLOWS_EMBED_ID_MUTATION",
              operatorId: operator?.id,
            },
            extra: {
              error: error.message,
            },
          }
        );
      },
    });

  const [enablePocketflowsCRM] = useMutation(ENABLE_POCKETFLOWS_CRM_MUTATION, {
    onCompleted() {
      reloadOperator();
      getPocketflowsEmbedId({
        variables: {
          input: {},
        },
      });
    },
    onError(error) {
      reloadOperator();
      setIsLoading(false);

      Sentry.captureMessage("Error enabling PocketFlows CRM for operator", {
        level: Sentry.Severity.Error,
        tags: {
          page: "CRMPage",
          action: "ENABLE_POCKETFLOWS_CRM_MUTATION",
          operatorId: operator?.id,
        },
        extra: {
          error: error.message,
        },
      });
    },
  });

  useEffect(() => {
    track("crmPage_visited");
  }, [track]);

  useEffect(() => {
    if (operator?.pocketflowsBusinessId) {
      getPocketflowsEmbedId({
        variables: {
          input: {},
        },
      });
    }
  }, [getPocketflowsEmbedId, operator?.pocketflowsBusinessId]);

  useEffect(() => {
    if (!operatorLoading && !getPocketflowsEmbedIdLoading) {
      setIsLoading(false);
    }
  }, [operatorLoading, getPocketflowsEmbedIdLoading]);

  if (isLoading) {
    return <Loading />;
  }

  const content = !operator.settings.lagoCrmPlanCode ? (
    <SubscribeToCrm />
  ) : operator.pocketflowsBusinessId ? (
    <PocketflowsEmbed embedId={pocketflowsEmbedId} tab={CrmPageTab.Segments} />
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <LoadingButton
        color="primary"
        variant="contained"
        onClick={initializePocketFlows}
        disabled={isLoading}
        loading={isLoading}
      >
        Initialize CRM
      </LoadingButton>
    </Box>
  );

  return (
    <>
      <Helmet>
        <title>CRM | Moovs</title>
      </Helmet>

      <Box justifyContent="center" width="100%">
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          height="100%"
          bgcolor={alabaster}
        >
          {content}
        </Box>
      </Box>
    </>
  );
}

export default CrmPage;
