/**
 * @file TripMap.tsx
 * Wrapper for google map.
 */
import React from "react";

import { Box, ButtonBase, Typography } from "@mui/material";

import { Request } from "../../../../types";
import MaximiseFullIcon from "../../../../design-system/icons/info/MaximiseFullIcon";
import { grayLight, white } from "../../../../design-system/colors";
import tripMapImage from "../../../../design-system/images/tripMapImage.png";
import theme from "theme";

const styles = {
  image: {
    ":hover": {
      zIndex: 1,
      "& .imageBackdrop": {
        opacity: 0.8,
      },
    },
  },
  imageButton: {
    borderRadius: "10px",
    overflow: "hidden",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("opacity"),
  },
  imageBackdrop: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "#F5F8FF",
    opacity: 0,
    transition: theme.transitions.create("opacity"),
  },
};

type TripMapProps = {
  trip: any;
  request: Request | any;
  viewingTripChangeProposal: boolean;
};

function TripMap(props: TripMapProps) {
  const { trip, viewingTripChangeProposal } = props;
  const googleDirectionsResult = viewingTripChangeProposal && trip.tripChangeProposal ? trip.tripChangeProposal.googleDirectionsResult : trip.googleDirectionsResult;
  const stops = viewingTripChangeProposal && trip.tripChangeProposal ? trip.tripChangeProposal.stops : trip.stops;

  // event handlers
  const handleGoogleMapClick = () => {
    const formatStopForGoogleDirections = ({ airport, location }) => {
      return airport
        ? `${airport.coordinates.x},${airport.coordinates.y}`
        : location;
    };

    const mapOptions = {
      travelmode: "driving",
      origin: formatStopForGoogleDirections(stops[0]),
      destination: formatStopForGoogleDirections(stops[stops.length - 1]),
      waypoints: stops
        .slice(1, stops.length - 1)
        .map((stop) => formatStopForGoogleDirections(stop))
        .join("|"),
    };

    const searchParams = new URLSearchParams(mapOptions);

    window.open(
      `https://www.google.com/maps/dir/?api=1&${searchParams}`,
      "_blank"
    );
  };

  return (
    <Box
      height={86}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={grayLight}
      borderRadius="4px"
      overflow="hidden"
    >
      <ButtonBase
        focusRipple
        sx={[{ flex: 1, position: "relative" }, styles.image]}
        //focusVisibleClassName={classes.focusVisible}
        onClick={handleGoogleMapClick}
      >
        {googleDirectionsResult && (
          <>
            <img
              alt="trip map"
              src={tripMapImage}
              height={86}
              width="100%"
              style={{ objectFit: "cover" }}
            />
            <Box
              className="imageBackdrop"
              component="span"
              position="absolute"
              sx={styles.imageBackdrop}
            />
            <Box component="span" position="absolute" sx={styles.imageButton}>
              <Box
                bgcolor={white}
                borderRadius="4px"
                px={2}
                py={1}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Box mr={1}>
                  <MaximiseFullIcon size="small" />
                </Box>
                <Typography variant="button" color="primary">
                  View Map
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </ButtonBase>
    </Box>
  );
}

export default TripMap;
