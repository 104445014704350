import React, { memo, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment-timezone";
import first from "lodash/first";
import last from "lodash/last";

import { Box, CardActionArea, Typography } from "@mui/material";

import { Request, RequestStage } from "../../../types";
import {
  errorRed,
  granite,
  grayLight,
  moovsBlue,
  moovsBlueLight,
  moovsBlueSelected,
  tintRed,
} from "../../../design-system/colors";
import BillingDisplay from "../../../components/common/BillingDisplay";
import OverflowTooltip from "../../../design-system/components/OverflowTooltip";
import RoundChip from "../../../design-system/components/chips/RoundChip";
import { orderTripsByAscendingStopTimes } from "../requests.utils";
import { useAnalytics, useScreenSize } from "../../../globals/hooks";
import { ORDER_ITEM_HEIGHT } from "./RequestsList";
import { getColorForFarmStatus } from "../../../globals/utils/helpers";
import { filterActiveTrips } from "../../../globals/utils/trips";
import { useRequestContact } from "./hooks/useRequestContact";
import theme from "theme";
import size from "lodash/size";
import StatusCircleIcon from "../../../design-system/icons/info/StatusCircleIcon";
import MoovsTooltip from "components/MoovsTooltip";

const CENTER_WITH_TRIP_NUMBER = "1.4";

export type RequestListItemProps = {
  request: Request;
  mode: "quotes" | "reservations";
};

function RequestListItem(props: RequestListItemProps) {
  const { mode, request } = props;

  // hooks
  const { requestId } = useParams<{ requestId: string }>();
  const location = useLocation();
  const history = useHistory();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const requestContact = useRequestContact(request);

  // state
  const [isSelectedRequest, setIsSelectedRequest] = useState(false);

  // derived state
  // a request is closed if all trips are closed OR cancelled
  const isClosedRequest = !!request.closedAt;
  const isCancelledRequest = !!request.cancelledAt;
  const relevantTrips = isCancelledRequest
    ? request.trips
    : filterActiveTrips(request.trips);

  // farmed route derived state
  const isFarmedRoute = !!request.farmedRouteStatus;

  const farmedRouteCircleColor =
    isFarmedRoute &&
    getColorForFarmStatus.circleAndBorderColor(request.farmedRouteStatus);
  const farmedRouteBackgroundColor =
    isFarmedRoute && getColorForFarmStatus.bgcolor(request.farmedRouteStatus);

  // trip derived states
  const activeTripsNumber = request.activeTripsCount;
  const tripNumberBgColor = isSelectedRequest
    ? moovsBlueLight
    : isFarmedRoute
    ? farmedRouteCircleColor
    : grayLight;

  const tripDates = useMemo(() => {
    if (!size(relevantTrips)) return;

    // will render a range if first stop of multiple trips span multiple days
    const orderedTrips = orderTripsByAscendingStopTimes(relevantTrips);
    const firstTripStartTime = moment.utc(
      first(orderedTrips).stops[0]?.dateTime
    );
    const firstTripStartTimeDisplay = firstTripStartTime.format("MM/DD/YY");

    if (relevantTrips.length > 1) {
      const lastTripStartTime = moment.utc(
        last(orderedTrips).stops[0].dateTime
      );

      return lastTripStartTime.isAfter(firstTripStartTime, "day")
        ? `${firstTripStartTimeDisplay} - ${lastTripStartTime.format(
            "MM/DD/YY"
          )}`
        : firstTripStartTimeDisplay;
    }
    return firstTripStartTimeDisplay;
  }, [relevantTrips]);

  const hasTripChangePropsal = request.hasTripChangeProposal;
  // effects
  useEffect(() => {
    setIsSelectedRequest(requestId === request.id);
  }, [setIsSelectedRequest, request, requestId]);

  // event handlers
  const redirectToUpdateRequest = (requestId) => {
    // tracks quote_opened and reservation_opened
    track(`${mode}_opened`);
    // drawer animation breaks if query param is passed in, so for now, left out of mobile
    const queryParamSlug = isMobileView ? "" : `/${location.search}`;

    history.push(`/${mode}/${requestId}${queryParamSlug}`, {
      backNavigationPath: `${history.location.pathname}${history.location.search}`,
    });
  };

  return (
    <CardActionArea
      disableRipple
      style={{
        height: ORDER_ITEM_HEIGHT,
        backgroundColor: isSelectedRequest
          ? moovsBlueSelected
          : farmedRouteBackgroundColor,
        position: "relative",
      }}
      sx={{
        borderBottom: `1px solid ${grayLight}`,
        padding: `0 ${theme.spacing(1)}`,
      }}
      onClick={() => {
        redirectToUpdateRequest(request.id);
      }}
    >
      {hasTripChangePropsal && (
        <Box
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            display: "flex",
          }}
        >
          <MoovsTooltip tooltipText="Pending changes">
            <StatusCircleIcon
              color={moovsBlue}
              fill={moovsBlue}
              size="small"
            />
          </MoovsTooltip>
        </Box>
      )}
      <Box display="flex" my={2} mx={2}>
        {/* Order Number, First Stop Date, & Amount */}
        <Box width="74px" borderRight={`1px solid ${grayLight}`}>
          <Box display="flex">
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "bold",
                lineHeight: CENTER_WITH_TRIP_NUMBER,
              }}
            >
              {request.orderNumber}
            </Typography>
            {activeTripsNumber > 1 && (
              <Box
                ml={0.5}
                width="20px"
                height="20px"
                bgcolor={tripNumberBgColor}
                borderRadius="16px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption" style={{ fontWeight: 500 }}>
                  {activeTripsNumber}
                </Typography>
              </Box>
            )}
          </Box>
          <Typography variant="caption">{tripDates}</Typography>
          <BillingDisplay amount={request.totalAmount}/>
        </Box>

        {/* Contact Name, Created At Time, Email, & Phone Number */}
        <Box ml={2} flex="1" display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" mb="3px">
            <Box maxWidth="155px">
              <OverflowTooltip
                variant="h6"
                toolTipText={requestContact.name}
                displayText={requestContact.name}
              />
            </Box>
          </Box>
          <Box maxWidth="220px" mb="1px">
            <OverflowTooltip
              variant="caption"
              toolTipText={requestContact.email || "n/a"}
              displayText={requestContact.email || "n/a"}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {!!requestContact.mobilePhone && (
              <Typography noWrap variant="caption">
                {requestContact.mobilePhone}
              </Typography>
            )}

            {/* Quote Status Chips */}
            {request.stage === RequestStage.Quote && (
              <RoundChip
                variant={
                  request.isExpired
                    ? "EXPIRED"
                    : request.isArchived
                      ? "ARCHIVED"
                      : request.status
                }
              />
            )}

            {/* Reservation Status Chips */}
            {request.stage === RequestStage.Reservation && (
              <>
                {isClosedRequest && (
                  <RoundChip label="CLOSED" color={granite} />
                )}
                {isCancelledRequest && (
                  <RoundChip
                    label="CANCELLED"
                    color={errorRed}
                    backgroundColor={tintRed}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </CardActionArea>
  );
}

export default memo(RequestListItem);
