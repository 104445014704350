/**
 * @file subscription.graphql.ts
 * queries/mutations for Subscription.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_SUBSCRIPTION_METRICS_QUERY = gql`
  query LoadSubscriptionMetrics {
    loadSubscriptionMetrics {
      userCount
      tripCount
      vehicleCount
      driverCount
      renewalDate
    }
  }
`;

export const UPGRADE_SUBSCRIPTION_PLAN_MUTATION = gql`
  mutation UpgradeSubscriptionPlan($input: UpgradeSubscriptionPlanInput!) {
    upgradeSubscriptionPlan(input: $input) {
      operator {
        id
        plan
        lagoPlanCode
      }
    }
  }
`;

export const SUBSCRIBE_TO_CRM_MUTATION = gql`
  mutation SubscribeToCrm($input: SubscribeToCrmInput!) {
    subscribeToCrm(input: $input) {
      result {
        __typename
        ... on SubscribeToCrmFailure {
          reason
        }
        ... on SubscribeToCrmSuccess {
          operatorSettings {
            id
          }
        }
      }
    }
  }
`;
