/**
 * @file graphql/route.ts
 * queries/mutations for Route.
 *
 */

import { gql } from "@apollo/client";

export const UPDATE_ROUTE_MUTATION = gql`
  mutation UpdateRoute($input: UpdateRouteInput!) {
    updateRoute(input: $input) {
      route {
        id
        operatorNote
        driverNote
        operatorConfirmation
        driverPayoutFlatRate
        driverPayoutHours
        driverPayoutRatePerHour
        driverPayoutGratuity
        vehicle {
          id
          name
          operator {
            id
            name
          }
          vehicleType {
            typeName
            typeSlug
          }
        }
        pricing {
          id
          totalAmt
          amountPaid
          paymentStatus
        }
      }
    }
  }
`;

export const UPDATE_ROUTE_PRICING_MUTATION = gql`
  mutation UpdateRoutePricing($input: UpdateRoutePricingInput!) {
    updateRoutePricing(input: $input) {
      route {
        pricing {
          baseRateAmt
          driverGratuityAmt
          driverGratuityPercent
          promoDiscountAmt
          promoDiscountPercent
          taxAmt
          taxPercent
          tollsAmt
          meetGreetAmt
          otherAmt
          otherPercent
          other2Amt
          other2Percent
          other3Amt
          other3Percent
          promoCodeId
          promoCodeName
          promoCodeAmt
          promoCodePercent
          forwardFacingSeatQuantity
          forwardFacingSeatAmt
          rearFacingSeatQuantity
          rearFacingSeatAmt
          boosterSeatQuantity
          boosterSeatAmt
        }
      }
    }
  }
`;

export const REMOVE_VEHICLE_FROM_ROUTE_MUTATION = gql`
  mutation removeVehicleFromRoute($input: RemoveVehicleFromRouteInput!) {
    removeVehicleFromRoute(input: $input) {
      route {
        id
        vehicle {
          id
        }
        pricing {
          id
          baseRateAmt
        }
      }
    }
  }
`;

export const UPDATE_ROUTE_DISPATCH_MUTATION = gql`
  mutation UpdateRouteDispatch($input: UpdateRouteDispatchInput!) {
    updateRouteDispatch(input: $input) {
      route {
        id
        dispatchStatus
        driverStatus {
          name
        }
        vehicle {
          id
          name
        }
        routeDriver {
          id
        }
        alert {
          newDriver
          uniqueLocation
          airport
          largeVehicle
          partner
          luggage
          signage
          carSeat
        }
      }
    }
  }
`;

export const LOAD_DISPATCH_LOG_QUERY = gql`
  query Route($id: ID!) {
    node(id: $id) {
      id
      ... on Route {
        id
        driverCloseoutStartDateTime
        driverCloseoutEndDateTime
        driverCloseoutDurationMinutes
        driverCloseoutNote
        operatorCloseoutStartDateTime
        operatorCloseoutEndDateTime
        operatorCloseoutDurationMinutes
        operatorCloseoutNote
        isFarmedRoute
        farmeeCloseoutStartDateTime
        farmeeCloseoutEndDateTime
        farmeeCloseoutDurationMinutes
        farmeeCloseoutNote
        garageOut
        garageIn
        trip {
          id
          useTotalDuration
          totalDuration
          estimatedDuration
          timezoneId
          tripCategory
          roundTripVariant
          stops {
            id
            stopIndex
            variant
            pickUpVariant
            dateTime
            groupSize
            location
            note
            originalDateTime
            flightOffset
            airport {
              icaoCode
              iataCode
              airportName
            }
            stopDispatch {
              id
              enRouteAt
              arrivedAt
              departedAt
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_BULK_ROUTE_DISPATCH_MUTATION = gql`
  mutation UpdateBulkRouteDispatch($input: UpdateBulkRouteDispatchInput!) {
    updateBulkRouteDispatch(input: $input) {
      result {
        __typename
        ... on UpdateBulkRouteDispatchSuccess {
          routes {
            id
            dispatchStatus
            routeDriver {
              id
              routeAvailability {
                available
              }
              personalAvailability {
                available
              }
              driver {
                id
                firstName
                lastName
                mobilePhone
              }
            }
          }
          notificationDeliveryErrorMessage
        }
        ... on UpdateBulkRouteDispatchFailure {
          reason
        }
      }
    }
  }
`;
