import React from "react";

import { IconButton, Tooltip } from "@mui/material";

import { CalendarIcon, CalendarUnavailableIcon } from "design-system/icons";
import { VehicleCalendarVariantEnum } from "globals/utils/vehicleCalendarEnum";
import { moovsBlue } from "design-system/colors";

type VehicleAvailabilityBlockProps = {
  vehicleAvailabilityDialogTitle: string;
  vehicleCalendarVariant: VehicleCalendarVariantEnum;
  onShowVehicleAvailabilityClick?: (
    vehicleAvailabilityDialogTitle: string
  ) => void;
};

function VehicleAvailabilityBlock(props: VehicleAvailabilityBlockProps) {
  const { AvailableCalendar, UnavailableCalendar, DisabledCalendar } = VehicleCalendarVariantEnum;

  const {
    vehicleAvailabilityDialogTitle,
    vehicleCalendarVariant,
    onShowVehicleAvailabilityClick,
  } = props;

  // event handlers
  const handleShowVehicleAvailabilityClick = (event) => {
    // on click of vehicle availability calender,
    // stop the event propagation so that the event doesn't bubble up to add the vehicle.
    event.stopPropagation();
    onShowVehicleAvailabilityClick(vehicleAvailabilityDialogTitle);
  };

  return <>
    {vehicleCalendarVariant === AvailableCalendar && (
      <Tooltip title="Vehicle Availability">
        <IconButton edge="end" onClick={handleShowVehicleAvailabilityClick} size="large">
          <CalendarIcon color={moovsBlue} />
        </IconButton>
      </Tooltip>
    )}
    {vehicleCalendarVariant === UnavailableCalendar && (
      <Tooltip title="Show Vehicle Unavailability">
        <IconButton edge="end" onClick={handleShowVehicleAvailabilityClick} size="large">
          <CalendarUnavailableIcon />
        </IconButton>
      </Tooltip>
    )}
    {vehicleCalendarVariant === DisabledCalendar && (
      <Tooltip title="Pick Up Date, Time, Location and Drop off Location Required">
        <IconButton edge="end" onClick={(event) => event.stopPropagation()} size="large">
          <CalendarUnavailableIcon />
        </IconButton>
      </Tooltip>
    )}
  </>;
}

export default VehicleAvailabilityBlock;
