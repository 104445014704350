import React from "react";
import { useHistory } from "react-router-dom";

import { Button, Typography } from "@mui/material";

function NeedToUpgradePlan() {
  const history = useHistory();

  return (
    <>
      <Typography variant="body1">
        To subscribe to CRM, you need to upgrade your plan.
      </Typography>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        sx={{ my: 2 }}
        onClick={() => history.push("/settings/billing/plans")}
      >
        Upgrade Now
      </Button>
    </>
  );
}

export default NeedToUpgradePlan;
