import React, { useState, useEffect } from "react";
import { Card, Box } from "@mui/material";
import { Trip } from "../../../../../../types";
import { TripProposalTimeline } from "./TripProposalTimeline";
import { TripChangeProposalHeader } from "./TripChangeProposalHeader";
import { tintRed, errorRed } from "design-system/colors";

type TripProposalBlockProps = { trip: Trip, setViewingTripChangeProposal: (viewing: boolean) => void };

export function TripProposalBlock(props: TripProposalBlockProps) {
  const { trip, setViewingTripChangeProposal } = props;
  const [showOriginalTrip, setShowOriginalTrip] = useState(false);

  const onShowOriginalTrip = () => {
    setShowOriginalTrip(!showOriginalTrip);
  };

  useEffect(() => {
    if (showOriginalTrip) {
      setViewingTripChangeProposal(false);
    } else {
      setViewingTripChangeProposal(true);
    }
  }, [showOriginalTrip, setViewingTripChangeProposal]);

  return (
    <Box width="100%">
      <Card 
        variant="outlined" 
        {...(showOriginalTrip && {
          sx: { 
            backgroundColor: tintRed,
            border: `1px solid ${errorRed}`
          }
        })}
      >
        <Box p={2}>
          <TripChangeProposalHeader trip={trip} isOriginalTrip={showOriginalTrip} onShowOriginalTrip={onShowOriginalTrip} />
          <Box mt={4}>
            <TripProposalTimeline trip={trip} isOriginalTrip={showOriginalTrip} />
          </Box>
        </Box>
      </Card>
    </Box>
  );
} 