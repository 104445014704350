import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import { CrossIcon } from "design-system/icons";
import { grayDark, white } from "design-system/colors";
import { SUBMIT_CUSTOMER_FEEDBACK_MUTATION } from "globals/graphql/customerFeedback.graphql";
import { useSnackbar } from "globals/hooks";

type FeedbackModalProps = {
  open: boolean;
  onClose: () => void;
};

function FeedbackModal(props: FeedbackModalProps) {
  const { open, onClose } = props;

  // State
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  // Hooks
  const snackbar = useSnackbar();

  // Mutations
  const [submitFeedback, { loading }] = useMutation(
    SUBMIT_CUSTOMER_FEEDBACK_MUTATION,
    {
      onCompleted() {
        snackbar.success("Feedback submitted successfully", {
          snackbarColor: white,
        });
        handleClose();
      },
      onError() {
        snackbar.error("Error submitting feedback");
      },
    }
  );

  // Event handlers
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    setTitleError(false);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
    setDescriptionError(false);
  };

  const handleSubmit = () => {
    // Validate inputs
    let hasError = false;
    
    if (!title.trim()) {
      setTitleError(true);
      hasError = true;
    }
    
    if (!description.trim()) {
      setDescriptionError(true);
      hasError = true;
    }
    
    if (hasError) return;

    // Submit feedback
    submitFeedback({
      variables: {
        input: {
          title,
          description,
        },
      },
    });
  };

  const handleClose = () => {
    setTitle("");
    setDescription("");
    setTitleError(false);
    setDescriptionError(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Product feedback</Typography>
          <IconButton onClick={handleClose} size="small">
            <CrossIcon color={grayDark} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent >
        <Box mb={2} pt={2}>
          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={handleTitleChange}
            error={titleError}
            helperText={titleError ? "Title is required" : ""}
            sx={{ mb: 2 }}
            placeholder="Short, descriptive title"
          />
          <TextField
            fullWidth
            label="Details"
            value={description}
            onChange={handleDescriptionChange}
            error={descriptionError}
            helperText={descriptionError ? "Description is required" : ""}
            multiline
            rows={4}
            placeholder="The more details you provide, the better we can help you."
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3}}>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
          >
            Send feedback
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default FeedbackModal; 