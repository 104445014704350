import React from "react";

type CrmSaveMoneyIconProps = {
  color?: string;
};

const CrmSaveMoneyIcon = (props: CrmSaveMoneyIconProps) => {
  const { color } = props;

  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="72" height="72" rx="36" fill={color || "#195FE9"} />
      <path
        d="M18 26.9368C18 28.283 19.3427 29.375 21 29.375M21 29.375C22.6573 29.375 24 28.283 24 26.9368C24 25.5906 22.6573 24.5 21 24.5C19.3427 24.5 18 23.408 18 22.0618C18 20.7156 19.3427 19.625 21 19.625M21 29.375V31M21 19.625C22.6573 19.625 24 20.7156 24 22.0618M21 19.625V18"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 45.45C18 45.45 19.35 45.45 21.6 45.45C23.85 45.45 27 45 27 45V54H18V45.45Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31 44.2222C31 44.2222 33 44.2222 35.5 43.7778C38 43.3333 41 42 41 42V54H31V44.2222Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45 39.3333C45 39.3333 47.25 38.4167 49.5 36.5833C51.75 34.75 54 32 54 32V54H45V39.3333Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.3315 20.6835L51.7941 18.4775L54.0001 24.9401"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51 20C51 20 45 39 18 37.7003"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CrmSaveMoneyIcon;
