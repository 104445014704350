import React from "react";

type FeedbackIconProps = {
  color?: string;
  size?: "small" | "medium";
};

const FeedbackIcon = (props: FeedbackIconProps) => {
  const { color, size = "small" } = props;

  if (size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.33333 6.66667H10.6667M5.33333 9.33333H8M14 8C14 11.3137 11.3137 14 8 14C6.95984 14 5.98409 13.7478 5.13072 13.3022L2 14L2.69778 10.8693C2.25222 10.0159 2 9.04016 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
          stroke={color || "#999999"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10H16M8 14H12M21 12C21 16.9706 16.9706 21 12 21C10.4397 21 8.98233 20.6216 7.69609 19.9534L3 21L4.04663 16.3039C3.37841 15.0177 3 13.5603 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke={color || "#999999"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FeedbackIcon; 