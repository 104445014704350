/**
 * @file: VehiclesDialog.tsx
 * Dialog for editing/adding vehicles(routes) to routesPanel.
 *
 *
 * components:
 *   VehiclesDialog
 *
 */
import React, {
  useState,
  useEffect,
  useMemo,
  ChangeEvent,
  useRef,
} from "react";
import { useQuery } from "@apollo/client";
import map from "lodash/map";

import {
  Box,
  MenuItem,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";

import GQLQueryStatusIndicator from "components/GQLQueryStatusIndicator";
import { FarmAffiliateVehicle, Stop, Vehicle } from "types";
import { LOAD_VEHICLES_QUERY, LOAD_VEHICLE_TYPES_QUERY } from "globals/graphql";
import VehicleCard, {
  FarmAffiliateVehicleCard,
} from "components/vehicles/VehicleCard";
import MoovsDialog from "../MoovsDialog";
import { useAnalytics, useScreenSize } from "globals/hooks";
import { errorRed } from "design-system/colors";
import VehicleAvailabilityDialog from "components/vehicles/VehicleAvailabilityDialog/VehicleAvailabilityDialog";
import { shouldShowVehiclesAutomatedBaseRate } from "globals/utils/shouldShowVehiclesAutomatedBaseRate";

type VehiclesDialogProps = {
  open: boolean;
  dialogMode: "add" | "edit" | "addMultiple";
  onClose: () => void;
  onAdd?: (data: any) => void;
  onCreate?: (data: any) => void;
  vehiclesToFilterOut?: string[];
  stops?: Stop[];
  farmOutProps?: {
    tripId?: string;
    farmAffiliateId?: string;
    includeExternalOperator?: boolean;
  };
  routeId?: string;
  disableBRA?: boolean;
  tripNumber?: string;
};

function VehiclesDialog(props: VehiclesDialogProps) {
  const {
    open,
    dialogMode,
    onClose,
    onAdd,
    onCreate,
    vehiclesToFilterOut = [],
    stops,
    farmOutProps,
    routeId,
    disableBRA,
    tripNumber,
  } = props;

  // hooks
  const { track } = useAnalytics();
  const { isMobileView } = useScreenSize();
  const errorMessageRef = useRef<HTMLInputElement>();

  // state
  const [selectedVehicles, setSelectedVehicles] = useState<string[]>([]);
  const [filterType, setFilteredType] = useState<string>("All");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState("");
  const [openVehicleAvailabilityDialog, setOpenVehicleAvailabilityDialog] =
    useState(false);

  const [vehicleAvailabilityDialogProps, setVehicleAvailabilityDialogProps] =
    useState({
      vehicleAvailabilityDialogTitle: "",
      vehicleId: "",
    });

  const vehicleStops = stops?.map(({ airport, location, dateTime }) => ({
    dateTime,
    location: airport?.airportName || location,
    ...(airport?.icaoCode && { airportIcao: airport.icaoCode }),
  }));

  const {
    data: vehiclesData,
    error,
    refetch,
    loading,
  } = useQuery(LOAD_VEHICLES_QUERY, {
    variables: {
      searchTerm,
      stops: vehicleStops,
      farmAffiliateId: farmOutProps?.farmAffiliateId,
      shouldCalculateBRA: !disableBRA,
    },
    fetchPolicy: "network-only",
    skip: !open,
  });

  // only used for main vehicles query
  const { data: vehicleTypesData } = useQuery(LOAD_VEHICLE_TYPES_QUERY);

  // shaped data
  const vehicles = map(vehiclesData?.loadVehicles?.edges, "node");

  const filteredVehicles = useMemo(() => {
    return vehicles.filter(
      (vehicle: Vehicle | FarmAffiliateVehicle) =>
        !vehiclesToFilterOut.includes(vehicle.id) &&
        (filterType === "All" || vehicle.vehicleType.typeSlug === filterType)
    );
  }, [vehicles, filterType, vehiclesToFilterOut]);

  const vehicleTypes = vehicleTypesData?.vehicleTypes || [];

  // effects
  useEffect(() => {
    if (open) {
      refetch();
      setFilteredType("All");
      setSelectedVehicles([]);
      setErrorMessage("");
    }
  }, [open, refetch]);

  // dialog mode map
  const { title, acceptButtonText, handleVehicleItemClick } = {
    add: {
      title: tripNumber ? `Add Vehicle to Trip ${tripNumber}` : "Add Vehicle",
      acceptButtonText: "Add Vehicle",
      handleVehicleItemClick: (vehicle: Vehicle | FarmAffiliateVehicle) => {
        setSelectedVehicles([vehicle.id]);
      },
    },
    edit: {
      title: "Edit Vehicle",
      acceptButtonText: "Save Vehicle",
      handleVehicleItemClick: (vehicle: Vehicle | FarmAffiliateVehicle) => {
        setErrorMessage("");
        setSelectedVehicles([vehicle.id]);
      },
    },
    addMultiple: {
      title: "Add Vehicles",
      acceptButtonText: "Add Vehicles",
      handleVehicleItemClick: (vehicle: Vehicle | FarmAffiliateVehicle) => {
        setErrorMessage("");

        if (selectedVehicles.includes(vehicle.id)) {
          setSelectedVehicles(
            selectedVehicles.filter((vehicleId) => vehicleId !== vehicle.id)
          );
        } else {
          setSelectedVehicles([...selectedVehicles, vehicle.id]);
        }
      },
    },
  }[dialogMode];

  // event handlers
  const handleFilteredTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilteredType(event.target.value as string);
  };

  const handleSaveAddClick = () => {
    if (!selectedVehicles.length) {
      setErrorMessage("At least one vehicle must be selected.");
      errorMessageRef.current.scrollIntoView();
      return;
    }

    setErrorMessage("");
    if (onAdd) onAdd(selectedVehicles);
    if (onCreate) {
      onCreate(
        vehicles.filter((vehicle: Vehicle) => {
          return selectedVehicles.includes(vehicle.id);
        })
      );
    }
  };

  const handleShowVehicleAvailabilityClick =
    (vehicleId: string) => (vehicleAvailabilityDialogTitle: string) => {
      setVehicleAvailabilityDialogProps({
        vehicleAvailabilityDialogTitle,
        vehicleId,
      });
      setOpenVehicleAvailabilityDialog(true);
      track("vehicleAvailability_opened");
    };

  // renders contents differently dependent on GraphQL Type of vehicle passed in
  const FilteredVehicleCard = ({ vehicle }) => {
    switch (vehicle.__typename) {
      case "Vehicle":
        return (
          <VehicleCard
            vehicle={vehicle}
            selectedVehicles={selectedVehicles}
            onSelectVehicle={handleVehicleItemClick}
            stops={stops}
            onShowVehicleAvailabilityClick={handleShowVehicleAvailabilityClick(
              vehicle?.id
            )}
            {...(vehicle.__typename === "Vehicle" &&
              !disableBRA && {
              showBRBlock: shouldShowVehiclesAutomatedBaseRate(
                vehicle,
                stops
              ),
            })}
          />
        );
      case "FarmAffiliateVehicle":
        return (
          <FarmAffiliateVehicleCard
            vehicle={vehicle}
            selectedVehicles={selectedVehicles}
            onSelectVehicle={handleVehicleItemClick}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      <MoovsDialog
        scrollableId="scrollableVehiclesDialog"
        open={open}
        size="sm"
        onClose={onClose}
        dialogTitle={title}
        onAccept={handleSaveAddClick}
        acceptButtonText={acceptButtonText}
        fixedFooter
      >
        <>
          {error && (
            <Box height={160}>
              <GQLQueryStatusIndicator
                data={vehiclesData}
                loading={loading}
                error={error}
                name="Vehicle"
              />
            </Box>
          )}

          <Box p={1} ref={errorMessageRef}>
            {errorMessage && (
              <Typography p={1} style={{ color: errorRed }}>
                {errorMessage}
              </Typography>
            )}
          </Box>

          <>
            <Box data-testid="vehicles-dialog" my={1}>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      select
                      name="typeSlug"
                      variant="outlined"
                      label="Vehicle Type"
                      value={filterType}
                      onChange={handleFilteredTypeChange}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {vehicleTypes.map((vehicleType, index) => (
                        <MenuItem key={index} value={vehicleType.typeSlug}>
                          {vehicleType.typeName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={isMobileView ? "Search" : "Search vehicle"}
                      fullWidth
                      value={searchTerm || ""}
                      variant="outlined"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setSearchTerm(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                {filteredVehicles.map(
                  (vehicle: Vehicle | FarmAffiliateVehicle) => (
                    <Box
                      my={1}
                      key={vehicle.id}
                      data-testid="vehicle-dialog-list-vehicle-card"
                    >
                      <FilteredVehicleCard vehicle={vehicle} />
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </>

          {loading && (
            <Box
              p={4}
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size={40} thickness={2} />
                <Box mt={0.5}>
                  <Typography>Loading vehicles....</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </>
      </MoovsDialog>

      <VehicleAvailabilityDialog
        title={vehicleAvailabilityDialogProps.vehicleAvailabilityDialogTitle}
        open={openVehicleAvailabilityDialog}
        onClose={() => setOpenVehicleAvailabilityDialog(false)}
        vehicleId={vehicleAvailabilityDialogProps.vehicleId}
        stops={stops}
        routeId={routeId}
      />
    </div>
  );
}

export default VehiclesDialog;
