import { Box, CardActionArea, Typography } from "@mui/material";
import { grayDark, moovsBlue, moovsBlueSelected } from "design-system/colors";
import TripStatusChip from "design-system/components/chips/chip-variants/TripStatusChip";
import { StatusCircleFullIcon, StatusCircleIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";
import { getColorForFarmStatus } from "globals/utils/helpers";
import moment from "moment-timezone";
import { getTripIconAndCategoryType } from "pages/requests/utils/getTripIconAndCategoryType";
import React, { useMemo } from "react";
//@ts-ignore fix later - invalid import url
import { TripPanelTrip } from "../../../utils/types";
import first from "lodash/first";
import last from "lodash/last";
import FlightChangeDisplay from "pages/requests/RequestOverview/ReservationOverview/TripItineraryBlock/components/FlightChangeDisplay";
import { tripHasChangeProposal } from "pages/requests/requests.utils";
import MoovsTooltip from "components/MoovsTooltip";

type TripCardContentProps = {
  onClick: () => void;
  trip: TripPanelTrip;
  completeOrderNumber: string;
  isSelected: boolean;
  hideStatus?: boolean;
  hideTripCategory?: boolean;
};

const TripCardContent = (props: TripCardContentProps) => {
  const {
    onClick,
    trip,
    completeOrderNumber,
    isSelected,
    hideStatus = false,
    hideTripCategory = false,
  } = props;

  const {
    closedAt,
    cancelledAt,
    tripListNumber,
    farmedRouteStatus,
    tripCategory,
    stops,
  } = trip;

  // hooks
  const { isMobileView } = useScreenSize();

  const { text, icon } = getTripIconAndCategoryType({
    tripCategory,
    hideRoundTripVariant: true,
  });

  // derived state
  //@ts-ignore fix later
  const pickupDateTime = first(stops).dateTime;
  //@ts-ignore fix later
  const dropOffDateTime = last(stops).dateTime;

  const hasChangeProposal = tripHasChangeProposal(trip);

  const [dateDisplay, timeDisplay] = useMemo(() => {
    const momentStartDateTime = moment(pickupDateTime).utc();
    const startDateDisplay = momentStartDateTime.format("MMM D, YYYY");
    const startTimeDisplay = momentStartDateTime.format("h:mm A");
    if (!dropOffDateTime) return [startDateDisplay, startTimeDisplay];
    else {
      const momentEndDateTime = moment(dropOffDateTime).utc();
      const endDateDisplay = momentEndDateTime.format("MMM D, YYYY");
      const endTimeDisplay = momentEndDateTime.format("h:mm A");
      const timeSpanDisplay = `${startTimeDisplay} - ${endTimeDisplay}`;

      const spansMultipleDays = momentEndDateTime.isAfter(
        momentStartDateTime,
        "day"
      );
      return spansMultipleDays
        ? [`${startDateDisplay} - ${endDateDisplay}`, timeSpanDisplay]
        : [startDateDisplay, timeSpanDisplay];
    }
  }, [dropOffDateTime, pickupDateTime]);

  return (
    <CardActionArea
      onClick={onClick}
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        bgcolor: isSelected ? moovsBlueSelected : undefined,
      }}
    >
      {hasChangeProposal && (
        <Box
          sx={{
            position: "absolute",
            top: "6px",
            right: "6px",
            display: "flex",
          }}
        >
          <MoovsTooltip tooltipText="Pending changes">
            <StatusCircleIcon
              color={moovsBlue}
              fill={moovsBlue}
              size="small"
            />
          </MoovsTooltip>
        </Box>
      )}
      <Box display="flex" flexDirection={isMobileView ? "column" : "row"}>
        {/* Trip Number */}
        <Box
          mr={isMobileView ? undefined : 2}
          mb={isMobileView ? 2 : undefined}
        >
          <Box display="flex">
            <Typography variant="overline">Trip {tripListNumber}</Typography>

            {/* Farmed Route Status Indicator */}
            {farmedRouteStatus && (
              <Box position="relative" top="2px" left="6px">
                <StatusCircleFullIcon
                  color={getColorForFarmStatus.circleAndBorderColor(
                    farmedRouteStatus
                  )}
                  size="small"
                />
              </Box>
            )}
          </Box>

          <Typography
            variant={isMobileView ? "h3" : "subtitle1"}
            sx={{
              mr: isMobileView ? undefined : 2,
              color: isSelected ? moovsBlue : undefined,
            }}
          >
            {completeOrderNumber}
          </Typography>
        </Box>

        {/* Date & time */}
        <Box>
          <Typography variant={isMobileView ? "body2" : "caption"}>
            {dateDisplay}
          </Typography>
          <Box display="flex">
            <Typography variant={isMobileView ? "body2" : "caption"}>
              {timeDisplay}
            </Typography>
            <Box ml={0.8} display="flex" alignItems="center">
              <FlightChangeDisplay
                // @ts-ignore fix later
                flightOffset={first(stops).flightOffset}
                // @ts-ignore fix later
                originalDateTime={first(stops).originalDateTime}
                // @ts-ignore fix later
                dateTime={first(stops).dateTime}
                iconSize="small"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Chip & Trip Category */}
      <Box>
        {!hideStatus && (
          <TripStatusChip closedAt={closedAt} cancelledAt={cancelledAt} />
        )}
        {!hideTripCategory && (
          <Box display="flex">
            <Box minHeight={32} display="flex" alignItems="center">
              <Typography
                variant="caption"
                style={{
                  color: grayDark,
                  whiteSpace: "pre-line",
                  textAlign: "right",
                }}
              >
                {text}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" ml="10px">
              {icon}
            </Box>
          </Box>
        )}
      </Box>
    </CardActionArea>
  );
};

export default TripCardContent;
