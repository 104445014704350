import React from "react";

type CrmSpendConfidentIconProps = {
  color?: string;
};

const CrmSpendConfidentIcon = (props: CrmSpendConfidentIconProps) => {
  const { color } = props;

  return (
    <svg
      width="73"
      height="72"
      viewBox="0 0 73 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.333496"
        width="72"
        height="72"
        rx="36"
        fill={color || "#195FE9"}
      />
      <path
        d="M38.3335 40.3714V50.0435C38.3335 50.8276 38.0255 51.5796 37.4773 52.1341C36.9292 52.6885 36.1857 53 35.4104 53H22.2566C21.4813 53 20.7378 52.6885 20.1896 52.1341C19.6415 51.5796 19.3335 50.8276 19.3335 50.0435V21.9565C19.3335 21.1724 19.6415 20.4204 20.1896 19.8659C20.7378 19.3115 21.4813 19 22.2566 19H35.4104"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.3335 49H19.3335"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.3335 23L19.3335 23"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.3335 38C50.3041 38 54.3335 33.9706 54.3335 29C54.3335 24.0294 50.3041 20 45.3335 20C40.3629 20 36.3335 24.0294 36.3335 29C36.3335 33.9706 40.3629 38 45.3335 38Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41.3335 29.9167L43.5591 32L49.3335 27"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.3335 28H28.3335M23.3335 32H32.3335M23.3335 36H34.3335V45H23.3335V36Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CrmSpendConfidentIcon;
