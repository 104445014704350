import React, { useState } from "react";

import { Box } from "@mui/material";

import { useOperator } from "globals/hooks";
import { NeedToUpgradePlan, SubscribeToCrmWithInfo } from "./components";

function SubscribeToCrm() {
  // hooks
  const operator = useOperator();

  // state
  const [isCrmDialogOpen, setIsCrmDialogOpen] = useState(false);
  const [subscribedSuccess, setSubscribedSuccess] = useState(false);

  const isFreePlan = !operator?.lagoPlanCode;

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Box display="flex" flexDirection="column" gap={2}>
          {isFreePlan && <NeedToUpgradePlan />}
        </Box>
      </Box>

      <Box>
        {!isFreePlan && !subscribedSuccess && (
          <SubscribeToCrmWithInfo
            isCrmDialogOpen={isCrmDialogOpen}
            setIsCrmDialogOpen={setIsCrmDialogOpen}
            setSubscribedSuccess={setSubscribedSuccess}
          />
        )}
      </Box>
    </>
  );
}

export default SubscribeToCrm;
