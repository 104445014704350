import React from "react";
import { useHistory } from "react-router-dom";

import { Box, IconButton, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { grayDark, moovsBlue } from "design-system/colors";
import { EditIcon } from "design-system/icons";
import MoovsTooltip from "components/MoovsTooltip";
import { getFarmTripInfo } from "../helper/getFarmTripInfo";

function ConfNumberColumn(props: GridRenderCellParams<string>) {
  const { value, row } = props;
  // hooks
  const history = useHistory();

  if (!row.routes?.length) {
    return null;
  }

  // handle farm and non-farm trip
  const { isFarmee, isFarmorAndFarmeeHasNotAcceptedTrip } = getFarmTripInfo(
    row.routes[0]
  );
  const canNotOpenEditTripDrawer =
    isFarmee || isFarmorAndFarmeeHasNotAcceptedTrip;

  // event handlers
  const navigateToReservationPage = () => {
    history.push(`/reservations/${row.request.id}?tripId=${row.id}`, {
      backNavigationPath: `${history.location.pathname}${history.location.search}`,
    });
  };

  const navigateToEditTripDrawer = () => {
    history.push(`/dispatch/${row.request.id}/update/${row.id}`, {
      backNavigationPath: `${history.location.pathname}${history.location.search}`,
    });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box mb={0.5}>
        {canNotOpenEditTripDrawer ? (
          <MoovsTooltip
            tooltipText={
              isFarmee
                ? "Can not edit farm-in trip"
                : "Can not edit farm-out trip when acceptance is pending"
            }
            placement="top-end"
          >
            <span>
              <IconButton disabled={true}>
                <EditIcon color={grayDark} size="medium" />
              </IconButton>
            </span>
          </MoovsTooltip>
        ) : (
          <IconButton onClick={navigateToEditTripDrawer}>
            <EditIcon color={moovsBlue} size="medium" />
          </IconButton>
        )}
      </Box>

      <Box ml={0.2}>
        <Typography
          variant="subtitle2"
          onClick={navigateToReservationPage}
          sx={{
            color: moovsBlue,
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

export default ConfNumberColumn;
