import { useMemo } from "react";
import moment from "moment-timezone";
import { DateRange } from "../DateFilter/types";

type useCustomDateRangeProps = {
  mode: "reservations" | "dispatch" | "driver-payout";
};

export function useCustomDateRange(props: useCustomDateRangeProps) {
  const { mode } = props;

  const dateRange = useMemo((): DateRange => {
    // check local storage for date range
    const [localStorageStart, localStorageEnd] = JSON.parse(
      window.localStorage.getItem(`${mode}-customDateRange`) || "[]"
    );

    const localStorageTimezone = window.localStorage.getItem(
      `${mode}-customDateRange-timezone`
    );

    const currentTimezone = moment.tz.guess(true) || "";

    if (
      localStorageTimezone === currentTimezone &&
      localStorageStart &&
      localStorageEnd
    ) {
      const formatLocalStart = moment(localStorageStart).format("YYYY-MM-DD");
      const formatLocalEnd = moment(localStorageEnd).format("YYYY-MM-DD");
      return [formatLocalStart, formatLocalEnd];
    } else {
      // if nothing is in local storage, default to today
      const today = moment().format("YYYY-MM-DD");
      return [today, today];
    }
  }, [mode]);

  return {
    storedDateRange: dateRange,
  };
}
