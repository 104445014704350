import React from "react";
import { Card, Box } from "@mui/material";
import { Trip } from "../../../../../types";
import TripTimeline from "./components/TripTimeline";
import TripItineraryHeader from "./components/TripItineraryHeader";
import { TripProposalBlock } from "./components/TripProposalBlock";

type TripItineraryBlockProps = { trip: Trip, setViewingTripChangeProposal: (viewing: boolean) => void };

function TripItineraryBlock(props: TripItineraryBlockProps) {
  const { trip, setViewingTripChangeProposal } = props;
  const changeProposal = trip.tripChangeProposal;
  if (changeProposal) {
    return <TripProposalBlock trip={trip} setViewingTripChangeProposal={setViewingTripChangeProposal} />
  }
  return (
    <Box width="100%">
      <Card variant="outlined">
        <Box p={2}>
          <TripItineraryHeader trip={trip} />
          <Box mt={4}>
            <TripTimeline trip={trip} />
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default TripItineraryBlock;
