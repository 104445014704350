/**
 * @file graphql/request.ts
 * queries/mutations for Request.
 *
 */

import { gql } from "@apollo/client";
import { TRIP_SUMMARY_FIELDS } from "./trip.graphql";

export const LOAD_RESERVATIONS = gql`
  query LoadReservations(
    $startDate: Date!
    $endDate: Date!
    $view: ReservationView!
    $cursor: String
    $limit: Int
  ) {
    loadReservations(
      startDate: $startDate
      endDate: $endDate
      view: $view
      after: $cursor
      first: $limit
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          isDeclined
          createdAt
          orderNumber
          closedAt
          cancelledAt
          totalAmount
          farmedRouteStatus
          activeTripsCount
          stage
          hasTripChangeProposal
          bookingContact {
            id
            firstName
            lastName
            mobilePhone
            email
          }
          trips {
            id
            cancelledAt
            farmedRouteStatus
            tripChangeProposal {
              estimatedDuration
              totalDuration
              googleDirectionsResult
            }
            routes {
              id
              farmRelationship
              farmAffiliate {
                ... on ExternalOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
                ... on MoovsNetworkOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                  isMoovsLiteOperator
                }
                ... on GriddnetOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
                ... on MoovsAiOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
              }
            }
            stops {
              id
              dateTime
            }
          }
        }
      }
    }
  }
`;

export const LOAD_NEW_QUOTES = gql`
  query LoadNewQuotes {
    loadNewQuotes {
      totalCount
    }
  }
`;

export const LOAD_QUOTES = gql`
  query LoadQuotes($view: View!, $cursor: String, $limit: Int) {
    loadQuotes(view: $view, after: $cursor, first: $limit) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          isDeclined
          createdAt
          orderNumber
          closedAt
          cancelledAt
          totalAmount
          farmedRouteStatus
          activeTripsCount
          stage
          status
          isArchived
          isExpired
          bookingContact {
            id
            firstName
            lastName
            mobilePhone
            email
          }
          trips {
            id
            cancelledAt
            farmedRouteStatus
            routes {
              id
              farmRelationship
              farmAffiliate {
                ... on ExternalOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
                ... on MoovsNetworkOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                  isMoovsLiteOperator
                }
                ... on GriddnetOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
                ... on MoovsAiOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
              }
            }
            stops {
              id
              dateTime
            }
          }
        }
      }
    }
  }
`;

export const LOAD_REQUEST_QUERY = gql`
  query Request($id: ID!) {
    node(id: $id) {
      id
      ... on Request {
        id
        isDeclined
        orderNumber
        orderType
        type
        createdAt
        updatedAt
        cancelledAt
        activeTripsCount
        status
        stage
        amountDue
        totalAmount
        paymentStatus
        farmedRouteStatus
        isArchived
        isExpired
        confirmReservationDisabledVariant
        salesAutomation {
          id
          disabledVariant
        }
        whiteLabelOperator {
          id
          name
        }
        preferredPaymentMethod {
          id
          card {
            id
            last4
            brand
            expYear
            expMonth
          }
          linkedPassenger {
           id
           firstName
           lastName
          }
        }
        emailLogs {
          id
          createdAt
          updatedAt
          emailType
          to
          toRecipients
          ccRecipients
          from
          replyTo
          sendgridResponse
          confirmationNumber
          emailEvents {
            id
            eventType
            triggeredAt
            recipient
          }
        }
        trips ${TRIP_SUMMARY_FIELDS}
        bookingContact {
          id
          createdAt
          updatedAt
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          preferences
          homeAddress
          workAddress
          linkedPassengers {
            id
            firstName
            lastName
            createdAt
            updatedAt
            email
            mobilePhone
            mobilePhoneInternational
            preferences
            homeAddress
            workAddress
          }
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            cardNote
            billingDetails {
              name
            }
            isPrimary
            stripeStatus
            linkedPassenger {
             id
             firstName
             lastName
            }
          }
          company {
            id
            name
          }
        }
        company {
          id
          name
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            billingDetails {
              name
            }
            cardNote
            isPrimary
            stripeStatus
          }
        }
      }
    }
  }
`;

export const LOAD_REQUEST_TRIP_PANEL_QUERY = gql`
  query LoadRequestTripPanel($id: ID!) {
    node(id: $id) {
      id
      ... on Request {
        id
        isDeclined
        orderNumber
        orderType
        cancelledAt
        stage
        amountDue
        totalAmount
        paymentStatus
        farmedRouteStatus
        hasTripChangeProposal
        whiteLabelOperator {
          name
        }
        preferredPaymentMethod {
          id
          card {
            id
            last4
            brand
            expYear
            expMonth
          }
          linkedPassenger {
            id
            firstName
            lastName
          }
          billingDetails {
            name
          }
        }
        trips {
          id
          cancelledAt
          requestId
          tripNumber
          tripCategory
          vehicleInConflictId
          hasVehicle
          amountDue
          totalDuration
          closedAt
          farmedRouteStatus
          tripChangeProposal {
            estimatedDuration
            totalDuration
            googleDirectionsResult
          }
          returnTrip {
            id
            cancelledAt
            requestId
            tripNumber
            tripCategory
            amountDue
            totalDuration
            closedAt
            farmedRouteStatus
            tripChangeProposal {
              estimatedDuration
              totalDuration
              googleDirectionsResult
            }
            routes {
              id
              isFarmedRoute
              farmStatus
              farmRelationship
              pricing {
                id
                baseRateAmt
                hourlyBaseRate
                hourlyBaseRateHours
                driverGratuityAmt
                driverGratuityPercent
                promoDiscountAmt
                promoDiscountPercent
                taxAmt
                taxPercent
                tollsAmt
                meetGreetAmt
                otherName
                otherAmt
                otherPercent
                other2Name
                other2Amt
                other2Percent
                other3Name
                other3Amt
                other3Percent
                promoCodeId
                promoCodeName
                promoCodeAmt
                promoCodePercent
                forwardFacingSeatQuantity
                forwardFacingSeatAmt
                rearFacingSeatQuantity
                rearFacingSeatAmt
                boosterSeatQuantity
                boosterSeatAmt
                totalAmt
                amountPaid
                paymentStatus
              }
              farmeePricing {
                id
                baseRateAmt
                hourlyBaseRate
                hourlyBaseRateHours
                driverGratuityAmt
                driverGratuityPercent
                promoDiscountAmt
                promoDiscountPercent
                taxAmt
                taxPercent
                tollsAmt
                meetGreetAmt
                otherName
                otherAmt
                otherPercent
                other2Name
                other2Amt
                other2Percent
                other3Name
                other3Amt
                other3Percent
                promoCodeId
                promoCodeName
                promoCodeAmt
                promoCodePercent
                forwardFacingSeatQuantity
                forwardFacingSeatAmt
                rearFacingSeatQuantity
                rearFacingSeatAmt
                boosterSeatQuantity
                boosterSeatAmt
                totalAmt
                amountPaid
                paymentStatus
              }
              vehicle {
                id
                baseRateAutomation {
                  total
                  lineItems {
                    name
                    description
                    lineTotal
                    source
                  }
                }
                enableBaseRateAutomation
                settings {
                  id
                  forwardFacingSeat {
                    id
                    type
                    active
                    quantity
                    amt
                    imageUrl
                    description
                  }
                  rearFacingSeat {
                    id
                    type
                    active
                    quantity
                    amt
                    imageUrl
                    description
                  }
                  boosterSeat {
                    id
                    type
                    active
                    quantity
                    amt
                    imageUrl
                    description
                  }
                }
              }
              farmAffiliate {
                ... on ExternalOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
                ... on MoovsNetworkOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                  paymentMethods {
                    id
                    isPrimary
                    payerId
                    cardNote
                    card {
                      id
                      brand
                      last4
                      expMonth
                      expYear
                    }
                    billingDetails {
                      name
                    }
                    billingDetails {
                      name
                    }
                  }
                }
                ... on MoovsAiOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                  paymentMethods {
                    id
                    isPrimary
                    payerId
                    cardNote
                    card {
                      id
                      brand
                      last4
                      expMonth
                      expYear
                    }
                    billingDetails {
                      name
                    }
                    billingDetails {
                      name
                    }
                  }
                }
                ... on GriddnetOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                  paymentMethods {
                    id
                    isPrimary
                    payerId
                    cardNote
                    card {
                      id
                      brand
                      last4
                      expMonth
                      expYear
                    }
                    billingDetails {
                      name
                    }
                    billingDetails {
                      name
                    }
                  }
                }
              }
            }
            contact {
              company {
                id
              }
            }
            stops {
              id
              location
              locationAlias
              dateTime
              stopIndex
              originalDateTime
              flightOffset
              coordinates
              airport {
                icaoCode
              }
            }
          }
          contact {
            company {
              id
            }
          }
          stops {
            id
            location
            locationAlias
            dateTime
            stopIndex
            originalDateTime
            flightOffset
            coordinates
            pickUpVariant
            airport {
              icaoCode
            }
          }
          routes {
            id
            isFarmedRoute
            farmRelationship
            farmStatus
            pricing {
              id
              baseRateAmt
              hourlyBaseRate
              hourlyBaseRateHours
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              meetGreetAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              other3Percent
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
              totalAmt
              amountPaid
              paymentStatus
            }
            farmeePricing {
              id
              baseRateAmt
              hourlyBaseRate
              hourlyBaseRateHours
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              meetGreetAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              other3Percent
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
              totalAmt
              amountPaid
              paymentStatus
            }
            vehicle {
              id
              baseRateAutomation {
                total
                lineItems {
                  name
                  description
                  lineTotal
                  source
                }
              }
              enableBaseRateAutomation
              settings {
                id
                forwardFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                rearFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                boosterSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
              }
            }
            farmAffiliate {
              ... on ExternalOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
              }
              ... on MoovsNetworkOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
                paymentMethods {
                  id
                  isPrimary
                  payerId
                  cardNote
                  card {
                    id
                    brand
                    last4
                    expMonth
                    expYear
                  }
                  billingDetails {
                    name
                  }
                  billingDetails {
                    name
                  }
                }
              }
              ... on GriddnetOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
                paymentMethods {
                  id
                  isPrimary
                  payerId
                  cardNote
                  card {
                    id
                    brand
                    last4
                    expMonth
                    expYear
                  }
                  billingDetails {
                    name
                  }
                  billingDetails {
                    name
                  }
                }
              }
            }
          }
        }
        bookingContact {
          id
          firstName
          lastName
          email
          mobilePhone
          linkedPassengers {
            id
            firstName
            lastName
            createdAt
            updatedAt
            email
            mobilePhone
            mobilePhoneInternational
            preferences
            homeAddress
            workAddress
          }
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            billingDetails {
              name
            }
            cardNote
            isPrimary
            stripeStatus
            linkedPassenger {
              id
              firstName
              lastName
            }
          }
          company {
            id
            name
          }
        }
        company {
          id
          name
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            billingDetails {
              name
            }
            cardNote
            isPrimary
            stripeStatus
            linkedPassenger {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const CREATE_REQUEST_MUTATION = gql`
  mutation CreateNewRequest($input: CreateNewRequestInput!) {
    createNewRequest(input: $input) {
      request {
        id
        isDeclined
        orderNumber
        orderType
        createdAt
        updatedAt
        status
        type
        trips {
          id
          totalGroupSize
          note
          tripCategory
          contact {
            id
            firstName
            lastName
          }
          stops {
            id
            location
            locationAlias
            dateTime
            stopIndex
          }
          comments {
            id
            bodyText
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
            }
          }
          routes {
            id
            isFarmedRoute
            carryOnLuggage
            checkedLuggage
            oversizeLuggage
            vehicle {
              id
              baseRateAutomation {
                total
                lineItems {
                  name
                  description
                  lineTotal
                  source
                }
              }
              photos {
                id
                url
                photoIndex
              }
              name
              capacity
              enableBaseRateAutomation
              description
              licensePlate
              vehicleType {
                typeName
                typeSlug
              }
              settings {
                id
                forwardFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                rearFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                boosterSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
              }
            }
            pricing {
              id
              baseRateAmt
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              meetGreetAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              other3Percent
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
            }
          }
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            homeAddress
            workAddress
          }
          tempPassenger {
            name
          }
        }
        bookingContact {
          id
          firstName
          lastName
          email
          mobilePhone
          linkedPassengers {
            id
            firstName
            lastName
            createdAt
            updatedAt
            email
            mobilePhone
            mobilePhoneInternational
            preferences
            homeAddress
            workAddress
          }
        }
      }
    }
  }
`;

export const UPDATE_REQUEST_MUTATION = gql`
  mutation UpdateRequest($input: UpdateRequestInput!) {
    updateRequest(input: $input) {
      request {
        id
        isDeclined
        orderType
        stage
        bookingContact {
          id
          createdAt
          updatedAt
          firstName
          lastName
          email
          mobilePhone
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            cardNote
            isPrimary
            stripeStatus
          }
        }
      }
    }
  }
`;

export const CREATE_SHUTTLE_RESERVATION_MUTATION = gql`
  mutation CreateShuttleReservation($input: CreateShuttleReservationInput!) {
    createShuttleReservation(input: $input) {
      request {
        id
        isDeclined
        orderNumber
        createdAt
        updatedAt
        status
        type
        trips {
          id
          totalGroupSize
          shuttleRoute {
            id
            name
          }
          contact {
            id
            firstName
          }
          tempPassenger {
            name
          }
          stops {
            id
            location
            locationAlias
            dateTime
          }
          routes {
            id
            pricing {
              id
              baseRateAmt
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              meetGreetAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              other3Percent
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
            }
            vehicle {
              id
              name
            }
          }
        }
        bookingContact {
          id
          firstName
          lastName
          email
          mobilePhone
          linkedPassengers {
            id
            firstName
            lastName
            createdAt
            updatedAt
            email
            mobilePhone
            mobilePhoneInternational
            preferences
            homeAddress
            workAddress
          }
        }
      }
    }
  }
`;

export const TOGGLE_QUOTE_ARCHIVED_MUTATION = gql`
  mutation UpdateRequest($input: UpdateRequestInput!) {
    updateRequest(input: $input) {
      request {
        id
        isArchived
      }
    }
  }
`;

export const LOAD_SALES_AUTOMATION = gql`
  query Request($id: ID!) {
    node(id: $id) {
      id
      ... on Request {
        id
        salesAutomation {
          id
          disabledVariant
          autoArchiveDate
          draftTexts {
            id
            content
            index
            sendDate
            stage
            impressions
            recipient {
              id
              firstName
              lastName
            }
          }
          texts {
            id
            content
            index
            sendDate
            stage
            impressions
            recipient {
              id
              firstName
              lastName
            }
          }
          sender {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const ENROLL_SALES_AUTOMATION = gql`
  mutation RegisterSalesAutomation($input: RegisterSalesAutomationInput!) {
    registerSalesAutomation(input: $input) {
      salesAutomation {
        id
      }
    }
  }
`;

export const CONFIRM_REQUEST_MUTATION = gql`
  mutation ConfirmRequestFromOperator(
    $input: ConfirmRequestFromOperatorInput!
  ) {
    confirmRequestFromOperator(input: $input) {
      request {
        id
        status
        trips {
          id
          billings {
            id
            amount
            note
            action
          }
        }
      }
    }
  }
`;

// deprecated
export const SEND_EMAIL_TO_CUSTOMER_MUTATION = gql`
  mutation SendEmailToCustomer($input: SendEmailToCustomerInput!) {
    sendEmailToCustomer(input: $input) {
      request {
        id
        status
        bookingContact {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

// send reservation or price summary to To and CC emails
export const SEND_EMAIL_TO_MULTIPLE_CUSTOMERS_MUTATION = gql`
  mutation SendEmailToMultipleCustomersV2(
    $input: SendEmailToMultipleCustomersV2Input!
  ) {
    sendEmailToMultipleCustomersV2(input: $input) {
      result {
        __typename
        ... on SendEmailToMultipleCustomersFailure {
          message
        }
      }
    }
  }
`;

export const LOAD_INVOICEABLE_REQUESTS_QUERY = gql`
  query LoadInvoiceableRequests(
    $contactId: ID
    $farmorOperatorId: ID
    $companyId: ID
  ) {
    loadInvoiceableRequests(
      contactId: $contactId
      farmorOperatorId: $farmorOperatorId
      companyId: $companyId
    ) {
      edges {
        node {
          id
          isDeclined
          orderNumber
          type
          totalAmount
          stage
          createdAt
          cancelledAt
          closedAt
          activeTripsCount
          status
          farmedRouteStatus
          company {
            id
          }
          preferredPaymentMethod {
            id
            card {
              id
              last4
              brand
              expYear
              expMonth
            }
          }
          invoice {
            id
            removedAt
          }
          emailLogs {
            id
            createdAt
            updatedAt
            emailType
            to
            from
            replyTo
            sendgridResponse
            emailEvents {
              id
              eventType
              triggeredAt
            }
          }
          trips {
            id
            paymentStatus
            amountDue
            totalAmount
            totalDuration
            estimatedDuration
            useTotalDuration
            totalGroupSize
            createdAt
            cancelledAt
            requestId
            tripNumber
            closedAt
            temporaryPassenger
            billings {
              id
              createdAt
              updatedAt
              amount
              note
              action
            }
            contact {
              id
              firstName
              lastName
              mobilePhone
              email
              homeAddress
              workAddress
            }
            stops {
              id
              stopIndex
              variant
              location
              locationAlias
              dateTime
              groupSize
              createdAt
              updatedAt
              flightNumber
              airport {
                icaoCode
                iataCode
                airportName
                countryName
                countryIso2
                timezone
                gmt
                coordinates
              }
              airline {
                icaoCode
                iataCode
                airlineName
                callsign
                fleetSize
                countryName
                countryIso2
                dateFounded
              }
              trackedFlight {
                id
                faFlightId
                updatedAt
                airline {
                  icaoCode
                  airlineName
                  iataCode
                }
                actualAirline {
                  airlineName
                  iataCode
                  icaoCode
                }
                flightStatus
                flightNumber
                actualFlightNumber
                origin {
                  id
                  airport {
                    airportName
                    iataCode
                    icaoCode
                    countryName
                    countryIso2
                    timezone
                    gmt
                  }
                  scheduledGate
                  scheduledDateTime
                  scheduledTerminal
                  actualGate
                  actualDateTime
                  estimatedDateTime
                  actualTerminal
                }
                destination {
                  id
                  airport {
                    airportName
                    iataCode
                    icaoCode
                    countryName
                    countryIso2
                    timezone
                    gmt
                  }
                  scheduledGate
                  scheduledDateTime
                  scheduledTerminal
                  actualGate
                  estimatedDateTime
                  actualDateTime
                  actualTerminal
                }
              }
            }
            routes {
              id
              groupSize
              operatorConfirmation
              dispatchStatus
              isFarmedRoute
              farmRelationship
              pricing {
                id
                baseRateAmt
                hourlyBaseRate
                hourlyBaseRateHours
                driverGratuityAmt
                driverGratuityIsPct
                promoDiscountAmt
                promoDiscountIsPct
                taxAmt
                taxIsPct
                tollsAmt
                meetGreetAmt
                otherName
                otherAmt
                otherIsPct
                other2Name
                other2Amt
                other2IsPct
                other3Name
                other3Amt
                other3IsPct
                promoCodeId
                promoCodeName
                promoCodeAmt
                promoCodePercent
                forwardFacingSeatQuantity
                forwardFacingSeatAmt
                rearFacingSeatQuantity
                rearFacingSeatAmt
                boosterSeatQuantity
                boosterSeatAmt
                totalAmt
                amountPaid
                paymentStatus
              }
              farmeePricing {
                id
                baseRateAmt
                hourlyBaseRate
                hourlyBaseRateHours
                driverGratuityAmt
                driverGratuityIsPct
                promoDiscountAmt
                promoDiscountIsPct
                taxAmt
                taxIsPct
                tollsAmt
                meetGreetAmt
                otherName
                otherAmt
                otherIsPct
                other2Name
                other2Amt
                other2IsPct
                other3Name
                other3Amt
                other3IsPct
                promoCodeId
                promoCodeName
                promoCodeAmt
                promoCodePercent
                forwardFacingSeatQuantity
                forwardFacingSeatAmt
                rearFacingSeatQuantity
                rearFacingSeatAmt
                boosterSeatQuantity
                boosterSeatAmt
                totalAmt
                amountPaid
                paymentStatus
              }
              farmAffiliateVariant
              requestedVehicleNote
              farmAffiliate {
                ... on ExternalOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
                ... on MoovsNetworkOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                  isMoovsLiteOperator
                }
                ... on GriddnetOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
                ... on MoovsAiOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
              }
              farmStatus
              farmoutNote
              vehicle {
                id
                name
              }
              driver {
                id
                firstName
                lastName
                mobilePhone
                email
              }
            }
          }
          bookingContact {
            id
            createdAt
            updatedAt
            firstName
            lastName
            mobilePhone
            mobilePhoneInternational
            email
          }
        }
      }
    }
  }
`;

export const UNDO_CONVERT_QUOTE_MUTATION = gql`
  mutation UndoConvertQuote($input: UndoConvertQuoteInput!) {
    undoConvertQuote(input: $input) {
      request {
        id
      }
    }
  }
`;

export const CREATE_DUPLICATE_RESERVATION_MUTATION = gql`
  mutation CreateDuplicateReservation(
    $input: CreateDuplicateReservationInput!
  ) {
    createDuplicateReservation(input: $input) {
      reservations {
        id
      }
    }
  }
`;

export const IS_SIGNED_TERMS_AND_CONDITIONS_PDF_DOWNLOAD_ENABLED = gql`
  query Request($id: ID!) {
    node(id: $id) {
      id
      ... on Request {
        id
        reservationConfirmationSignatureLog {
          id
          downloadEnabled
        }
      }
    }
  }
`;

export const UPDATE_REQUEST_COMPANY_LINKING = gql`
  mutation UpdateRequestCompanyLinking(
    $input: UpdateRequestCompanyLinkingInput!
  ) {
    updateRequestCompanyLinking(input: $input) {
      request {
        id
        company {
          id
          name
        }
        bookingContact {
          id
          company {
            id
            name
          }
        }
      }
    }
  }
`;

export const ACCEPT_UNCONFIRMED_REQUEST_MUTATION = gql`
  mutation AcceptUnconfirmedReservation(
    $input: AcceptUnconfirmedReservationInput!
  ) {
    acceptUnconfirmedReservation(input: $input) {
      request {
        id
        isDeclined
        orderNumber
        orderType
        createdAt
        updatedAt
        status
        type
        trips {
          id
          totalGroupSize
          note
          tripCategory
          contact {
            id
            firstName
            lastName
          }
          stops {
            id
            location
            locationAlias
            dateTime
          }
          comments {
            id
            bodyText
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
            }
          }
          routes {
            id
            isFarmedRoute
            carryOnLuggage
            checkedLuggage
            oversizeLuggage
            vehicle {
              id
              baseRateAutomation {
                total
                lineItems {
                  name
                  description
                  lineTotal
                  source
                }
              }
              photos {
                id
                url
                photoIndex
              }
              name
              capacity
              enableBaseRateAutomation
              description
              licensePlate
              vehicleType {
                typeName
                typeSlug
              }
              settings {
                id
                forwardFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                rearFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                boosterSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
              }
            }
            pricing {
              id
              baseRateAmt
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              meetGreetAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              other3Percent
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
            }
          }
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            homeAddress
            workAddress
          }
          tempPassenger {
            name
          }
        }
        bookingContact {
          id
          firstName
          lastName
          email
          mobilePhone
          linkedPassengers {
            id
            firstName
            lastName
            createdAt
            updatedAt
            email
            mobilePhone
            mobilePhoneInternational
            preferences
            homeAddress
            workAddress
          }
        }
      }
    }
  }
`;

export const DECLINE_UNCONFIRMED_REQUEST_MUTATION = gql`
  mutation DeclineUnconfirmedReservation(
    $input: DeclineUnconfirmedReservationInput!
  ) {
    declineUnconfirmedReservation(input: $input) {
      request {
        id
        isDeclined
        orderNumber
        orderType
        createdAt
        updatedAt
        status
        type
        trips {
          id
          totalGroupSize
          note
          tripCategory
          contact {
            id
            firstName
            lastName
          }
          stops {
            id
            location
            locationAlias
            dateTime
          }
          comments {
            id
            bodyText
            createdAt
            updatedAt
            user {
              id
              firstName
              lastName
            }
          }
          routes {
            id
            isFarmedRoute
            carryOnLuggage
            checkedLuggage
            oversizeLuggage
            vehicle {
              id
              baseRateAutomation {
                total
                lineItems {
                  name
                  description
                  lineTotal
                  source
                }
              }
              photos {
                id
                url
                photoIndex
              }
              name
              capacity
              enableBaseRateAutomation
              description
              licensePlate
              vehicleType {
                typeName
                typeSlug
              }
              settings {
                id
                forwardFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                rearFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                boosterSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
              }
            }
            pricing {
              id
              baseRateAmt
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              meetGreetAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              other3Percent
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
            }
          }
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            homeAddress
            workAddress
          }
          tempPassenger {
            name
          }
        }
        bookingContact {
          id
          firstName
          lastName
          email
          mobilePhone
          linkedPassengers {
            id
            firstName
            lastName
            createdAt
            updatedAt
            email
            mobilePhone
            mobilePhoneInternational
            preferences
            homeAddress
            workAddress
          }
        }
      }
    }
  }
`;
