import React from "react";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  moovsBlue,
  moovsBlueDark,
  moovsBlueSelected,
  black,
  moovsBlueHover,
} from "design-system/colors";
import { useScreenSize } from "../globals/hooks";

type MoovsAcceptDeclineBannerProps = {
  bannerText: string;
  acceptButtonText: string;
  declineButtonText: string;
  onAccept: () => void;
  onDecline: () => void;
  icon?: React.ReactNode;
  acceptLoading?: boolean;
  declineLoading?: boolean;
}

function MoovsAcceptDeclineBanner({
  bannerText,
  acceptButtonText,
  declineButtonText,
  onAccept,
  onDecline,
  icon,
  acceptLoading,
  declineLoading
}: MoovsAcceptDeclineBannerProps) {
  const { isMobileView } = useScreenSize();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobileView ? "column" : "row",
        alignItems: isMobileView ? "flex-start" : "center",
        justifyContent: "space-between",
        backgroundColor: moovsBlueSelected,
        borderRadius: "8px",
        padding: "16px",
        mb: 2,
        gap: isMobileView ? 2 : 0,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {icon}
        <Typography color={black}>{bannerText}</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 1, width: isMobileView ? "100%" : "auto" }}>
        <LoadingButton
          variant="contained"
          sx={{
            backgroundColor: moovsBlue,
            "&:hover": { backgroundColor: moovsBlueDark },
            flex: isMobileView ? 1 : "none",
          }}
          onClick={onAccept}
          startIcon={<CheckIcon />}
          loading={acceptLoading}
        >
          {acceptButtonText}
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{
            borderColor: moovsBlue,
            borderWidth: "1px",
            color: moovsBlue,
            "&:hover": {
              borderColor: moovsBlueDark,
              backgroundColor: moovsBlueHover,
              borderWidth: "1px",
            },
            flex: isMobileView ? 1 : "none",
          }}
          onClick={onDecline}
          startIcon={<CloseIcon />}
          loading={declineLoading}
        >
          {declineButtonText}
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default MoovsAcceptDeclineBanner; 