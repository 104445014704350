/**
 * @file graphql/driver.ts
 * queries/mutations for Driver.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_OPERATOR_DRIVERS_QUERY = gql`
  query DriverList($farmAffiliateId: ID, $includeExternalOperators: Boolean) {
    drivers(
      farmAffiliateId: $farmAffiliateId
      includeExternalOperators: $includeExternalOperators
    ) {
      id
      firstName
      lastName
      mobilePhone
      homePhone
      homeAddress
      email
      licenseNumber
      licenseClass
      licenseState
      licenseExpirationDate
      note
      driverProfilePhoto
    }
  }
`;

export const LOAD_OPERATOR_DRIVER_QUERY = gql`
  query Driver($id: ID!) {
    node(id: $id) {
      id
      ... on Driver {
        id
        firstName
        lastName
        mobilePhone
        mobilePhoneInternational
        homePhone
        homeAddress
        email
        licenseNumber
        licenseClass
        licenseState
        licenseExpirationDate
        note
        driverProfilePhoto
        settings {
          id
          hourlyTripHourlyRate
          hourlyTripBaseRatePct
          hourlyTripLocationsVariant
          oneWayTripHourlyRate
          oneWayTripMinimumHours
          oneWayTripFlatRate
          oneWayTripBaseRatePct
          oneWayTripLocationsVariant
          gratuityPct
        }
      }
    }
  }
`;

export const LOAD_ROUTE_DRIVERS_QUERY = gql`
  query LoadRouteDrivers(
    $farmAffiliateId: ID
    $includeExternalOperators: Boolean
    $routeId: ID
  ) {
    loadRouteDrivers(
      farmAffiliateId: $farmAffiliateId
      includeExternalOperators: $includeExternalOperators
      routeId: $routeId
    ) {
      id
      driver {
        id
        firstName
        lastName
        mobilePhone
        homePhone
        homeAddress
        email
        licenseNumber
        licenseClass
        licenseState
        licenseExpirationDate
        note
        driverProfilePhoto
      }
      routeAvailability {
        available
      }
      personalAvailability {
        available
      }
    }
  }
`;

export const CREATE_DRIVER_MUTATION = gql`
  mutation CreateDriver($input: CreateDriverInput!) {
    createDriver(input: $input) {
      driver {
        id
        createdAt
        updatedAt
        firstName
        lastName
        mobilePhone
        homePhone
        homeAddress
        email
        licenseNumber
        licenseClass
        licenseState
        licenseExpirationDate
        note
        driverProfilePhoto
        settings {
          hourlyTripHourlyRate
          hourlyTripLocationsVariant
          hourlyTripBaseRatePct
          oneWayTripHourlyRate
          oneWayTripMinimumHours
          oneWayTripLocationsVariant
          oneWayTripBaseRatePct
          oneWayTripFlatRate
          gratuityPct
        }
      }
    }
  }
`;

export const REMOVE_DRIVER_MUTATION = gql`
  mutation RemoveDriver($input: RemoveDriverInput!) {
    removeDriver(input: $input) {
      driver {
        id
      }
    }
  }
`;

export const UPDATE_DRIVER_MUTATION = gql`
  mutation UpdateDriver($input: UpdateDriverInput!) {
    updateDriver(input: $input) {
      driver {
        id
        firstName
        lastName
        mobilePhone
        homePhone
        homeAddress
        email
        licenseNumber
        licenseClass
        licenseState
        licenseExpirationDate
        note
        driverProfilePhoto
        settings {
          id
          hourlyTripHourlyRate
          hourlyTripBaseRatePct
          hourlyTripLocationsVariant
          oneWayTripHourlyRate
          oneWayTripMinimumHours
          oneWayTripFlatRate
          oneWayTripBaseRatePct
          oneWayTripLocationsVariant
          gratuityPct
        }
      }
    }
  }
`;

export const LOAD_OPERATOR_DRIVERS_NAME_AND_ID_QUERY = gql`
  query DriverNamdIdList {
    drivers {
      id
      firstName
      lastName
      mobilePhone
    }
  }
`;

export const CREATE_ONBOARDING_DRIVER_MUTATION = gql`
  mutation CreateOnboardingDriver($input: CreateOnboardingDriverInput!) {
    createOnboardingDriver(input: $input) {
      operator {
        id
        onboardingCompleted
      }
    }
  }
`;
