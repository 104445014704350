import React, { useEffect, useState } from "react";
import startCase from "lodash/startCase";

import { useQuery } from "@apollo/client";

import { Typography, Box, MenuItem, InputAdornment } from "@mui/material";

import { granite } from "../../../../../design-system/colors";
import {
  LabeledInlineSelect,
  LabeledInlineInput,
  LabeledInlineLocationAutocomplete,
  LabeledInlineInternationalPhoneNumberInput,
} from "../../../../../design-system/components/inputs";
import { Contact, Company } from "../../../../../types";
import { LOAD_COMPANIES_QUERY } from "../../../../../globals/graphql";
import MoovsAvatar from "../../../../../design-system/components/MoovsAvatar";
import UseCompanyAddressButton from "../../UseCompanyAddressButton";
import { useAnalytics, useScreenSize } from "../../../../../globals/hooks";

type ContactInfoSectionProps = {
  contact: Contact;
  onContactInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onCompanyChange: (company: Company) => void;
  onContactAddressInputChange: (
    value: { description: string },
    name: string
  ) => void;
  onContactPhoneInputChange: (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ) => void;
  contactMobilePhoneError?: string;
  contactEmailError?: string;
};

function ContactInfoSection(props: ContactInfoSectionProps) {
  const {
    contact,
    onContactInputChange,
    onCompanyChange,
    onContactAddressInputChange,
    onContactPhoneInputChange,
    contactMobilePhoneError,
    contactEmailError,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();

  // state
  const [showUseCompanyAddressButton, setShowUseCompanyAddressButton] =
    useState(false);

  // queries
  const { data } = useQuery(LOAD_COMPANIES_QUERY);

  // effects
  useEffect(() => {
    setShowUseCompanyAddressButton(
      contact?.company?.address &&
        contact?.company?.address !== contact.workAddress
    );
  }, [contact]);

  if (!contact) {
    return null;
  }

  // event handlers
  const handleCompanySelectChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    if (event.target.value === null) {
      onCompanyChange(null);
    } else if (typeof event.target.value === "string") {
      const company = data.companies.find(
        ({ id }) => id === event.target.value
      );

      track("contact_companyAdded");

      onCompanyChange(company);
    }
  };

  const contactName = [contact.firstName, contact.lastName]
    .map((word) => startCase(word))
    .join(" ");

  return (
    <>
      <Box mt={3} display="flex" flexDirection="column" alignItems="center">
        <Box>
          <MoovsAvatar
            alt="contact, person"
            size="xl"
            placeholder={[contact.firstName, contact.lastName]}
          />
        </Box>
        <Box mt={2}>
          <Typography variant="h2">{contactName}</Typography>
        </Box>
        <Box mt={0.5}>
          <Typography variant="body1" sx={{ color: granite }}>
            {contact.mobilePhone}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <LabeledInlineInput
          required
          errorText="First name is required"
          label="First Name"
          value={contact ? contact.firstName : ""}
          name="firstName"
          onChange={onContactInputChange}
        />
        <LabeledInlineInput
          required
          errorText="Last name is required"
          label="Last Name"
          value={contact ? contact.lastName : ""}
          name="lastName"
          onChange={onContactInputChange}
        />
        <LabeledInlineInternationalPhoneNumberInput
          label="Mobile Phone"
          value={contact ? contact.mobilePhoneInternational : ""}
          name="mobilePhone"
          onChange={onContactPhoneInputChange}
          dropdownWidth={isMobileView ? "400%" : "900%"}
          error={contactMobilePhoneError}
          errorMessage="Please enter valid number"
        />
        <LabeledInlineSelect
          label="Company"
          value={contact.company ? contact.company.id : ""}
          onChange={handleCompanySelectChange}
        >
          <MenuItem key="blank" value={null}>
            &nbsp;
          </MenuItem>
          {((data && data.companies) || []).map((company: Company) => (
            <MenuItem key={company.id} value={company.id || ""}>
              {company.name}
            </MenuItem>
          ))}
        </LabeledInlineSelect>
        <LabeledInlineLocationAutocomplete
          label="Home Address"
          name="homeAddress"
          value={contact ? contact.homeAddress : ""}
          onChange={(value) =>
            onContactAddressInputChange(value, "homeAddress")
          }
        />
        <LabeledInlineLocationAutocomplete
          label="Work Address"
          name="workAddress"
          value={contact ? contact.workAddress : ""}
          onChange={(value) =>
            onContactAddressInputChange(value, "workAddress")
          }
          TextFieldInputProps={{
            ...(!isMobileView &&
              showUseCompanyAddressButton && {
                endAdornment: (
                  <InputAdornment position="end">
                    <UseCompanyAddressButton
                      onClick={() => {
                        onContactAddressInputChange(
                          { description: contact.company.address },
                          "workAddress"
                        );
                      }}
                    />
                  </InputAdornment>
                ),
              }),
          }}
        />
        {isMobileView && showUseCompanyAddressButton && (
          <UseCompanyAddressButton
            onClick={() => {
              onContactAddressInputChange(
                { description: contact.company.address },
                "workAddress"
              );
            }}
          />
        )}

        <LabeledInlineInput
          label="Company Position"
          value={contact ? contact.companyPosition : ""}
          name="companyPosition"
          onChange={onContactInputChange}
        />

        <LabeledInlineInput
          required
          errorText={
            !!contactEmailError ? contactEmailError : "Email is required"
          }
          label="Email address"
          value={contact ? contact.email : ""}
          name="email"
          onChange={onContactInputChange}
          error={!!contactEmailError}
        />
        <LabeledInlineInput
          inputProps={{ maxLength: 3000 }}
          multiline
          label="Contact Preferences"
          name="preferences"
          value={contact.preferences || ""}
          onChange={onContactInputChange}
        />
      </Box>
    </>
  );
}

export default ContactInfoSection;
