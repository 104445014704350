import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { Box, TextField, Typography } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";

import MoovsDialog from "components/MoovsDialog";
import { black, grayMedium } from "design-system/colors";
import { UPDATE_ROUTE_DISPATCH_MUTATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks";

type DriverNoteEditColumnType = {
  params: GridRenderEditCellParams;
  setSaveIndicatorState: Dispatch<
    SetStateAction<"default" | "saved" | "loading" | "error">
  >;
};

function DriverNoteEditColumn(props: DriverNoteEditColumnType) {
  const {
    params: { row, api, id, field },
    setSaveIndicatorState,
  } = props;

  const driverNote = row.routes[0].driverNote;
  const routeId = row.routes[0].id;

  // hooks
  const snackbar = useSnackbar();

  // mutations
  const [updateRouteDispatch, { loading }] = useMutation(
    UPDATE_ROUTE_DISPATCH_MUTATION,
    {
      refetchQueries: ["LoadFilterableDispatchTrips"],
      onCompleted() {
        handleCloseDialog();
        setSaveIndicatorState("saved");
      },
      onError() {
        setSaveIndicatorState("error");
        snackbar.error("Can not update driver notes.");
      },
    }
  );

  // state
  const [dialogOpen, setDialogOpen] = useState(true);
  const [driverNoteInput, setDriverNoteInput] = useState("");

  // event handler
  const handleCloseDialog = () => {
    setDialogOpen(false);
    api.setCellMode(id, field, "view");
  };

  const handleSaveDriverNote = () => {
    setSaveIndicatorState("loading");
    updateRouteDispatch({
      variables: {
        input: {
          routeId,
          driverNote: driverNoteInput,
        },
      },
    });
  };

  // effects
  useEffect(() => {
    setDriverNoteInput(driverNote);
  }, [driverNote]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Typography
        variant="body2"
        color={driverNote ? black : grayMedium}
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {driverNote || "Add Driver Note"}
      </Typography>
      <MoovsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        hideTopBorder
        dialogTitle="Driver Notes"
        closeButtonText="Cancel"
        size="xs"
        acceptButtonText="Save"
        onAccept={handleSaveDriverNote}
        hideLoadingIndicator={!loading}
        acceptDisabled={loading}
      >
        <TextField
          multiline
          rows={6}
          value={driverNoteInput}
          onChange={(e) => setDriverNoteInput(e.target.value)}
          placeholder="Add Driver Note"
          sx={{ mb: 1 }}
        />
      </MoovsDialog>
    </Box>
  );
}

export default DriverNoteEditColumn;
